import { useEffect, useMemo, useState, useCallback } from 'react';
import {
  Navigate,
  useLocation,
  useOutletContext,
  useParams,
  useNavigate,
} from 'react-router-dom';
import HelloSign from 'hellosign-embedded';
import { useMutation } from '@apollo/client';
import { get } from 'lodash';

import { Loader } from '@vartana-repo/base-components/loader';
import { CloseModal } from '../../../components/CloseModal';
import {
  CrossIcon,
  useMoveBack,
  GET_SIGNATURE_URL,
  SAVE_AGREEMENT,
  reportError,
} from '../../../assets';
import { mapStateToPath, ORDER_STATES } from '../../routes';

const docusignStyles = {
  branding: {
    primaryButton: {
      backgroundColor: '#054BC7',
      color: '#fff',
    },
  },
  signingNavigationButton: {
    position: 'bottom-right',
  },
};

export function Signatures() {
  const [onSignInitiated, setOnSignInitiated] = useState(false);
  const [signatureCompleted, setSignatureCompleted] = useState(false);
  const [signatureUrl, setSignatureUrl] = useState('');
  const [isDocusign, setIsDocusign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showNextDocLoading, setShowNextDocLoading] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { orderNumber } = useParams();
  const [{ orderDetail }, updateOrderDetails] = useOutletContext();

  const orderState = get(orderDetail, 'state', '');
  const isTestMode =
    process.env.NX_NODE_ENV === 'production' &&
    get(orderDetail, 'company.seller.testMode', false);

  const [saveSignedAgreement] = useMutation(SAVE_AGREEMENT);
  const [getOrderSignatureURL, { loading: isOrderSignatureURLLoading }] =
    useMutation(GET_SIGNATURE_URL);
  const [, signatureToVerification] = useMoveBack(updateOrderDetails);

  const { helloSignClient, skipDomainVerification } = useMemo(() => {
    return {
      helloSignClient: new HelloSign({
        clientId: isTestMode
          ? process.env.NX_HELLOSIGN_SANDBOX_CLIENT_ID
          : process.env.NX_HELLOSIGN_CLIENT_ID,
      }),
      skipDomainVerification: isTestMode
        ? true
        : process.env.NX_HELLOSIGN_PRODUCTION_MODE !== 'true',
    };
  }, [isTestMode]);

  const onAgreementSigned = useCallback(() => {
    saveSignedAgreement({
      onCompleted: (response) => {
        const newOrder = get(response, 'saveSignedAgreement.order', {});
        updateOrderDetails({
          ...newOrder,
          currentCheckoutProgress: {
            ...newOrder.currentCheckoutProgress,
            progress: 1,
          },
          state: ORDER_STATES.completed,
        });
        setSignatureCompleted(true);
      },
    });
  }, [saveSignedAgreement, updateOrderDetails]);

  const signDocusignDocument = useCallback(
    (urls, urlIndex) => {
      if (urlIndex >= urls.length) {
        // All documents signed, call onAgreementSigned
        onAgreementSigned();
        return;
      }

      setLoading(true);
      if (urlIndex > 0) {
        setShowNextDocLoading(true);
      }

      window.DocuSign.loadDocuSign(process.env.NX_DOCUSIGN_API_KEY)
        .then((docusign) => {
          let hasSessionEnded = false;
          const signing = docusign.signing({
            url: urls[urlIndex],
            displayFormat: 'focused',
            style: docusignStyles,
          });

          signing.on('ready', () => {
            // UI is rendered
            setLoading(false);
            setShowNextDocLoading(false);
          });

          signing.on('sessionEnd', (event) => {
            // session end callback
            if (!hasSessionEnded) {
              if (
                event.sessionEndType === 'signing_complete' ||
                event.sessionEndType === 'viewing_complete'
              ) {
                // Move on to the next document
                setLoading(true);
                signDocusignDocument(urls, urlIndex + 1);
              } else {
                // in all other cases cancel, decline, exception, fax_pending,
                // session_timeout, ttl_expired go back to verification
                signatureToVerification();
              }
            }
            hasSessionEnded = true;
          });

          signing.mount('#docusign');
        })
        .catch((error) => {
          reportError(`[DOCUSIGN ERROR]: ${error.message}`);
          navigate('/error');
        });
    },
    [navigate, onAgreementSigned, signatureToVerification]
  );

  // move to verification screen after cancel icon is pressed
  useEffect(() => {
    helloSignClient.on('cancel', () => {
      signatureToVerification();
    });
  }, [helloSignClient, signatureToVerification]);

  // Get signature URL for HelloSign/Docusign document
  useEffect(() => {
    if (orderState === ORDER_STATES.signatures) {
      getOrderSignatureURL({
        onCompleted: (response) => {
          setSignatureUrl(get(response, 'generateAgreementUrl.order.signatureUrl', ''));
        },
      });
    }
  }, [location, orderState, getOrderSignatureURL]);

  // After getting signature URL, open HelloSign/Docusign client
  useEffect(() => {
    if (signatureUrl) {
      if (signatureUrl.includes('docusign')) {
        setIsDocusign(true);
      } else {
        helloSignClient.open(signatureUrl, {
          allowCancel: true,
          container: document.getElementById('hellosign-document-container'),
          skipDomainVerification,
        });
        setOnSignInitiated(true);
      }
    }

    return () => signatureUrl && helloSignClient.close();
  }, [helloSignClient, skipDomainVerification, signatureUrl, signDocusignDocument]);

  useEffect(() => {
    if (onSignInitiated) {
      helloSignClient.on('sign', () => {
        onAgreementSigned();
      });
    }
  }, [
    helloSignClient,
    onAgreementSigned,
    onSignInitiated,
    saveSignedAgreement,
    updateOrderDetails,
  ]);

  useEffect(() => {
    if (isDocusign) {
      const urls = JSON.parse(signatureUrl);
      signDocusignDocument(urls, 0);
    }
  }, [isDocusign, signDocusignDocument, signatureUrl]);

  if (orderState && orderState !== ORDER_STATES.signatures) {
    return <Navigate to={mapStateToPath(orderNumber, orderState)} />;
  }

  return (
    <Loader isLoading={isOrderSignatureURLLoading} className="w-10 h-10 mt-[14rem] mb-6">
      {isDocusign ? (
        <div className="absolute bottom-0 left-0 top-0 right-0  bg-vartana-gray-160/80 flex justify-center">
          <div className="bg-vartana-white-100 h-full w-full md:h-[86%] md:w-[86%] md:rounded-xl flex flex-col md:mt-[3.25rem]">
            <div className="w-full flex justify-end p-2">
              <div className="cursor-pointer w-6 h-6 flex items-center justify-center">
                <CrossIcon onClick={() => setShowCloseModal(true)} />
              </div>
            </div>
            <div className="px-4 pb-4 md:px-10 md:pb-10 h-inherit flex-1 relative">
              <p className="text-vartana-black-100 text-xl md:text-2xl font-bold mb-4 md:mb-6">
                Review and sign documents
              </p>
              <div id="docusign" className="h-[calc(100%-2.5rem)] w-full" />
              {loading && (
                <div className="h-[calc(100%-2.5rem)] w-full absolute bottom-0 left-0 bg-white md:rounded-xl flex items-center flex-col">
                  <Loader isLoading={loading} className="w-10 h-10 mt-60" />
                  {showNextDocLoading && (
                    <p className="text-sm font-semibold text-vartana-gray-140 mt-6 w-full max-w-[11.875rem] text-center">
                      Please wait while we load the next document.
                    </p>
                  )}
                </div>
              )}
            </div>
            {showCloseModal && (
              <CloseModal
                onCloseClick={() => setShowCloseModal(false)}
                onConfirmClick={() => {
                  if (orderNumber) {
                    // there are limitations around unmounting DocuSign
                    // hence reload the page when going back a step
                    window.location.href = `order/${orderNumber}/checkout/verification`;
                  } else {
                    signatureToVerification();
                  }
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <div id="hellosign-document-container h-full" />
      )}
      {signatureCompleted && (
        <Navigate to={mapStateToPath(orderNumber, ORDER_STATES.completed)} />
      )}
    </Loader>
  );
}
