import PropTypes from 'prop-types';
import '../form.scss';
import { Label } from '../label/Label';

export const TextArea = ({ field, form, label, ...rest }) => {
  const { touched, errors } = form;

  return (
    <div className="flex flex-col gap-2">
      <Label
        htmlFor="message"
        className={`${errors[field.name] ? 'text-vartana-red-160' : null}`}
      >
        {label}
      </Label>
      <textarea
        className={`
          field-text
          field--base
          field-text
          ${errors[field.name] && 'field--error'}
        `}
        {...field}
        {...rest}
      />
      {touched[field.name] && errors[field.name] && (
        <span className="text-vartana-red-160 text-xs leading-4 font-semibold">
          {errors[field.name]}
        </span>
      )}
    </div>
  );
};
TextArea.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    errors: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string,
};

TextArea.defaultProps = {
  label: 'Include a message (optional)',
};
