export const rollbarConfig = {
  accessToken: process.env.NX_ROLLBAR_CLIENT_ITEM_ACCESS_TOKEN,
  enabled:
    process.env.NX_NODE_ENV === 'production' ||
    process.env.NX_NODE_ENV === 'qa' ||
    process.env.NX_NODE_ENV === 'preview' ||
    process.env.NX_NODE_ENV === 'eksstaging' ||
    process.env.NX_NODE_ENV === 'staging',
  environment: process.env.REACT_APP_NODE_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  code_version: process.env.NX_GIT_SHA,
  nodeSourceMaps: true,
  payload: {
    environment: process.env.NX_NODE_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
          code_version: process.env.NX_GIT_SHA,
          guess_uncaught_frames: true
      },
    },
  }
};
