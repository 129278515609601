import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { OptionCard } from './OptionCard';

/**
 * List of Option Selection Cards - basically radio buttons that look like cards
 *
 * @param options {array} List of choices
 */
export const OptionCards = ({ options, containerClassNames, isDisabled, onSelectOption }) => {
  const selectedOption = useMemo(
    () => options.find(({ isSelected }) => isSelected)?.id,
    [options]
  );

  return (
    <div role="radiogroup" className={containerClassNames}>
      {options?.map((option, index) => (
        <OptionCard
          groupIndex={index}
          key={option.id}
          label={option.label}
          selected={option.id === selectedOption}
          isDisabled={isDisabled}
          onClick={() => {
            onSelectOption(option);
          }}
        />
      ))}
    </div>
  );
};

OptionCards.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string.isRequired,
      isSelected: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onSelectOption: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  containerClassNames: PropTypes.string,
};
OptionCards.defaultProps = {
  isDisabled: false,
  containerClassNames: '',
};
