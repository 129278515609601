import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { get, omit } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';

import {
  InputField,
  CustomSelect,
  SearchAddress,
  Checkbox,
} from '@vartana-repo/base-components/form';
import { Button } from '@vartana-repo/base-components/buttons';
import { Loader } from '@vartana-repo/base-components/loader';
import { ToolTip } from '@vartana-repo/base-components/miscellaneous';
import {
  InfoIcon,
  LeftArrow,
  RightArrow,
  states,
  useMoveBack,
  TERMS_AND_CONDITIONS_URL,
  UPDATE_PERSONAL_GUARANTEE,
  GET_USER_INFO_FOR_PG,
} from '../../../assets';
import { ForwardApplicationModal } from '../../../components/Modals';
import { mapStateToPath } from '../../routes';

const initialValues = {
  firstName: '',
  lastName: '',
  street: '',
  city: '',
  state: '',
  zip: '',
  pg_disclaimer: false,
};

const exampleValidationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  street: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string().required('ZipCode is required'),
  pg_disclaimer: Yup.bool()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'The terms and conditions must be accepted.'),
});

const PageHeader = ({ title, subtitle1, subtitle2, linkAction, linkText }) => (
  <div className="flex flex-col gap-2 pb-4">
    <span className="page-title-small lg:page-title text-vartana-black-100">{title}</span>
    <span className="body flex gap-1 items-center">
      {subtitle1}
      <ToolTip
        element={<InfoIcon className="w-4 h-4" />}
        tooltipContent={
          <p>
            A PG or personal guaranty is an <br />
            agreement confirming that the <br />
            individual who signs is <br />
            responsible for paying back a <br />
            loan should the business ever <br />
            becomes unable to make payments.
          </p>
        }
      />
    </span>

    <div className="flex flex-col md:flex-row gap-2 body items-start lg:items-center">
      <span>{subtitle2}</span>

      <Button variant="linkBlue" onClick={linkAction}>
        {linkText}
      </Button>
    </div>
  </div>
);

const TermsAndConditionsLink = ({ className }) => (
  <a className={className} href={TERMS_AND_CONDITIONS_URL} target="_blank" rel="noreferrer">
    View terms and condition
  </a>
);

export function PersonalGuaranty() {
  const [btnLoading, setBtnLoading] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [noOfPGUsers, setNoOfPGUsers] = useState(0);
  const [isPgRequired, setIsPgRequired] = useState(false);

  const urlParams = useParams();
  const navigate = useNavigate();

  const [{ sessionDetail }, updateOrderDetails] = useOutletContext();
  const { orderNumber } = urlParams;

  const { data: sessionData, loading: sessionLoading } = useQuery(GET_USER_INFO_FOR_PG, {
    onError: (error) => console.error('[GET_USER_INFO_FOR_PG]', error),
  });

  const [backBtnLoading, onBackBtnClick] = useMoveBack(updateOrderDetails);
  const [updatePersonalGuarantee] = useMutation(UPDATE_PERSONAL_GUARANTEE);

  const currentUser = useMemo(() => get(sessionData, 'session.user'), [sessionData]);
  const onSubmit = useCallback(
    async (values) => {
      setBtnLoading(true);
      const payload = omit(values, ['firstName', 'lastName']);
      const { data, errors } = await updatePersonalGuarantee({
        variables: {
          agreed: true,
          ...payload,
        },
      });
      setBtnLoading(false);

      if (errors) console.error('[UPDATE_PERSONAL_GUARANTEE]', errors);
      else if (data) {
        const newOrder = get(data, 'updatePersonalGuarantee.order');
        if (newOrder) {
          const newRoute = mapStateToPath(orderNumber, newOrder?.state, isPgRequired);
          updateOrderDetails(newOrder);
          navigate(newRoute);
        }
      }
    },
    [isPgRequired, navigate, orderNumber, updateOrderDetails, updatePersonalGuarantee]
  );

  useEffect(() => {
    setNoOfPGUsers(get(sessionDetail, 'session.creditAppraisal.pgContacts', []).length);
    setIsPgRequired(get(sessionDetail, 'session.creditAppraisal.pgRequired', false));
  }, [sessionDetail]);

  const pageHeaders = useMemo(() => {
    const headers = {
      title: 'Personal guaranty',
      subtitle1: 'A personal guaranty is required for this order.',
    };

    if (!isPgRequired || (isPgRequired && noOfPGUsers > 1)) {
      headers.subtitle2 = 'Not the personal guarantor?';
      headers.linkText = 'Forward order';
      headers.linkAction = () => setShowHelpModal(true);
    }
    return headers;
  }, [isPgRequired, noOfPGUsers]);

  return (
    <Loader isLoading={sessionLoading}>
      <PageHeader
        title={pageHeaders.title}
        subtitle1={pageHeaders.subtitle1}
        subtitle2={pageHeaders.subtitle2}
        linkText={pageHeaders.linkText}
        linkAction={pageHeaders.linkAction}
      />
      {!isPgRequired || (isPgRequired && noOfPGUsers > 1) ? (
        <ForwardApplicationModal
          isOpen={showHelpModal}
          onClose={() => setShowHelpModal(false)}
          updateOrderDetail={updateOrderDetails}
          isPgRequired={isPgRequired}
        />
      ) : null}

      <Formik
        initialValues={{
          ...initialValues,
          firstName: currentUser?.firstName,
          lastName: currentUser?.lastName,
          street: get(currentUser, 'defaultAddress.street', ''),
          city: get(currentUser, 'defaultAddress.city', ''),
          state: get(currentUser, 'defaultAddress.state', ''),
          zip: get(currentUser, 'defaultAddress.zip', ''),
        }}
        onSubmit={onSubmit}
        validationSchema={exampleValidationSchema}
      >
        {({ errors, touched, isValid, dirty, setFieldValue }) => {
          return (
            <Form>
              <div className="h-inherit flex flex-col justify-between gap-4">
                <div className="grid grid-cols-6 gap-y-4 gap-x-4">
                  <div className="col-span-6 md:col-span-3">
                    <InputField
                      name="firstName"
                      label="First name"
                      errorMsg={errors.firstName}
                      touched={touched.firstName}
                      disabled
                    />
                  </div>
                  <div className="col-span-6 md:col-span-3">
                    <InputField
                      name="lastName"
                      label="Last name"
                      errorMsg={errors.lastName}
                      touched={touched.lastName}
                      disabled
                    />
                  </div>
                  <div className="col-span-6">
                    <SearchAddress
                      name="street"
                      label="Address"
                      disabled
                      afterPlaceSelect={(addressComponents) => {
                        if (addressComponents.city)
                          setFieldValue('city', addressComponents.city);
                        if (addressComponents.state?.shortName)
                          setFieldValue('state', addressComponents.state.shortName);
                        if (addressComponents.zip) setFieldValue('zip', addressComponents.zip);
                      }}
                    />
                  </div>
                  <div className="col-span-6 md:col-span-3">
                    <InputField
                      name="city"
                      label="City"
                      errorMsg={errors.city}
                      touched={touched.city}
                      disabled
                    />
                  </div>
                  <div className="md:col-span-3 col-span-6 grid grid-cols-6 gap-4">
                    <div className="col-span-6 md:col-span-3">
                      <CustomSelect disabled name="state" label="State" options={states} />
                    </div>
                    <div className="col-span-6 md:col-span-3">
                      <InputField
                        name="zip"
                        label="Zip"
                        placeholder="XXXXX"
                        errorMsg={errors.zip}
                        disabled
                        touched={touched.zip}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col pt-6">
                <Checkbox
                  name="pg_disclaimer"
                  labelClassName="text-vartana-gray-140"
                  longText
                  label={
                    <span>
                      By checking this box, I have read the terms of use, and I agree to offer
                      a personal guaranty for the financing application of this order.&nbsp;
                      <TermsAndConditionsLink className="body text-blue-500 underline text-xs inline-block md:inline" />
                    </span>
                  }
                />
              </div>

              {/* Form Action buttons */}
              <div className="flex items-center justify-between pt-8">
                <Button variant="linkBlue" isLoading={backBtnLoading} onClick={onBackBtnClick}>
                  <LeftArrow /> Back
                </Button>

                <Button
                  type="submit"
                  isLoading={btnLoading}
                  disabled={!(isValid && dirty)}
                  className="text-xs md:text-base"
                >
                  Next <RightArrow />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Loader>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle1: PropTypes.string.isRequired,
  subtitle2: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  linkAction: PropTypes.func.isRequired,
};

TermsAndConditionsLink.propTypes = {
  className: PropTypes.string.isRequired,
};
