import { getAPIURL, reportError } from './helpers';

export async function initiateVerificationInquiry(inquiryId) {
  try {
    const response = await fetch(`${getAPIURL()}/persona/initiate_inquiry`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        inquiry_id: inquiryId,
      }),
    });
    return response.status;
  } catch (e) {
    reportError(e);
    return null;
  }
}

export async function resumeVerificationInquiry(inquiryId) {
  const defaultResponse = {
    session_token: '',
    inquiry_status: '',
  };
  try {
    const response = await fetch(`${getAPIURL()}/persona/resume_inquiry`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        inquiry_id: inquiryId,
      }),
    });
    if (response.status === 200) {
      return response.json();
    }
    return defaultResponse;
  } catch (e) {
    reportError(e);
    return defaultResponse;
  }
}

export async function getVerificationSummary(inquiryId) {
  const defaultResponse = {
    name_first: '',
    name_middle: '',
    name_last: '',
    driver_license_number: '',
    type: '',
    status: '',
  };
  try {
    const response = await fetch(
      `${getAPIURL()}/persona/verification_summary?inquiry_id=${inquiryId}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      }
    );
    if (response.status === 200) {
      return response.json();
    }
    return defaultResponse;
  } catch (e) {
    reportError(e);
    return defaultResponse;
  }
}
