import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useMutation } from '@apollo/client';

import { Button } from '@vartana-repo/base-components/buttons';
import { Loader } from '@vartana-repo/base-components/loader';

import {
  handleDocumentPreview,
  handleDocumentPreviewAll,
  PREVIEW_DOCUMENT,
  CartIcon,
  CheckIcon2 as CheckIcon,
} from '../../assets';
import { FileInfoCard } from '../Cards';
import { SummaryData } from './SummaryData';
import { ORDER_STATES } from '../../app/routes';

const downloadStatuses = {
  DEFAULT: 'DEFAULT',
  DOWNLOADING: 'DOWNLOADING',
  DOWNLOADED: 'DOWNLOADED',
};
export function OrderSummary({ paymentSummary, summaryLoading, orderState }) {
  const [filesDownloading, setFilesDownloading] = useState([]);
  const [downloadAllStatus, setDownloadAllStatus] = useState(downloadStatuses.DEFAULT);

  const { orderNumber } = useParams();

  const [previewDocuments] = useMutation(PREVIEW_DOCUMENT);

  const onDownloadAllClick = useCallback(() => {
    if (downloadAllStatus === downloadStatuses.DEFAULT) {
      setDownloadAllStatus(downloadStatuses.DOWNLOADING);
      previewDocuments({
        onCompleted: (response) => {
          setDownloadAllStatus(downloadStatuses.DOWNLOADED);
          setTimeout(() => {
            setDownloadAllStatus(downloadStatuses.DEFAULT);
          }, [700]);
          if (response.previewDocuments) {
            const docs = get(response, 'previewDocuments.encodedDocuments');
            const docNumber = get(response, 'previewDocuments.number');
            handleDocumentPreviewAll(docs, docNumber);
          }
        },
        onError: (error) => {
          setDownloadAllStatus(downloadStatuses.DEFAULT);
          console.error(error);
        },
      });
    }
  }, [downloadAllStatus, previewDocuments]);

  const updateDownloadingFiles = (file) => {
    setFilesDownloading((prevState) => [
      ...prevState.filter((filename) => filename !== file.filename),
    ]);
  };

  const onFileReviewClick = useCallback(
    (file) => {
      setFilesDownloading((prevState) => [...prevState, file.filename]);
      previewDocuments({
        variables: {
          docId: file.id,
          docType: file.type,
          filename: file.filename,
        },
        onCompleted: (response) => {
          updateDownloadingFiles(file);
          if (response.previewDocuments) {
            const docs = get(response, 'previewDocuments.encodedDocuments');
            const docNumber = get(response, 'previewDocuments.number');
            handleDocumentPreview(docs[0], docNumber);
          }
        },
        onError: (error) => {
          updateDownloadingFiles(file);
          console.error(error);
        },
      });
    },
    [previewDocuments]
  );

  const reviewDocuments = get(paymentSummary, 'reviewDocuments', []);

  return (
    <Loader isLoading={summaryLoading} containerClassName="pt-10">
      <div className="flex gap-2 text-vartana-black-100 card-title-bold">
        <CartIcon />
        <span>Order#</span>
        <span>{orderNumber}</span>
      </div>
      <div className="pt-6 pb-4 w-full text-vartana-black-100 card-subtitle-bold">
        Order summary
      </div>
      <div className="grid grid-cols-6 gap-x-12 divide-y-2">
        <div className="col-span-6 grid grid-cols-6 gap-y-4 pb-6">
          <SummaryData paymentSummary={paymentSummary} />
          {/* Display if Sales tax and shipping costs are not taken upfront */}
          <div className="col-span-6 px-2 py-2 bg-vartana-gray-30/50">
            <span className="p-small text-vartana-gray-160">
              Installment payments may change based on sales tax and shipping costs.
            </span>
          </div>
        </div>

        {/* Terms and Conditions */}
        {orderState !== ORDER_STATES.completed && (
          <div className="col-span-6 pt-7 flex flex-col gap-3">
            <div className="flex flex-col xl:flex-row justify-between items-start xl:items-center">
              <span className="card-subtitle-bold text-vartana-black-100">
                Terms and conditions
              </span>
              <Button
                variant="link"
                className="text-link-bold cursor-pointer"
                disabled={downloadAllStatus !== downloadStatuses.DEFAULT}
                onClick={onDownloadAllClick}
              >
                <div className="flex items-center gap-1">
                  {downloadAllStatus === downloadStatuses.DOWNLOADED && <CheckIcon />}
                  {downloadAllStatus === downloadStatuses.DOWNLOADING && (
                    <Loader isLoading className="w-4 h-4 mr-1" />
                  )}
                  Download all
                </div>
              </Button>
            </div>
            <div>
              {reviewDocuments.length > 0 ? (
                <div className="flex flex-col gap-4">
                  {reviewDocuments.map((file) => (
                    <FileInfoCard
                      key={file.id}
                      name={file.filename}
                      document={file.document}
                      loading={
                        filesDownloading.includes(file.filename) ||
                        downloadAllStatus === downloadStatuses.DOWNLOADING
                      }
                      onClick={() => onFileReviewClick(file)}
                    />
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </Loader>
  );
}

OrderSummary.propTypes = {
  orderState: PropTypes.string.isRequired,
  summaryLoading: PropTypes.bool,
  paymentSummary: PropTypes.shape({
    reviewDocuments: PropTypes.arrayOf(PropTypes.shape({})),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    paymentSchedule: PropTypes.string,
    contractLength: PropTypes.string,
    paymentDue: PropTypes.string,
    customerApplicationFee: PropTypes.string,
    payments: PropTypes.string,
    orderTotal: PropTypes.string,
    fee: PropTypes.string,
    totalPayment: PropTypes.string,
  }),
};

OrderSummary.defaultProps = {
  summaryLoading: true,
  paymentSummary: {},
};
