import PropTypes from 'prop-types';
import { Modal } from '@vartana-repo/base-components/modal';
import { Button } from '@vartana-repo/base-components/buttons';
import { FilesIcon } from '../../assets';

export const FinancialDetailsModal = ({ open, handleClose }) => {
  return (
    <Modal isOpen={open} showCloseIcon onClose={handleClose} ariaHideApp={false}>
      <div className="flex gap-3">
        <FilesIcon />
        <h3 className="page-title-small">Financial statements</h3>
      </div>
      <p className="font-normal text-sm leading-5">Acceptable financial documents include:</p>
      <ol className="list-outside list-disc ml-6 text-sm">
        <li>
          2 years of CPA-prepared or audited financial statements, including income statement,
          balance sheet, cashflow statement, and debt schedules with CPA’s notes
        </li>
        <li>
          If CPA-prepared documents are not available, business tax returns (Form{' '}
          <a
            className="font-medium text-vartana-blue-120 focus-within:outline-none underline"
            href={process.env.NX_F1065_SAMPLE_URL}
            target="_blank"
            rel="noreferrer"
          >
            1065
          </a>
          ,&nbsp;
          <a
            className="font-medium text-vartana-blue-120 focus-within:outline-none underline"
            href={process.env.NX_F1120_SAMPLE_URL}
            target="_blank"
            rel="noreferrer"
          >
            1120
          </a>
          &nbsp;or
          <a
            className="font-medium text-vartana-blue-120 focus-within:outline-none underline"
            href={process.env.NX_F1120S_SAMPLE_URL}
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            1120-S including Schedule L
          </a>
          )&nbsp;may be submitted
        </li>
        <li>
          If the end date of these documents is more than 6 months ago, year-to-date documents
          through the most recent quarter-end are required{' '}
        </li>
      </ol>
      <div className="flex justify-end mt-6">
        <Button type="button" onClick={handleClose}>
          Close window
        </Button>
      </div>
    </Modal>
  );
};

FinancialDetailsModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

FinancialDetailsModal.defaultProps = {
  open: false,
  handleClose: () => {},
};
