import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Label } from '../label/Label';

export const Checkbox = ({
  name,
  label,
  longText,
  containerClassName,
  labelClassName,
  fieldClassName,
  disabled,
}) => {
  return (
    <div className={containerClassName}>
      <Label
        htmlFor={name}
        className={`text-sm flex gap-2 body ${longText ? 'items-start' : 'items-center'}
        ${labelClassName}`}
      >
        <>
          <Field
            id={name}
            name={name}
            type="checkbox"
            autoComplete="off"
            className={`min-w-4.5 w-4.5 min-h-4.5 h-4.5 ${fieldClassName}`}
            disabled={disabled}
          />
          <span className="w-auto">{label}</span>
        </>
      </Label>
    </div>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  longText: PropTypes.bool,
  labelClassName: PropTypes.string,
  fieldClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  longText: false,
  labelClassName: '',
  containerClassName: '',
  fieldClassName: '',
  disabled: false,
};
