import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';
import { Label } from '../label/Label';
import '../form.scss';
import { InputErrorField } from '../inputField/InputErrorField';

export const Dropdown = ({ name, label, options, error, ...rest }) => {
  return (
    <div className="field-wrapper">
      <Label htmlFor={name} className={`${error ? 'text-vartana-red-160' : ''}`}>
        {label}
      </Label>
      <Field
        as="select"
        id={name}
        name={name}
        autoComplete="off"
        {...rest}
        className={`
          field--base field-text
          ${error && 'field--error'}
        `}
      >
        {options.length > 0
          ? options.map((option) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.key}
                </option>
              );
            })
          : 'No options found'}
      </Field>
      <ErrorMessage name={name} component={InputErrorField} />
    </div>
  );
};
Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      key: PropTypes.key,
    })
  ),
};
Dropdown.defaultProps = {
  label: '',
  options: [],
};
