import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { Modal } from '@vartana-repo/base-components/modal';
import { InputField, TextArea } from '@vartana-repo/base-components/form';
import { Button } from '@vartana-repo/base-components/buttons';
import { ForwardIcon, LeftArrow, SEND_MESSAGE_TO_VENDOR } from '../../assets';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Email must be valid').required('Email is required'),
  phone: Yup.string().test('len', 'Invalid phone number', (val) => {
    if (val) {
      return !val.includes('_');
    }
    return true;
  }),
  message: Yup.string().required('Order modification is required'),
});

const Heading = ({ sellerName }) => (
  <div className="flex flex-col gap-1 pb-4">
    <div className="flex items-center gap-2">
      <ForwardIcon className="w-8 h-8" />
      <span>Request {sellerName} to edit order</span>
    </div>
  </div>
);

export const MessageVendorModal = ({
  isOpen,
  onClose,
  sellerName,
  buyerDetails,
  onSuccess,
}) => {
  const initialValues = {
    firstName: buyerDetails ? buyerDetails.firstName : '',
    lastName: buyerDetails ? buyerDetails.lastName : '',
    email: buyerDetails ? buyerDetails.email : '',
    message: '',
  };

  const [sendMessageToVendor, { loading: sendingToVendor }] = useMutation(
    SEND_MESSAGE_TO_VENDOR,
    {
      onError: (error) => console.error(error),
    }
  );

  const handleSubmit = useCallback(
    async (values, { setErrors }) => {
      const { data, errors } = await sendMessageToVendor({ variables: { ...values } });
      if (errors) setErrors(errors);
      if (data) onSuccess();
    },
    [onSuccess, sendMessageToVendor]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} header={<Heading sellerName={sellerName} />}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched, isValid, dirty }) => {
          const isDisabled = !(isValid && dirty);
          return (
            <Form className="grid grid-cols-6 gap-4">
              <div className="col-span-6 md:col-span-3">
                <InputField
                  name="firstName"
                  type="text"
                  label="Recipient first name"
                  disabled
                  errorMsg={errors.firstName}
                  touched={touched.firstName}
                />
              </div>
              <div className="col-span-6 md:col-span-3">
                <InputField
                  name="lastName"
                  type="text"
                  disabled
                  label="Recipient last name"
                  errorMsg={errors.lastName}
                  touched={touched.lastName}
                />
              </div>
              <div className="col-span-6">
                <InputField
                  name="email"
                  type="email"
                  placeholder="example@gmail.com"
                  label="Email"
                  disabled
                  errorMsg={errors.email}
                  touched={touched.email}
                />
              </div>
              <div className="col-span-6 md:col-span-3">
                <InputField
                  name="phone"
                  type="text"
                  label="Phone (optional)"
                  mask="+1 (999) 999-9999"
                  placeholder="+1 (___) ___-____"
                  errorMsg={errors.phone}
                  touched={touched.phone}
                />
              </div>
              <div className="col-span-6">
                <Field
                  as="textarea"
                  name="message"
                  rows={4}
                  placeholder={`Tell ${sellerName} what needs to be updated in this order...`}
                >
                  {(props) => (
                    <TextArea
                      variant="outlined"
                      placeholder={`Tell ${sellerName} what needs to be updated in this order...`}
                      label="Order modifications"
                      {...props}
                    />
                  )}
                </Field>
              </div>
              <div className="flex items-center col-span-3 md:col-span-2">
                <Button variant="linkBlue" className="flex-start h-fit" onClick={onClose}>
                  <>
                    <LeftArrow />
                    Cancel
                  </>
                </Button>
              </div>
              <div className="col-span-3 md:col-span-4 flex justify-end">
                <Button
                  type="submit"
                  disabled={isDisabled}
                  isLoading={sendingToVendor}
                  className="w-32 md:w-48"
                >
                  Send
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

Heading.propTypes = {
  sellerName: PropTypes.string.isRequired,
};

MessageVendorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  sellerName: PropTypes.string.isRequired,
  buyerDetails: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
};

MessageVendorModal.defaultProps = {
  buyerDetails: null,
};

export default MessageVendorModal;
