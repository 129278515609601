import PropTypes from 'prop-types';

export function InputErrorField({ children, className }) {
  return (
    <div className={`field-helper-text text-vartana-red-160 ${className}`}>{children}</div>
  );
}
InputErrorField.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  className: PropTypes.string,
};
InputErrorField.defaultProps = {
  className: '',
};
