import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

/**
 * Shows tooltip when you hover over the `element`
 *
 * @param {JSX.Element} element - Anchor element on which to hover
 * @param {JSX.Element} tooltipContent - Content/Text to show inside tooltip
 * @returns {JSX.Element|null}
 * @constructor
 */
export function ToolTip({ element, tooltipContent }) {
  if (element) {
    const uniqueId = uuidv4();
    return (
      <>
        <div id={uniqueId}>{element}</div>
        <Tooltip
          html={tooltipContent}
          anchorId={uniqueId}
          className="bg-[#333333] shadow-lift-3 rounded z-10"
        />
      </>
    );
  }
  return null;
}

ToolTip.propTypes = {
  element: PropTypes.node.isRequired,
  tooltipContent: PropTypes.node.isRequired,
};
