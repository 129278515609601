import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import * as FullStory from '@fullstory/browser';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useRollbar, useRollbarPerson } from '@rollbar/react';

import { useLazyQuery } from '@apollo/client';
import { errorSubject, navigationService, GET_SESSION_DETAIL, getAPIURL } from '../assets';
import { SomethingWentWrongModal } from '../components/Modals';
import {
  PaymentOptions,
  BillingMethodPage,
  PersonalGuaranty,
  Verification,
  Signatures,
  ThankYou,
  ErrorPage,
  PersonalGuarantor,
  BusinessDetails,
  AuthorizedSigner,
  ThankYouPG,
} from './pages';
import { AppLayout } from './AppLayout';
import routePaths from './routes';
import CheckoutRoot from './CheckoutRoot';
import { PersonalGuarantorLayout } from './PersonalGuarantorLayout';
import { PGVerification } from './pages/PGVerification/PGVerification';

FullStory.init({
  orgId: process.env.NX_FULLSTORY_ID,
  devMode:
    process.env.NX_NODE_ENV === 'eksstaging' ||
    process.env.NX_NODE_ENV === 'staging' ||
    process.env.NX_NODE_ENV === 'qa' ||
    process.env.NX_NODE_ENV === 'preview' ||
    process.env.NX_NODE_ENV === 'development',
});

export function App({ setMetaData }) {
  const [showErrorModel, setShowErrorModel] = useState(false);

  const navigate = useNavigate();
  const rollbar = useRollbar();

  useEffect(() => {
    const subscription = errorSubject.subscribe((hasError) => {
      setShowErrorModel(!!hasError);
    });
    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [setShowErrorModel]);

  useEffect(() => {
    navigationService.navigate = navigate;
    navigationService.rollbar = rollbar;
  }, [navigate, rollbar]);

  useEffect(() => {
    FullStory.getCurrentSessionURL();
  }, []);

  async function setServerCookie(token) {
    return fetch(`${getAPIURL()}/set-cookie`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token,
        app: 'checkout-app',
      }),
      credentials: 'include',
    });
  }

  let companyNumber = null;
  const [fetchUserSession, { data: sessionData }] = useLazyQuery(GET_SESSION_DETAIL, {
    notifyOnNetworkStatusChange: true,
    onCompleted: async (response) => {
      companyNumber = get(response, 'session.creditAppraisal.company.seller.number', '');
    },
    onError: (error) => console.error('[GET_SESSION_DETAIL]', error),
  });

  const [searchParams] = useSearchParams();
  const token = searchParams.get('t');
  useEffect(() => {
    if (token) {
      setServerCookie(token).then((response) => response.ok && fetchUserSession());
    } else fetchUserSession();
  }, [fetchUserSession, token]);

  // Initialize LaunchDarkly client, or close in case of local env
  const ldClient = useLDClient();
  useEffect(() => {
    if (!isEmpty(companyNumber)) {
      if (process.env.NX_NODE_ENV !== 'development') {
        const ldContext = {
          key: companyNumber,
          kind: 'company',
        };
        ldClient.identify(ldContext, undefined);
      } else {
        ldClient.close();
      }
    }
  }, [ldClient, companyNumber]);

  // Set session user for Rollbar reporting
  const currentUser = get(sessionData, 'session.user', {});
  useRollbarPerson({
    id: currentUser?.id,
    email: currentUser?.email,
    username: currentUser?.email,
    name: currentUser?.fullName,
    company: {
      number: currentUser?.company?.number,
      name: currentUser?.company?.name,
    },
  });

  return (
    <IntercomProvider autoBoot appId={process.env.NX_INTERCOM_ID}>
      <Routes>
        <Route
          path="order/:orderNumber/checkout/"
          element={<CheckoutRoot setMetaData={setMetaData} />}
        />

        <Route
          path="order/:orderNumber/checkout/"
          element={<AppLayout setMetaData={setMetaData} />}
        >
          <Route path={routePaths.payment_options} element={<PaymentOptions />} />
          <Route path={routePaths.payment_method} element={<BillingMethodPage />} />
          <Route path={routePaths.personal_guarantee} element={<PersonalGuaranty />} />
          <Route path={routePaths.verification} element={<Verification />} />
          <Route path={routePaths.pg_verification} element={<PGVerification />} />
          <Route path={routePaths.signatures} element={<Signatures />} />
          <Route path={routePaths.completed} element={<ThankYou />} />
        </Route>

        <Route
          path="customer/"
          element={<PersonalGuarantorLayout setMetaData={setMetaData} />}
        >
          <Route path={routePaths.personal_guarantor} element={<PersonalGuarantor />} />
          <Route path={routePaths.business_information} element={<BusinessDetails />} />
          <Route path={routePaths.authorized_signer} element={<AuthorizedSigner />} />
          <Route path={routePaths.completed} element={<ThankYouPG />} />
        </Route>

        <Route
          path="/unauthorize"
          element={
            <ErrorPage
              errorTitle="Access Denied"
              errorMessage="Please reach out to Vartana through the support window below."
            />
          }
        />
        <Route path="/error" element={<ErrorPage errorTitle="Something went wrong" />} />
        <Route
          path="*"
          element={
            <ErrorPage
              errorTitle="Page not found"
              errorMessage="Sorry, the page you're looking for doesn't exist. Please reach out to Vartana through the support window below."
            />
          }
        />
      </Routes>

      <SomethingWentWrongModal isOpen={showErrorModel} />
    </IntercomProvider>
  );
}

App.propTypes = {
  setMetaData: PropTypes.func.isRequired,
};
