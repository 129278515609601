import { useEffect, useState } from 'react';
import { useFormik, FormikProvider, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Modal } from '@vartana-repo/base-components/modal';
import { InputField, SearchAddress, CustomSelect } from '@vartana-repo/base-components/form';
import { Button } from '@vartana-repo/base-components/buttons';
import { LeftArrow, InvoiceRecipientIcon, states } from '../../assets';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().test('len', 'Invalid phone number', (val) => {
    if (val) {
      return !val.includes('_');
    }
    return false;
  }),
  street: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string().required('ZipCode is required'),
});

const Heading = () => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-2 modal-heading">
        <InvoiceRecipientIcon className="w-8 h-8" />
        <span>Recipient for invoice</span>
      </div>
    </div>
  );
};

export const InvoiceRecipientModal = ({
  isOpen,
  recipientDetail,
  isSubmitting,
  onSubmit,
  onClose,
  onBack,
}) => {
  const formikBag = useFormik({
    initialValues: recipientDetail,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });
  const { isValid, dirty, touched, errors, setFieldValue, validateField } = formikBag;
  const [selectedPlace, setSelectedPlace] = useState({});

  useEffect(() => {
    if (selectedPlace.city) {
      setFieldValue('city', selectedPlace.city).then(() => validateField('city'));
    }
    if (selectedPlace.state?.shortName) {
      setFieldValue('state', selectedPlace.state.shortName).then(() => validateField('state'));
    }
    if (selectedPlace.zip) {
      setFieldValue('zip', selectedPlace.zip).then(() => validateField('zip'));
    }
  }, [selectedPlace, setFieldValue, validateField]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} showCloseIcon>
      <>
        <Heading />
        <FormikProvider value={formikBag}>
          <Form className="grid grid-cols-6 gap-4">
            <div className="col-span-6 md:col-span-3">
              <InputField
                name="firstName"
                label="First name"
                errorMsg={errors.firstName}
                touched={touched.firstName}
              />
            </div>

            <div className="col-span-6 md:col-span-3">
              <InputField
                name="lastName"
                label="Last name"
                errorMsg={errors.lastName}
                touched={touched.lastName}
              />
            </div>

            <div className="col-span-6 md:col-span-3">
              <InputField
                name="email"
                label="Email"
                errorMsg={errors.email}
                touched={touched.email}
              />
            </div>

            <div className="col-span-6 md:col-span-3">
              <InputField
                name="phone"
                label="Phone"
                mask="+1 (999) 999-9999"
                placeholder="+1 (___) ___-____"
                errorMsg={errors.phone}
                touched={touched.phone}
              />
            </div>

            <div className="col-span-6">
              <SearchAddress
                name="street"
                label="Address"
                afterPlaceSelect={(tempSelectedPlace) => setSelectedPlace(tempSelectedPlace)}
              />
            </div>

            <div className="col-span-6 md:col-span-3">
              <InputField
                name="city"
                label="City"
                errorMsg={errors.city}
                touched={touched.city}
              />
            </div>

            <div className="col-span-6 md:col-span-3 grid grid-cols-6 gap-4">
              <div className="col-span-6 md:col-span-3">
                <CustomSelect name="state" label="State" options={states} />
              </div>

              <div className="col-span-6 md:col-span-3">
                <InputField
                  name="zip"
                  label="Zip"
                  placeholder="XXXXX"
                  errorMsg={errors.zip}
                  touched={touched.zip}
                />
              </div>
            </div>

            <div className="flex items-center col-span-3 md:col-span-2">
              <Button
                variant="linkBlue"
                className="flex-start w-fit mt-6 h-fit"
                onClick={onBack}
              >
                <LeftArrow /> Cancel
              </Button>
            </div>

            <div className="col-span-3 md:col-span-4 flex justify-end">
              <Button
                type="submit"
                disabled={!(isValid && dirty) || isSubmitting}
                isLoading={isSubmitting}
                className="w-32 md:w-48 mt-6"
              >
                Submit
              </Button>
            </div>
          </Form>
        </FormikProvider>
      </>
    </Modal>
  );
};
InvoiceRecipientModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  recipientDetail: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

InvoiceRecipientModal.defaultProps = {
  recipientDetail: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    state: '',
    zip: '',
  },
};
