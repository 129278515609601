import { useCallback } from 'react';
import { ErrorMessage, useField } from 'formik';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

import { ToolTip } from '@vartana-repo/base-components/miscellaneous';
import { InfoIcon, VerifiedIcon } from '../../../../assets';
import { Label } from '../label/Label';
import { InputErrorField } from './InputErrorField';
import '../form.scss';

export function InputField({
  name,
  label,
  errorMsg,
  touched,
  prefix,
  autoComplete,
  mask,
  className,
  disableFullStory,
  tooltipContent,
  disabled,
  format,
  thousandSeparator,
  isNumeric,
  decimalScale,
  isLoading,
  showVerified,
  ...rest
}) {
  const showError = touched && errorMsg;
  const [field] = useField(name);
  const getInputProps = useCallback(() => {
    const defaultClass =
      'disabled:bg-vartana-gray-30 w-full focus:outline-0 field-text p-3 rounded-lg';
    return {
      ...rest,
      ...field,
      autoComplete,
      className: `${defaultClass} ${prefix ? 'pl-6' : ''} ${
        disableFullStory ? 'fs-mask' : ''
      }`,
    };
  }, [autoComplete, disableFullStory, field, prefix, rest]);

  const getField = useCallback(() => {
    if (mask) {
      // Todo: this can be updated to use same package as numericFormat.
      return (
        <InputMask
          mask={mask}
          maskPlaceholder={rest.placeholder || ''}
          disabled={disabled}
          {...getInputProps()}
        />
      );
    }
    if (isNumeric) {
      return (
        <NumericFormat
          {...getInputProps()}
          decimalScale={decimalScale}
          name={field.name}
          thousandSeparator=","
          disabled={disabled}
        />
      );
    }
    return <input disabled={disabled} id={name} {...getInputProps(field)} />;
  }, [decimalScale, disabled, field, getInputProps, isNumeric, mask, name, rest.placeholder]);

  return (
    <div className="flex flex-col gap-1">
      <div className="field-wrapper">
        {label ? (
          <div className="flex gap-1">
            <Label htmlFor={name} className={`${showError ? 'text-vartana-red-160' : ''}`}>
              {label}
            </Label>
            {tooltipContent ? (
              <ToolTip
                element={<InfoIcon className="w-4 h-4" />}
                tooltipContent={tooltipContent}
              />
            ) : null}
          </div>
        ) : null}
        <div
          className={`
                p-0 field--base
                ${disabled ? 'bg-vartana-gray-30 border-vartana-gray-30' : ''}
                ${showError ? 'field--error' : ''}
                ${prefix ? 'items-center gap-0.5 relative' : ''}
                ${className}
              `}
        >
          {prefix && (
            <span className={prefix ? 'absolute left-3 field-text' : ''}>{prefix}</span>
          )}
          {getField()}
          {isLoading && (
            <div className="flex mr-2 items-center">
              <span className="bg-vartana-blue-60 h-1.5 w-1.5 mx-1 my-1 rounded-full animate-loader animation-delay-100"></span>
              <span className="bg-vartana-blue-60 h-1.5 w-1.5 mx-1 my-1 rounded-full animate-loader animation-delay-200"></span>
              <span className="bg-vartana-blue-60 h-1.5 w-1.5 mx-1 my-1 rounded-full animate-loader animation-delay-400"></span>
            </div>
          )}
          {showVerified && !isLoading && (
            <div className="p-3">
              <VerifiedIcon className="w-5 h-5" />
            </div>
          )}
        </div>
      </div>
      <ErrorMessage name={name} component={InputErrorField} />
    </div>
  );
}
InputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
  touched: PropTypes.bool,
  prefix: PropTypes.string,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  mask: PropTypes.string,
  className: PropTypes.string,
  disableFullStory: PropTypes.bool,
  tooltipContent: PropTypes.node,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  thousandSeparator: PropTypes.string,
  isNumeric: PropTypes.bool,
  decimalScale: PropTypes.number,
  isLoading: PropTypes.bool,
  showVerified: PropTypes.bool,
};
InputField.defaultProps = {
  label: '',
  errorMsg: '',
  touched: false,
  prefix: '',
  autoComplete: 'on',
  mask: '',
  className: '',
  disableFullStory: false,
  tooltipContent: '',
  disabled: false,
  format: null,
  thousandSeparator: null,
  isNumeric: false,
  decimalScale: null,
  isLoading: false,
  showVerified: false,
};
export default InputField;
