import PropTypes from 'prop-types';
import { CrossIcon } from '../../assets';
import { OrderSummary } from '../../components/OrderSummary';

export function SummarySection({
  summaryLoading,
  paymentSummary,
  orderState,
  showSummaryOnMobile,
  onClose,
}) {
  return (
    <div
      className={`
        ${showSummaryOnMobile ? 'block' : 'hidden'} overflow-y-scroll
        pt-4 pb-8 md:py-10 px-4 md:px-8 bg-vartana-blue-10
        lg:block w-full lg:w-5/12
      `}
    >
      <span className="flex items-top justify-end w-full lg:hidden">
        <CrossIcon onClick={onClose} />
      </span>
      <div className="px-4">
        <OrderSummary
          paymentSummary={paymentSummary}
          summaryLoading={summaryLoading}
          orderState={orderState}
        />
      </div>
    </div>
  );
}

SummarySection.propTypes = {
  paymentSummary: OrderSummary.propTypes.paymentSummary,
  summaryLoading: OrderSummary.propTypes.summaryLoading,
  orderState: OrderSummary.propTypes.orderState,
  showSummaryOnMobile: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SummarySection.defaultProps = {
  paymentSummary: {},
  summaryLoading: OrderSummary.defaultProps.summaryLoading,
  orderState: '',
};
