import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Button } from '@vartana-repo/base-components/buttons';
import {
  Checkbox,
  CustomSelect,
  InputField,
  SearchAddress,
} from '@vartana-repo/base-components/form';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { get, omit } from 'lodash';

import { ToolTip } from '@vartana-repo/base-components/miscellaneous';
import { UPSERT_AUTHORIZED_SIGNER } from '../../../assets/graphql/personalGuarantor';
import { FilesIcon, InfoIcon, RightArrow, states, useSetAddressFields } from '../../../assets';
import { ForwardPGModal } from '../../../components/Modals';
import { JobTitles } from '../Verification/JobTitles';
import {
  authSignFormSchema,
  initialAuthSignerFormValues,
} from '../../../assets/constants/personalGuarantor.constants';

const PageHeader = ({ titleIcon, title, subtitle1, subtitle2, linkAction, linkText }) => (
  <div className="flex flex-col pb-6">
    <span className="flex gap-1 pb-2 page-title-small lg:page-title text-vartana-black-100">
      {titleIcon}
      {title}
    </span>
    <h1 className="body inline relative pb-3">{subtitle1}</h1>
    <div className="flex flex-col md:flex-row gap-2 body items-start lg:items-center">
      <span className="body">{subtitle2}</span>
      {linkText && (
        <Button variant="linkBlue" onClick={linkAction}>
          {linkText}
        </Button>
      )}
    </div>
  </div>
);

PageHeader.propTypes = {
  titleIcon: PropTypes.node,
  title: PropTypes.string.isRequired,
  subtitle1: PropTypes.string.isRequired,
  subtitle2: PropTypes.string,
  linkText: PropTypes.string,
  linkAction: PropTypes.func,
};

PageHeader.defaultProps = {
  titleIcon: null,
  subtitle2: '',
  linkText: '',
  linkAction: undefined,
};

export function AuthorizedSigner() {
  const [, refetchSessionDetails] = useOutletContext();
  const [showForwardPGModal, setShowForwardPGModal] = useState(false);
  const [updateAuthSigner] = useMutation(UPSERT_AUTHORIZED_SIGNER);

  const pageHeaders = {
    titleIcon: <FilesIcon />,
    title: 'You’re almost there',
    subtitle1:
      'Complete the following information to generate payment options for your orders.',
    subtitle2: "Don't have information?",
    linkText: 'Forward information request',
    linkAction: () => setShowForwardPGModal(true),
  };

  const handleSubmit = async (values, { setErrors }) => {
    updateAuthSigner({
      variables: {
        authorizedSigner: {
          jobTitle: values.title,
          ...omit(values, ['authSignerDisclaimer', 'title']),
        },
      },
    })
      .then((response) => {
        const errors = get(response, 'errors', {});
        if (Object.keys(errors).length) {
          setErrors(errors);
        } else {
          refetchSessionDetails();
        }
      })
      .catch((error) => {
        // Todo: Add ui changes for error.
        console.error('[UPSERT_AUTHORIZED_SIGNER] - ', error);
      });
  };

  const formikBag = useFormik({
    initialValues: initialAuthSignerFormValues,
    validationSchema: authSignFormSchema,
    onSubmit: handleSubmit,
  });
  const { isValid, dirty, touched, errors, setFieldValue, validateField } = formikBag;
  const setSelectedPlace = useSetAddressFields(setFieldValue, validateField);

  return (
    <>
      <PageHeader
        titleIcon={pageHeaders.titleIcon}
        title={pageHeaders.title}
        subtitle1={pageHeaders.subtitle1}
        subtitle2={pageHeaders.subtitle2}
        message={pageHeaders.message}
        linkText={pageHeaders.linkText}
        linkAction={pageHeaders.linkAction}
      />
      <div className="flex gap-1 items-center pb-2">
        <span className="card-title-bold">Authorized signer</span>
        <ToolTip
          element={<InfoIcon className="w-4 h-4" />}
          tooltipContent={
            <p>
              An authorized signer is a person who can make <br />
              financial decisions on behalf of the company.
            </p>
          }
        />
      </div>
      <FormikProvider value={formikBag}>
        <Form>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 md:col-span-6">
              <InputField
                id="firstName"
                name="firstName"
                label="Legal first name"
                errorMsg={errors.firstName}
                touched={touched.firstName}
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <InputField
                id="lastName"
                name="lastName"
                label="Last name"
                errorMsg={errors.lastName}
                touched={touched.lastName}
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <InputField
                id="email"
                name="email"
                label="Email"
                placeholder="example@email.com"
                errorMsg={errors.email}
                touched={touched.email}
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <InputField
                id="phone"
                name="phone"
                label="Phone number"
                mask="+1 (999) 999-9999"
                placeholder="+1 (___) ___-____"
                errorMsg={errors.phone}
                touched={touched.phone}
              />
            </div>
            <div className="col-span-12">
              <JobTitles id="title" name="title" label="Job title" />
            </div>
            <div className="col-span-12">
              <SearchAddress
                id="street"
                name="street"
                label="Primary address"
                afterPlaceSelect={(tempSelectedPlace) => setSelectedPlace(tempSelectedPlace)}
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <InputField
                id="city"
                name="city"
                label="City"
                errorMsg={errors.city}
                touched={touched.city}
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <CustomSelect id="state" name="state" label="State" options={states} />
            </div>
            <div className="col-span-12 md:col-span-3">
              <InputField
                id="zip"
                name="zip"
                label="Zip"
                placeholder="XXXXX"
                errorMsg={errors.zip}
                touched={touched.zip}
              />
            </div>
            <div className="col-span-12">
              <Checkbox
                name="authSignerDisclaimer"
                labelClassName="text-vartana-gray-140 pt-6"
                longText
                label={
                  <span>
                    By checking this box, I acknowledge that I am providing personal
                    information of the individual that can act as the authorized signer.
                    Vartana may review their credit history through a soft credit check which
                    will not impact their credit score.
                  </span>
                }
              />
            </div>
          </div>

          <div className="flex items-center justify-end pt-8">
            <Button
              type="submit"
              disabled={!isValid || !dirty}
              className="w-40 flex gap-0.5 items-center"
            >
              Next <RightArrow />
            </Button>
          </div>
        </Form>
      </FormikProvider>
      <ForwardPGModal
        isOpen={showForwardPGModal}
        onClose={() => setShowForwardPGModal(false)}
      />
    </>
  );
}
