import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Button } from '@vartana-repo/base-components/buttons';
import {
  CustomSelect,
  DateInput,
  InputField,
  Label,
  MultiFileUpload,
  SearchAddress,
} from '@vartana-repo/base-components/form';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useMutation } from '@apollo/client';
import { get, omit } from 'lodash';

import {
  UPDATE_BUSINESS_DETAILS,
  UPDATE_CREDIT_APPRAISAL_APPLICATION_STEPS,
} from '../../../assets/graphql/personalGuarantor';
import {
  FilesIcon,
  businessDetailsFormSchema,
  financialDocsFormSchema,
  initialBusinessDetailsFormValues,
  reportError,
  states,
  useDirectUploadFilesAppraisal,
  useSetAddressFields,
} from '../../../assets';
import { FinancialDetailsModal, ForwardPGModal } from '../../../components/Modals';

const PageHeader = ({ titleIcon, title, subtitle1, subtitle2, linkAction, linkText }) => (
  <div className="flex flex-col pb-6">
    <span className="flex gap-1 pb-2 page-title-small lg:page-title text-vartana-black-100">
      {titleIcon}
      {title}
    </span>
    <h1 className="body inline relative pb-3">{subtitle1}</h1>
    <div className="flex flex-col md:flex-row gap-2 body items-start lg:items-center">
      <span className="body">{subtitle2}</span>
      {linkText && (
        <Button variant="linkBlue" onClick={linkAction}>
          {linkText}
        </Button>
      )}
    </div>
  </div>
);

PageHeader.propTypes = {
  titleIcon: PropTypes.node,
  title: PropTypes.string.isRequired,
  subtitle1: PropTypes.string.isRequired,
  subtitle2: PropTypes.string,
  linkText: PropTypes.string,
  linkAction: PropTypes.func,
};

PageHeader.defaultProps = {
  titleIcon: null,
  subtitle2: '',
  linkText: '',
  linkAction: undefined,
};

export function BusinessDetails() {
  const [{ sessionDetail }, refetchSessionDetails] = useOutletContext();
  const [showForwardPGModal, setShowForwardPGModal] = useState(false);
  const [isFinancialDialogOpen, setIsFinancialDialogOpen] = useState(false);
  const [updateBusinessDetails] = useMutation(UPDATE_BUSINESS_DETAILS);
  const [updateCreditAppraisalApplicationSteps] = useMutation(
    UPDATE_CREDIT_APPRAISAL_APPLICATION_STEPS,
    {
      onError: (error) => console.error('[UPDATE_CREDIT_APPRAISAL_APPLICATION_STEPS]', error),
    }
  );
  const [directUploadFilesAppraisal] = useDirectUploadFilesAppraisal();
  const [validationSchema, setValidationSchema] = useState({});
  const [financialDocsRequired, setFinancialDocsRequired] = useState(false);
  const [businessDetailsRequired, setBusinessDetailsRequired] = useState(false);

  const pageHeaders = {
    titleIcon: <FilesIcon />,
    title: 'You’re almost there',
    subtitle1:
      'Complete the following information to generate payment options for your orders.',
    subtitle2: "Don't have information?",
    linkText: 'Forward information request',
    linkAction: () => setShowForwardPGModal(true),
  };

  const handleSubmit = async (values, { setErrors }) => {
    try {
      let response = null;
      if (financialDocsRequired && businessDetailsRequired) {
        await directUploadFilesAppraisal([...values.financialDocs], {
          documentType: 'financial',
          companyId: get(sessionDetail, 'session.user.company.id', null),
          creditAppraisalId: get(sessionDetail, 'session.creditAppraisal.id', null),
        });
        response = await updateBusinessDetails({
          variables: {
            ...omit(values, ['financialDocsRequired', 'financialDocs']),
            annualRevenue: +values.annualRevenue.replaceAll(',', ''),
          },
        });
      } else if (financialDocsRequired) {
        await directUploadFilesAppraisal([...values.financialDocs], {
          documentType: 'financial',
          companyId: get(sessionDetail, 'session.user.company.id', null),
          creditAppraisalId: get(sessionDetail, 'session.creditAppraisal.id', null),
        });
        await updateCreditAppraisalApplicationSteps();
      } else if (businessDetailsRequired) {
        response = await updateBusinessDetails({
          variables: {
            ...omit(values, ['financialDocsRequired', 'financialDocs']),
            annualRevenue: +values.annualRevenue.replaceAll(',', ''),
          },
        });
      }
      const errors = get(response, 'errors', {});
      if (Object.keys(errors).length) {
        setErrors(errors);
      } else {
        refetchSessionDetails();
      }
    } catch (error) {
      reportError(error);
    }
  };

  const formikBag = useFormik({
    initialValues: initialBusinessDetailsFormValues,
    validationSchema,
    onSubmit: handleSubmit,
  });
  const { isValid, dirty, touched, errors, setFieldValue, validateField } = formikBag;
  const setSelectedPlace = useSetAddressFields(setFieldValue, validateField);

  useEffect(() => {
    const areFinancialDocsRequired = get(
      sessionDetail,
      'session.creditAppraisal.applicationSteps',
      []
    ).some((applicationStep) => applicationStep.name === 'financial_documents');
    const areBusinessDetailsRequired = get(
      sessionDetail,
      'session.creditAppraisal.applicationSteps',
      []
    ).some(
      (applicationStep) =>
        applicationStep.name === 'business_details' && applicationStep.status === 'pending'
    );
    setBusinessDetailsRequired(areBusinessDetailsRequired);
    setFinancialDocsRequired(areFinancialDocsRequired);
    if (areFinancialDocsRequired && areBusinessDetailsRequired)
      setValidationSchema(businessDetailsFormSchema.concat(financialDocsFormSchema));
    else if (areFinancialDocsRequired) setValidationSchema(financialDocsFormSchema);
    else if (areBusinessDetailsRequired) setValidationSchema(businessDetailsFormSchema);
  }, [sessionDetail]);

  return (
    <>
      <PageHeader
        titleIcon={pageHeaders.titleIcon}
        title={pageHeaders.title}
        subtitle1={pageHeaders.subtitle1}
        subtitle2={pageHeaders.subtitle2}
        message={pageHeaders.message}
        linkText={pageHeaders.linkText}
        linkAction={pageHeaders.linkAction}
      />
      <span className="card-title-bold pb-2">Business information</span>
      <FormikProvider value={formikBag}>
        <Form>
          <div className="grid grid-cols-12 gap-4">
            {businessDetailsRequired ? (
              <>
                <div className="col-span-12 md:col-span-6">
                  <InputField
                    id="businessName"
                    name="businessName"
                    label="Legal company name"
                    errorMsg={errors.businessName}
                    touched={touched.businessName}
                  />
                </div>
                <div className="col-span-12 md:col-span-6">
                  <InputField
                    id="dba"
                    name="dba"
                    label="Also known as (optional)"
                    errorMsg={errors.dba}
                    touched={touched.dba}
                    tooltipContent={
                      <p>
                        Also known as or DBA (Doing <br />
                        Business As) is another name a <br />
                        company operates under.
                      </p>
                    }
                  />
                </div>
                <div className="col-span-12 md:col-span-4">
                  <InputField
                    id="ein"
                    name="ein"
                    label="EIN number"
                    mask="99-9999999"
                    placeholder="__-_______"
                    errorMsg={errors.ein}
                    touched={touched.ein}
                    disableFullStory
                    tooltipContent={
                      <p>
                        EIN (Employer Identification <br />
                        Number) is a nine-digit number <br />
                        the IRS uses to identify a <br />
                        business's tax records.
                      </p>
                    }
                  />
                </div>
                <div className="col-span-12 md:col-span-4">
                  <DateInput
                    id="dateStarted"
                    name="dateStarted"
                    label="Year company founded"
                    placeholder="YYYY"
                    dateFormat="yyyy"
                    minDate={new Date(moment().subtract(100, 'years'))}
                    maxDate={new Date()}
                  />
                </div>
                <div className="col-span-12 md:col-span-4">
                  <InputField
                    id="annualRevenue"
                    name="annualRevenue"
                    label="Annual revenue"
                    thousandSeparator=","
                    isNumeric
                    decimalScale={0}
                    prefix="$"
                    errorMsg={errors.annualRevenue}
                    touched={touched.annualRevenue}
                    tooltipContent={
                      <p>
                        Annual revenue is the total <br />
                        income of a company over 12 <br />
                        months.
                      </p>
                    }
                  />
                </div>
                <div className="col-span-12">
                  <SearchAddress
                    id="street"
                    name="street"
                    label="Primary address"
                    afterPlaceSelect={(tempSelectedPlace) =>
                      setSelectedPlace(tempSelectedPlace)
                    }
                  />
                </div>
                <div className="col-span-12 md:col-span-6">
                  <InputField
                    id="city"
                    name="city"
                    label="City"
                    errorMsg={errors.city}
                    touched={touched.city}
                  />
                </div>
                <div className="col-span-12 md:col-span-3">
                  <CustomSelect id="state" name="state" label="State" options={states} />
                </div>
                <div className="col-span-12 md:col-span-3">
                  <InputField
                    id="zip"
                    name="zip"
                    label="Zip"
                    placeholder="XXXXX"
                    errorMsg={errors.zip}
                    touched={touched.zip}
                  />
                </div>
              </>
            ) : null}
            {financialDocsRequired ? (
              <div className="col-span-12 flex flex-col gap-1">
                <div className="flex gap-1">
                  <Label htmlFor="financialDocs">2 years of financial documents:</Label>
                  <button
                    type="button"
                    className="p-small-bold text-vartana-blue-120"
                    onClick={() => setIsFinancialDialogOpen(true)}
                  >
                    Learn more
                  </button>
                </div>
                <MultiFileUpload acceptMultipleFiles id="financialDocs" name="financialDocs" />
              </div>
            ) : null}
          </div>
          <div>
            <div className="flex items-center justify-end pt-8">
              <Button type="submit" disabled={!isValid || !dirty} className="w-40">
                Submit
              </Button>
            </div>
          </div>
        </Form>
      </FormikProvider>
      <FinancialDetailsModal
        open={isFinancialDialogOpen}
        handleClose={() => setIsFinancialDialogOpen(false)}
      />
      <ForwardPGModal
        isOpen={showForwardPGModal}
        onClose={() => setShowForwardPGModal(false)}
      />
    </>
  );
}
