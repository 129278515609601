import { useEffect, useState, useMemo } from 'react';
import { get } from 'lodash';
import { AlertIcon } from '../../assets';

const getEinErrorMessage = (einFailed, personaFailed, ofacFailed) => {
  let errorMessage = '';
  if (!einFailed && !personaFailed && ofacFailed) {
    errorMessage =
      'We were unable to verify your information. Support team will be in touch momentarily.';
  } else if (!einFailed && personaFailed && ofacFailed) {
    errorMessage =
      'We were unable to verify your personal information. Please update and try again.';
  } else if (einFailed && personaFailed && ofacFailed) {
    errorMessage =
      'We were unable to verify your personal and company information. Please update and try again.';
  } else if (einFailed && !personaFailed && !ofacFailed) {
    errorMessage =
      'We were unable to verify your company information. Please update and try again.';
  } else if (einFailed && !personaFailed && ofacFailed) {
    errorMessage =
      'We were unable to verify your information. Support team will be in touch momentarily.';
  } else if (einFailed && personaFailed && !ofacFailed) {
    errorMessage =
      'We were unable to verify your personal and company information. Please update and try again.';
  }
  return errorMessage;
};

export function useVerification(orderDetail, user) {
  const [einVerificationFailed, setEinVerificationFailed] = useState(null);
  const [showEinLoader, setShowEinLoader] = useState(false);
  const [personaVerificationFailed, setPersonaVerificationFailed] = useState(false);
  const [ofacVerificationFailed, setOfacVerificationFailed] = useState(false);

  const isEinAlreadyVerified = get(orderDetail, 'company.einIsVerified');
  const preExistingEinValue = get(orderDetail, 'company.entityInformationEin');
  const personaBypassed = get(user, 'isPersonaVerificationBypassed', false);

  const [disableEIN, setDisableEIN] = useState(
    !!(isEinAlreadyVerified && preExistingEinValue)
  );

  useEffect(() => {
    setDisableEIN(!!(isEinAlreadyVerified && preExistingEinValue));
  }, [isEinAlreadyVerified, preExistingEinValue]);

  const einErrorMessage = useMemo(() => {
    return (
      (einVerificationFailed || personaVerificationFailed || ofacVerificationFailed) &&
      !personaBypassed && (
        <div className="text-vartana-red-160 bg-vartana-red-20 px-4 py-3 ">
          <div className="flex gap-1 justify-between">
            <div className="flex">
              <div className="w-4">
                <AlertIcon className="w-4 h-4" />
              </div>
              <span className="p-small pl-1">
                {getEinErrorMessage(
                  einVerificationFailed,
                  personaVerificationFailed,
                  ofacVerificationFailed
                )}
              </span>
            </div>
          </div>
        </div>
      )
    );
  }, [
    einVerificationFailed,
    personaVerificationFailed,
    ofacVerificationFailed,
    personaBypassed,
  ]);

  return {
    einVerificationFailed,
    setEinVerificationFailed,
    showEinLoader,
    setShowEinLoader,
    isEinAlreadyVerified,
    preExistingEinValue,
    disableEIN,
    setDisableEIN,
    setPersonaVerificationFailed,
    personaVerificationFailed,
    einErrorMessage,
    setOfacVerificationFailed,
  };
}
