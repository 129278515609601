import { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Persona from 'persona';
import { capitalize, get, map, pick } from 'lodash';
import { useMutation } from '@apollo/client';

import { Button } from '@vartana-repo/base-components/buttons';
import { Loader } from '@vartana-repo/base-components/loader';

import {
  initiateVerificationInquiry,
  getVerificationSummary,
  UPDATE_USER_INFO,
} from '../../../assets';

import { PersonInfoCard } from '../../../components/Cards';

import UserFields from './UserFields';

function PersonaKYC({
  isLoading = false,
  user,
  isTestMode,
  formValues,
  kycPreferences,
  errors,
  touched,
  setIsVerified,
  setPersonaVerificationFailed,
  setOfacVerificationFailed,
  disablePersonaFields,
  setDisablePersonaFields,
}) {
  const [verificationSummaryLoading, setVerificationSummaryLoading] = useState(false);
  const [personaCompleted, setPersonaCompleted] = useState(false);
  const [personaInquiryId, setPersonaInquiryId] = useState(null);
  const [clientLoading, setClientLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [verified, setVerified] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const personaEnvironment = useMemo(() => {
    return isTestMode
      ? process.env.NX_PERSONA_SANDBOX_ENVIRONMENT
      : process.env.NX_PERSONA_ENVIRONMENT;
  }, [isTestMode]);

  const [updateUserInfo] = useMutation(UPDATE_USER_INFO, {
    onError: (error) => console.error('[UPDATE_USER_INFO]', error),
  });

  const getLatestVerificationSummary = useCallback(async () => {
    if (personaInquiryId) {
      setVerificationSummaryLoading(true);
      const { status } = await getVerificationSummary(personaInquiryId);

      if (status === 'approved' || get(user, 'isPersonaVerificationBypassed', false)) {
        setIsVerified(true);
        setPersonaVerificationFailed(false);
        setOfacVerificationFailed(false);
        setVerified(true);
      } else if (status === 'declined') {
        setPersonaVerificationFailed(false);
        setOfacVerificationFailed(true);
        setDisablePersonaFields(true);
        setVerified(false);
      } else {
        setVerified(false);
      }

      setVerificationSummaryLoading(false);
      setPersonaCompleted(false);
      setShowResults(true);
    }
  }, [
    personaInquiryId,
    user,
    setIsVerified,
    setOfacVerificationFailed,
    setPersonaVerificationFailed,
    setDisablePersonaFields,
  ]);

  useEffect(() => {
    setVerified(get(user, 'verified', false));
    setUserInfo({
      fullName: user?.fullName,
      email: user?.email,
      formattedPhone: user?.formattedPhone,
      formattedTitle: map(user?.title?.split('_'), capitalize).join(' '),
    });
  }, [user]);

  useEffect(() => {
    if (personaCompleted) getLatestVerificationSummary();
  }, [getLatestVerificationSummary, personaCompleted]);

  useEffect(() => {
    const inquiryStatus = get(user, 'personaInquiryStatus', '');
    setOfacVerificationFailed(inquiryStatus === 'declined');
    setDisablePersonaFields(inquiryStatus === 'declined');
  }, [user, setOfacVerificationFailed, setDisablePersonaFields]);

  const onButtonClick = useCallback(
    (inquiryTemplateId) => {
      if (user && inquiryTemplateId?.length) {
        setClientLoading(true);
        updateUserInfo({
          variables: pick(formValues, ['firstName', 'lastName', 'title']),
        });
        const { id, email, formattedPhone } = user;
        const personaConfig = {
          templateId: inquiryTemplateId,
          environment: personaEnvironment,
          fields: {
            nameFirst: formValues.firstName,
            nameLast: formValues.lastName,
          },
          onLoad: (error) => {
            if (error)
              throw new Error(
                `Persona: Failed with code: ${error.code} and message ${error.message}`
              );
          },
          onStart: async (newInquiryId) => {
            await initiateVerificationInquiry(newInquiryId);
            setPersonaInquiryId(newInquiryId);
          },
          onError: (error) => {
            throw new Error('Persona Error', error);
          },
          onFail: () => {
            setShowResults(true);
            setPersonaVerificationFailed(true);
            setOfacVerificationFailed(true);
          },
          onComplete: () => {
            setUserInfo({
              fullName: `${formValues.firstName} ${formValues.lastName}`,
              email,
              formattedPhone,
              formattedTitle: map(formValues.title.split('_'), capitalize).join(' '),
            });
            setPersonaCompleted(true);
          },
          onEvent: (name, metadata) => {
            if (metadata?.status === 'declined') {
              setPersonaVerificationFailed(false);
              setOfacVerificationFailed(true);
              setDisablePersonaFields(true);
            }
          },
          referenceId: id,
        };

        const client = new Persona.Client(personaConfig);
        client.open();

        setClientLoading(false);
      }
    },
    [
      user,
      personaEnvironment,
      formValues,
      updateUserInfo,
      setOfacVerificationFailed,
      setPersonaVerificationFailed,
      setDisablePersonaFields,
    ]
  );

  const allowedVerificationKYCPreferences = useMemo(() => {
    const allowed = [];
    const userFieldsFilled = formValues.firstName && formValues.lastName && formValues.title;

    const options = {
      dob: (
        <Button
          key="dob"
          variant="selector"
          onClick={() => onButtonClick(`${process.env.NX_DOB_TEMPLATE}`)}
          disabled={
            clientLoading ||
            isLoading ||
            verified ||
            verificationSummaryLoading ||
            !userFieldsFilled ||
            disablePersonaFields
          }
          className="font-semibold leading-6 text-vartana-blue-180"
        >
          DOB
        </Button>
      ),
      state_id: (
        <Button
          key="state_id"
          variant="selector"
          onClick={() => onButtonClick(`${process.env.NX_GOVT_ID_TEMPLATE}`)}
          disabled={
            clientLoading ||
            isLoading ||
            verified ||
            verificationSummaryLoading ||
            !userFieldsFilled ||
            disablePersonaFields
          }
          className="font-semibold leading-6 text-vartana-blue-180"
        >
          State ID
        </Button>
      ),
      ssn: (
        <Button
          key="ssn"
          variant="selector"
          onClick={() => onButtonClick(`${process.env.NX_DB_VERIFICATION_TEMPLATE}`)}
          disabled={
            clientLoading ||
            isLoading ||
            verified ||
            verificationSummaryLoading ||
            !userFieldsFilled ||
            disablePersonaFields
          }
          className="font-semibold leading-6 text-vartana-blue-180"
        >
          SSN
        </Button>
      ),
    };

    if (kycPreferences.length) kycPreferences.forEach((p) => allowed.push(options[p]));
    else Object.keys(options).forEach((p) => allowed.push(options[p]));

    return allowed;
  }, [
    kycPreferences,
    clientLoading,
    formValues,
    isLoading,
    onButtonClick,
    verificationSummaryLoading,
    verified,
    disablePersonaFields,
  ]);

  return verified ? (
    <div className="flex flex-col gap-2">
      <span className="text-vartana-black-100 body">You’re verified!</span>
      <PersonInfoCard person={userInfo} verified />
    </div>
  ) : (
    <>
      <UserFields verifyFailed={showResults} errors={errors} touched={touched} />
      <div className="flex flex-col">
        <p className="text-vartana-black-100 field-label pb-1.5">
          Verify your identity (select option)
        </p>
        <Loader loading={isLoading} containerClassName="m-20">
          <div className="flex flex-col md:flex-row gap-3">
            {allowedVerificationKYCPreferences}
          </div>
        </Loader>
      </div>
    </>
  );
}

PersonaKYC.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  formValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  isTestMode: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    formattedPhone: PropTypes.string,
    personaInquiryId: PropTypes.string,
    verified: PropTypes.bool,
  }).isRequired,
  kycPreferences: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.shape({}).isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  setIsVerified: PropTypes.func.isRequired,
  setPersonaVerificationFailed: PropTypes.func.isRequired,
  setOfacVerificationFailed: PropTypes.func.isRequired,
  disablePersonaFields: PropTypes.bool.isRequired,
  setDisablePersonaFields: PropTypes.func.isRequired,
};

PersonaKYC.defaultProps = {
  kycPreferences: [],
};

export default PersonaKYC;
