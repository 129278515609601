import PropTypes from 'prop-types';
import { capitalize, get, isEmpty, upperCase } from 'lodash';
import { getUSDateFormat } from '../../assets';
import { SummaryItem } from './SummaryItem';

// Order Types
const FULL_PAYMENT = 'Full Payment';

export function SummaryData({ paymentSummary }) {
  const startDate = getUSDateFormat(get(paymentSummary, 'startDate'));
  const endDate = getUSDateFormat(get(paymentSummary, 'endDate', '-'));
  const paymentSchedule = get(paymentSummary, 'paymentSchedule', '');
  const billingFrequency = get(paymentSummary, 'billingFrequency', '');
  const withFinancing = get(paymentSummary, 'useVartanaFinancing');
  const billingMethod = isEmpty(paymentSummary.billingMethod)
    ? ''
    : upperCase(paymentSummary.billingMethod);

  if (billingFrequency) {
    if (withFinancing) {
      return (
        <>
          <SummaryItem title="Payment terms" value={get(paymentSummary, 'paymentDue', '--')} />
          <SummaryItem
            title="Contract Length"
            value={get(paymentSummary, 'contractLength', '--')}
          />
          <SummaryItem title="Start and end date" value={`${startDate}-${endDate}`} />
          <SummaryItem
            title="Estimated Payment"
            value={get(paymentSummary, 'payments', '--')}
          />
          {billingMethod && <SummaryItem title="Billing Method" value={billingMethod} />}
        </>
      );
    }

    return (
      <>
        <SummaryItem title="Payment terms" value={get(paymentSummary, 'paymentDue', '--')} />
        <SummaryItem
          title="Contract Length"
          value={get(paymentSummary, 'contractLength', '--')}
        />
        <SummaryItem title="Start and end date" value={`${startDate}-${endDate}`} />
        <SummaryItem
          title={`${capitalize(billingFrequency)} payments`}
          value={get(paymentSummary, 'payments', '--')}
        />
      </>
    );
  }

  if (paymentSchedule === FULL_PAYMENT) {
    if (withFinancing) {
      return (
        <>
          <SummaryItem
            title="Total contract value"
            value={get(paymentSummary, 'orderTotal', '--')}
          />
          <SummaryItem title="Payment terms" value={get(paymentSummary, 'paymentDue', '--')} />
          <SummaryItem title="Processing fee" value={get(paymentSummary, 'fee', '--')} />
          <SummaryItem
            title="Total payment"
            value={get(paymentSummary, 'totalPayment', '--')}
          />
          {billingMethod && <SummaryItem title="Billing Method" value={billingMethod} />}
        </>
      );
    }

    return (
      <>
        <SummaryItem
          title="Total contract value"
          value={get(paymentSummary, 'orderTotal', '--')}
        />
        <SummaryItem title="Payment terms" value={get(paymentSummary, 'paymentDue', '--')} />
      </>
    );
  }
  return null;
}

SummaryData.propTypes = {
  paymentSummary: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    paymentSchedule: PropTypes.string,
    contractLength: PropTypes.string,
    paymentDue: PropTypes.string,
    customerApplicationFee: PropTypes.string,
    payments: PropTypes.string,
    orderTotal: PropTypes.string,
    fee: PropTypes.string,
    totalPayment: PropTypes.string,
    billingMethod: PropTypes.string,
  }),
};

SummaryData.defaultProps = {
  paymentSummary: {},
};
