import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ExpandableCard } from '@vartana-repo/base-components/accordion';
import { DirectPaymentIcon, InvoiceIcon } from '../../../assets';
import { BillingMethod } from './BillingMethod';
import { invoiceTypes } from './constants';
import './InvoiceAccordion.scss';

const invoiceOptions = [
  {
    type: invoiceTypes.PAY_BY_ACH,
    title: 'Pay by ACH',
    icon: <DirectPaymentIcon className="w-8 h-8" />,
  },
  {
    type: invoiceTypes.PAY_BY_CHECK,
    title: 'Pay by check',
    icon: <InvoiceIcon />,
  },
];

export const InvoiceAccordion = ({ invoiceType, onSelect }) => {
  const { heading, option } = useMemo(() => {
    if (!invoiceType) {
      return {
        heading: invoiceOptions[0],
        option: invoiceOptions[1],
      };
    }
    return {
      heading: invoiceOptions.find((invoice) => invoice.type === invoiceType),
      option: invoiceOptions.find((invoice) => invoice.type !== invoiceType),
    };
  }, [invoiceType]);

  return (
    <ExpandableCard
      key={heading.title}
      variant="bank-selection"
      items={[
        {
          id: 'invoiceOptions',
          header: <BillingMethod title={heading.title} />,
          content: (
            <BillingMethod
              title={option.title}
              containerClassName="h-16 py-4 px-6"
              onClick={() => onSelect(option.type)}
            />
          ),
        },
      ]}
      wrapperCss="relative w-full h-24"
      panelProps={{
        className: 'bg-vartana-white-100',
      }}
      collapseProps={{
        isCollapsibleMode: false,
        className: 'invoice-options border-0 relative flex flex-col gap-3',
      }}
    />
  );
};

InvoiceAccordion.propTypes = {
  invoiceType: PropTypes.oneOf(['', ...Object.values(invoiceTypes)]).isRequired,
  onSelect: PropTypes.func.isRequired,
};
