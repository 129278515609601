import { useCallback, useState } from 'react';
import { BankCard } from '../../Cards';

/**
 * A hook that provides the ability to transform a banks array into
 * an object which consumable by our Accordion lib.
 * @returns Object {
 *  formattedBanks - state
 *  formatBanks - helper function
 * }
 */
export const useFormattedBanks = () => {
  const [formattedBanks, setFormattedBanks] = useState({
    header: null,
    content: null,
  });

  const formatBanks = useCallback(({ registeredBanks, onSelect }) => {
    try {
      if (registeredBanks?.length > 1) {
        const defaultBank = registeredBanks.find(({ isDefault }) => isDefault === true);
        const otherBanks = registeredBanks.filter(({ id }) => defaultBank.id !== id);

        const accordionPanel = {
          id: 'registeredBanks',
          header: <BankCard selectedAccount={{ ...defaultBank }} />,
          content: otherBanks.map((bank) => {
            const { accountName, accountType } = bank;
            return (
              <BankCard
                key={`${accountName}_${accountType}`}
                variant="option"
                selectedAccount={bank}
                onClick={() => onSelect(bank)}
              />
            );
          }),
        };
        setFormattedBanks(accordionPanel);
      }
    } catch (e) {
      console.error('[useBanksFormatted][formatBanks()] - ', e);
    }
  }, []);

  return { formattedBanks, formatBanks };
};
