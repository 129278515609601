import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { Button } from '@vartana-repo/base-components/buttons';
import { Modal } from '@vartana-repo/base-components/modal';
import { InputField, TextArea } from '@vartana-repo/base-components/form';
import { useNavigate } from 'react-router-dom';
import { LeftArrow, ForwardIcon } from '../../assets';
import { FORWARD_PG_APPLICATION } from '../../assets/graphql/personalGuarantor';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  message: '',
};

const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Email must be valid').required('Email is required'),
  phone: Yup.string().test('len', 'Invalid phone number', (val) => {
    if (val) {
      return !val.includes('_');
    }
    return false;
  }),
  message: Yup.string().optional(),
});

const Heading = () => (
  <div className="flex flex-col pb-4">
    <div className="flex items-center gap-2 modal-heading">
      <ForwardIcon className="w-8 h-8" />
      <span>Forward information request</span>
    </div>
  </div>
);

export const ForwardPGModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [forwardPGApplication, { loading }] = useMutation(FORWARD_PG_APPLICATION, {
    onError: (error) => console.error('[FORWARD_PG_APPLICATION]', error),
  });

  const onSubmit = async (values, { setErrors }) => {
    const { errors } = await forwardPGApplication({
      variables: { ...values },
    });
    if (errors) setErrors(errors);
    else {
      onClose();
      navigate('/customer/completed', {
        replace: true,
        state: {
          title: 'Application forwarded',
          subtitle: `Access to this application has been updated. Application link has been forwarded to ${values.firstName} ${values.lastName}.`,
        },
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={<Heading />}
      overlayClassName="md:pt-20 items-start"
      showCloseIcon
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched, isValid, dirty }) => {
          const isDisabled = !(isValid && dirty);
          return (
            <Form className="grid grid-cols-6 gap-4">
              <div className="col-span-6 md:col-span-3">
                <InputField
                  name="firstName"
                  type="text"
                  label="Contact first name"
                  errorMsg={errors.firstName}
                  touched={touched.firstName}
                />
              </div>
              <div className="col-span-6 md:col-span-3">
                <InputField
                  name="lastName"
                  type="text"
                  label="Last name"
                  errorMsg={errors.lastName}
                  touched={touched.lastName}
                />
              </div>
              <div className="col-span-6 md:col-span-3">
                <InputField
                  name="email"
                  type="email"
                  placeholder="example@gmail.com"
                  label="Email"
                  errorMsg={errors.email}
                  touched={touched.email}
                />
              </div>
              <div className="col-span-6 md:col-span-3">
                <InputField
                  name="phone"
                  type="text"
                  label="Phone"
                  mask="+1 (999) 999-9999"
                  placeholder="+1 (___) ___-____"
                  errorMsg={errors.phone}
                  touched={touched.phone}
                />
              </div>
              <div className="col-span-6">
                <Field
                  as="textarea"
                  name="message"
                  rows={4}
                  label="Message for recipient (optional)"
                  component={TextArea}
                  placeholder="Include a note for the contact..."
                />
              </div>
              <div className="flex items-center col-span-3 md:col-span-2">
                <Button variant="linkBlue" onClick={onClose}>
                  <LeftArrow /> Cancel
                </Button>
              </div>
              <div className="col-span-3 md:col-span-4 flex justify-end">
                <Button
                  type="submit"
                  isLoading={loading}
                  disabled={isDisabled}
                  className="w-48"
                >
                  Forward
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

ForwardPGModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
