import { get } from 'lodash';

/**
 * Asynchronously verifies the company's EIN (Employer Identification Number) status.
 *
 * @param {Function} verifyCompanyEIN - The mutation that performs the EIN verification
 * from backend.
 * @param {Function} setShowEinLoader - A function to control the visibility of the EIN loader.
 * @param {Function} setEinVerificationFailed - A function to set the EIN.
 * verification failure status.
 * @param {Function} setDisableEIN - A function to disable EIN input after successful verification.
 * @returns {void}
 */

export const verifyEinStatus = async (
  verifyCompanyEIN,
  setShowEinLoader,
  setEinVerificationFailed,
  setDisableEIN
) => {
  const timeout = 2 * 60 * 1000;
  const startTime = new Date().getTime();

  const handleVerificationSuccess = () => {
    setShowEinLoader(false);
    setEinVerificationFailed(false);
    setDisableEIN(true);
  };

  const handleVerificationFailure = () => {
    setShowEinLoader(false);
    setEinVerificationFailed(true);
    setDisableEIN(false);
  };

  const poll = async () => {
    try {
      const { data: einData, errors: einErrors } = await verifyCompanyEIN();

      if (einErrors) {
        handleVerificationFailure();
      } else {
        const verificationStatus = get(einData, 'verifyCompanyEin.verificationStatus', null);

        if (verificationStatus === true) {
          handleVerificationSuccess();
        } else if (verificationStatus === false) {
          handleVerificationFailure();
        } else {
          const currentTime = new Date().getTime();
          const elapsedTime = currentTime - startTime;
          let nextInterval;

          if (elapsedTime < 20000) {
            nextInterval = 20000;
          } else if (elapsedTime < 35000) {
            nextInterval = 15000;
          } else if (elapsedTime < 45000) {
            nextInterval = 10000;
          } else {
            nextInterval = 5000;
          }

          if (elapsedTime < timeout) {
            setTimeout(poll, nextInterval);
          } else {
            handleVerificationFailure();
          }
        }
      }
    } catch (error) {
      console.error('[verifyCompanyEIN] ', error);
      handleVerificationFailure();
    }
  };

  poll();
};
