import check from '../../../assets/images/check.svg';

export const paymentMethodInitialState = {
  bank: '',
  paymentMode: '',
  id: null,
  accountName: '',
  accountType: '',
  routingNumber: '',
  accountNumber: '',
  contactName: '',
  phone: '',
  email: '',
  street: '',
  suite: '',
  city: '',
  state: '',
  zip: '',
  accountId: '',
  accessToken: '',
  itemId: '',
  requestId: '',
  logo: check,
};

export const invoiceTypes = {
  PAY_BY_ACH: 'ach_push',
  PAY_BY_CHECK: 'check',
};
