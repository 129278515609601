import PropTypes from 'prop-types';

import { Button } from '@vartana-repo/base-components/buttons';

const styles = {
  subtitleLayout: 'flex items-start md:items-center gap-2',
  subtitleLayoutWithLogo:
    'flex flex-col lg:flex-row items-start lg:items-center gap-1 lg:gap-2',
};

export const PageHeader = ({ title, subTitle, message, icon, linkText, linkAction }) => {
  return (
    <div className="flex flex-col pb-4">
      {message ? (
        <span className="pb-4 personalized-message text-vartana-gray-140">{message}</span>
      ) : null}
      {title ? (
        <span className="pb-2 page-title-small lg:page-title text-vartana-black-100">
          {title}
        </span>
      ) : null}
      {subTitle ? (
        <div className={`${icon ? styles.subtitleLayoutWithLogo : styles.subtitleLayout}`}>
          <div className="flex gap-1 items-center body">
            {subTitle} {icon}
          </div>
          {linkText && (
            <Button variant="linkBlue" onClick={linkAction}>
              {linkText}
            </Button>
          )}
        </div>
      ) : null}
    </div>
  );
};

PageHeader.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  icon: PropTypes.node,
  linkText: PropTypes.string,
  linkAction: PropTypes.func,
};

PageHeader.defaultProps = {
  message: '',
  title: '',
  subTitle: '',
  icon: null,
  linkText: '',
  linkAction: undefined,
};
