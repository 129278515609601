import { gql } from '@apollo/client';

export const UPSERT_PERSONAL_GUARANTORS = gql`
  mutation upsertPersonalGuarantors($personalGuarantors: [InputPersonalGuarantor!]!) {
    upsertPersonalGuarantors(personalGuarantors: $personalGuarantors) {
      id
    }
  }
`;

export const UPDATE_BUSINESS_DETAILS = gql`
  mutation updateBusinessDetailsCreditAppraisal(
    $businessName: String!
    $dba: String!
    $street: String!
    $city: String!
    $state: String!
    $zip: String!
    $ein: String!
    $annualRevenue: Float!
    $dateStarted: ISO8601Date!
  ) {
    updateBusinessDetailsCreditAppraisal(
      businessName: $businessName
      dba: $dba
      street: $street
      city: $city
      state: $state
      zip: $zip
      ein: $ein
      annualRevenue: $annualRevenue
      dateStarted: $dateStarted
    ) {
      id
    }
  }
`;

export const UPSERT_AUTHORIZED_SIGNER = gql`
  mutation upsertAuthorizedSigner($authorizedSigner: InputAuthorizedSigner!) {
    upsertAuthorizedSigner(authorizedSigner: $authorizedSigner) {
      id
    }
  }
`;

export const CREATE_DIRECT_UPLOADS = gql`
  mutation createDirectUploads($directUploads: [DirectUploadInput!]!) {
    createDirectUploads(directUploads: $directUploads) {
      directUploads {
        url
        headers
        blobId
        signedBlobId
      }
    }
  }
`;

export const ATTACH_BLOBS_CREDIT_APPRAISAL = gql`
  mutation attachBlobsCreditAppraisal($attachBlobs: [AttachBlobInputCreditAppraisal!]!) {
    attachBlobsCreditAppraisal(attachBlobs: $attachBlobs) {
      id
    }
  }
`;

export const FORWARD_PG_APPLICATION = gql`
  mutation forwardApplication(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $message: String
  ) {
    forwardApplication(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      message: $message
    ) {
      id
    }
  }
`;

export const UPDATE_APPLICATION_FIRST_OPENED_AT = gql`
  mutation updateApplicationFirstOpenedAt($currentStep: String!) {
    updateApplicationFirstOpenedAt(currentStep: $currentStep) {
      creditAppraisal {
        id
        number
        applicationSteps
      }
    }
  }
`;

export const EXPIRE_SESSION = gql`
  mutation expireSession {
    expireSession {
      id
    }
  }
`;

export const UPDATE_CREDIT_APPRAISAL_APPLICATION_STEPS = gql`
  mutation updateCreditAppraisalApplicationSteps {
    updateCreditAppraisalApplicationSteps {
      creditAppraisal {
        id
      }
    }
  }
`;
