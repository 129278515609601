import PropTypes from 'prop-types';
import './Loader.scss';

export const Loader = ({ children, isLoading, className, containerClassName }) => {
  if (isLoading) {
    return (
      <div className={containerClassName}>
        <div className={`border-2 block lds-dual-ring m-auto ${className}`} />
      </div>
    );
  }
  return children;
};

Loader.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

Loader.defaultProps = {
  className: 'w-10 h-10 mt-6',
  containerClassName: '',
  isLoading: false,
  children: null,
};

export default Loader;
