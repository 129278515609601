import { useState, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, useField } from 'formik';

import { Label } from '../label/Label';
import { InputErrorField } from '../inputField/InputErrorField';
import '../form.scss';

export const SearchField = ({ label, name, options, placeholder }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [cursor, setCursor] = useState(0);

  const elementRef = useRef();
  const inputRef = useRef();
  const [field, meta, { setValue }] = useField(name);

  const isInputFocused = useCallback(() => document?.activeElement === inputRef?.current, []);

  const loadSuggestions = useCallback(
    (inputValue) => {
      const filteredOptions = options.filter((opt) =>
        opt.label.toLowerCase().includes(inputValue.toLowerCase())
      );

      if (filteredOptions.length > 0) {
        setSuggestions(filteredOptions);
        if (cursor > inputValue.length - 1) setCursor(inputValue.length - 1);
        if (isInputFocused()) setDropdownVisible(true);
      } else {
        setDropdownVisible(false);
      }
    },
    [cursor, isInputFocused, options]
  );

  const onSelectOption = useCallback(
    (option) => {
      setDropdownVisible(false);
      setValue(option.value);
    },
    [setValue]
  );

  const inputLabel = useMemo(() => {
    const option = options.find((opt) => opt.value === field.value);
    if (option) return option.label;
    return field.value;
  }, [field.value, options]);

  const inputProps = {
    ...field,
    className: `
      w-full field--base field-text
      rounded-lg shadow-sm ${meta.touched && meta.error ? 'field--error' : ''}
    `,
    type: 'text',
    name: field.name,
    id: field.name,
    value: inputLabel,
    placeholder,
    autoComplete: 'nope',
    ref: inputRef,
    onChange: ({ target }) => {
      const inputValue = target.value;
      if (inputValue.length === 0) setSuggestions([]);
      setValue(inputValue);
      loadSuggestions(inputValue);
    },
    onBlur: (e) => {
      field.onBlur(e);
      setDropdownVisible(false);
    },
    onKeyDown({ key }) {
      if (key === 'ArrowUp' && cursor > 0) {
        setCursor((prevCursor) => prevCursor - 1);
      } else if (key === 'ArrowDown' && cursor < suggestions.length - 1) {
        setCursor((prevCursor) => prevCursor + 1);
      } else if (key === 'Enter' && suggestions.length > 0) {
        onSelectOption(suggestions[cursor]);
      }
    },
  };

  return (
    <div className="relative">
      <div ref={elementRef} className="field-wrapper">
        {label ? (
          <Label
            htmlFor={name}
            className={meta.touched && meta.error ? 'text-vartana-red-160' : ''}
          >
            {label}
          </Label>
        ) : null}

        <input {...inputProps} />
      </div>
      {dropdownVisible && suggestions.length > 0 ? (
        <div
          className={`
            field-text mt-2 m-0  absolute bg-white
            border border-gray-100 rounded-md shadow-sm z-10
          `}
          style={{
            width: elementRef?.current?.clientWidth || 0,
          }}
        >
          <ul role="listbox" tabIndex="0">
            {suggestions.map((suggestion, index) => (
              <li
                key={suggestion.label}
                role="option"
                aria-selected
                className={`
                  px-3 cursor-pointer z-20
                  ${cursor === index ? 'bg-vartana-blue-90 text-white' : ''}
                `}
                onMouseDown={() => onSelectOption(suggestion)}
                onMouseEnter={() => setCursor(index)}
              >
                <div className="py-3">{suggestion.label}</div>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      <ErrorMessage name={name} component={InputErrorField} />
    </div>
  );
};

SearchField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

SearchField.defaultProps = {
  name: '',
  label: '',
  placeholder: '',
  options: [],
};
