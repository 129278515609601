import { forwardRef } from 'react';
import { ErrorMessage, useField } from 'formik';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { Label } from '../label/Label';
import { InputErrorField } from '../inputField/InputErrorField';
import '../form.scss';
import 'react-datepicker/dist/react-datepicker.css';
import './DateInput.scss';

const CustomInput = forwardRef(({ className, ...rest }, ref) => {
  return (
    <>
      <input ref={ref} type="text" className={`field-text ${className} `} {...rest} />
      <div role="button" tabIndex="0" onClick={rest.onClick}>
        <CalendarIcon className="absolute right-0 top-2/4 transform-gpu -translate-y-2/4 left-[92%] md:left-[90%]" />
      </div>
    </>
  );
});
CustomInput.propTypes = {
  className: PropTypes.string,
};
CustomInput.defaultProps = {
  className: '',
};

export const DateInput = ({
  name,
  label,
  placeholder,
  highlightDates,
  className,
  dateFormat,
  minDate,
  maxDate,
}) => {
  const [field, meta, { setValue, setTouched }] = useField(name);
  return (
    <div className={`field-wrapper ${className}`}>
      <Label
        htmlFor={name}
        className={`${meta.touched && meta.error ? 'text-vartana-red-160' : null}`}
      >
        {label}
      </Label>
      <DatePicker
        {...field}
        id={name}
        className={`
                  w-full
                  field--base
                  ${meta.touched && meta.error && 'field--error'}
                `}
        calendarClassName="rasta-stripes"
        selected={field.value}
        onChange={(date) => {
          setValue(date);
        }}
        placeholderText={placeholder}
        autoComplete="off"
        minDate={minDate}
        maxDate={maxDate}
        customInput={<CustomInput />}
        onChangeRaw={() => setTouched(true, true)}
        highlightDates={highlightDates}
        popperPlacement="bottom"
        dateFormat={dateFormat}
        showYearPicker={dateFormat === 'yyyy'}
      />
      <ErrorMessage name={name} component={InputErrorField} />
    </div>
  );
};

DateInput.propTypes = {
  name: PropTypes.string,
  highlightDates: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.arrayOf(PropTypes.date),
    })
  ),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  dateFormat: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

DateInput.defaultProps = {
  name: '',
  highlightDates: [],
  placeholder: '',
  label: '',
  className: '',
  dateFormat: 'MM/dd/yyyy',
  minDate: null,
  maxDate: null,
};

export default DateInput;
