import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { get } from 'lodash';
import { useMutation, useQuery } from '@apollo/client';

import { Button } from '@vartana-repo/base-components/buttons';
import { OptionCards } from '@vartana-repo/base-components/form';

import { mapStateToPath } from '../../routes';
import {
  RightArrow,
  SELECT_ORDER_PROPOSAL,
  MOVE_TO_NEXT_STEP,
  convertArrayToObject,
  GET_SESSION_FOR_USER_INFO,
} from '../../../assets';
import { PageHeader } from '../../PageHeader/PageHeader';
import { MessageVendorModal, MessageSentModal } from '../../../components/Modals';
import { PaymentOptionAccordions } from './PaymentOptionsAccordions';

export function PaymentOptions() {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [proposalOptions, setProposalOptions] = useState([]);
  const [accountOwnerDetails, setAccountOwnerDetails] = useState(null);
  const urlParams = useParams();
  const navigate = useNavigate();

  const { orderNumber } = urlParams;
  const [
    { orderDetail, paymentSummary, summaryLoading },
    updateOrderDetail,
    setPaymentSummary,
    setSummaryLoading,
  ] = useOutletContext();

  useQuery(GET_SESSION_FOR_USER_INFO, {
    onError: (error) => console.error('[GET_SESSION_FOR_USER_INFO]', error),
    onCompleted: (session) => {
      const owner = {
        firstName: get(session, 'session.order.user.firstName', ''),
        lastName: get(session, 'session.order.user.lastName', ''),
        email: get(session, 'session.order.user.email', ''),
      };
      setAccountOwnerDetails(owner);
    },
  });

  const [selectOrderProposal] = useMutation(SELECT_ORDER_PROPOSAL, {
    onError: (error) => console.error('[SELECT_ORDER_PROPOSAL]', error),
  });

  const [moveToNextStep, { loading: nextLoading }] = useMutation(MOVE_TO_NEXT_STEP, {
    onError: (error) => console.error('[MOVE_TO_NEXT_STEP]', error),
  });

  const { sellerName, orderProposals } = useMemo(
    () => ({
      sellerName: get(orderDetail, 'company.seller.businessName', ''),
      orderProposals: get(orderDetail, 'orderProposals', []),
    }),
    [orderDetail]
  );

  const updateSelectedProposal = (proposalNumber) => {
    setProposalOptions((prevOptions) => [
      ...prevOptions.map((option) => ({
        ...option,
        isSelected: option.number === proposalNumber,
      })),
    ]);
  };

  const onProposalSelect = useCallback(
    async (proposalNumber) => {
      updateSelectedProposal(proposalNumber);
      setSummaryLoading(true);
      const { data } = await selectOrderProposal({
        variables: { proposalNumber },
      });
      setSummaryLoading(false);

      if (data?.selectOrderProposal) {
        const newOrder = get(data, 'selectOrderProposal.order', {});
        const reviewDocuments = get(newOrder, 'reviewDocuments', []);
        const buyerSummary = convertArrayToObject(get(newOrder, 'buyerSummary', []));
        setPaymentSummary({
          useVartanaFinancing: get(newOrder, 'useVartanaFinancing'),
          reviewDocuments,
          ...buyerSummary,
        });
        updateOrderDetail((order) => ({
          ...order,
          currentCheckoutProgress: get(newOrder, 'currentCheckoutProgress', {}),
          currentCheckoutStates: get(newOrder, 'currentCheckoutStates', []),
        }));
      }
    },
    [selectOrderProposal, setSummaryLoading, updateOrderDetail, setPaymentSummary]
  );

  useEffect(() => {
    const proposals = orderProposals
      .map((proposal) => ({
        ...proposal,
        label: proposal.title,
      }))
      .sort((a, b) => a.sortOrder - b.sortOrder);
    setProposalOptions(proposals);
  }, [onProposalSelect, orderProposals]);

  const onNextBtnClick = useCallback(async () => {
    const { data } = await moveToNextStep();

    if (data) {
      const updatedOrder = get(data, 'moveToNextStep');
      if (updatedOrder) {
        updateOrderDetail(updatedOrder);
        navigate(mapStateToPath(orderNumber, updatedOrder?.state));
      }
    }
  }, [moveToNextStep, navigate, orderNumber, updateOrderDetail]);

  return (
    <>
      <PageHeader
        title="Select an option"
        subTitle="See a problem?"
        linkText={`Request ${sellerName} to edit order`}
        linkAction={() => setShowHelpModal(true)}
      />

      {/* Renders on larger screens */}
      <OptionCards
        containerClassNames="hidden lg:flex flex-col gap-2 py-4"
        options={proposalOptions}
        isDisabled={summaryLoading}
        onSelectOption={(proposal) => onProposalSelect(proposal.number)}
      />

      {/* Renders on smaller screens */}
      <PaymentOptionAccordions
        options={proposalOptions}
        paymentSummary={paymentSummary}
        summaryLoading={summaryLoading}
        wrapperClassName="block lg:hidden"
        onSelectOption={(proposal) => onProposalSelect(proposal.number)}
        onClearSelection={() => updateSelectedProposal(null)}
      />

      <div className="flex justify-end w-full pt-8">
        <Button
          isLoading={nextLoading}
          onClick={onNextBtnClick}
          disabled={proposalOptions.every((option) => !option.isSelected) || summaryLoading}
        >
          Next <RightArrow />
        </Button>
      </div>

      <MessageVendorModal
        isOpen={showHelpModal}
        onClose={() => setShowHelpModal(false)}
        onSuccess={() => {
          setShowSuccessModal(true);
          setShowHelpModal(false);
        }}
        sellerName={sellerName}
        buyerDetails={accountOwnerDetails}
      />

      <MessageSentModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        sellerName={sellerName}
      />
    </>
  );
}
