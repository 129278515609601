import PropTypes from 'prop-types';
import { Modal } from '@vartana-repo/base-components/modal';
import { Button } from '@vartana-repo/base-components/buttons';
import { ExclamationMarkIcon } from '../../assets';

export const RemovePersonalGuarantorModal = ({ open, handleClose, handleRemove }) => {
  return (
    <Modal
      isOpen={open}
      showCloseIcon
      onClose={handleClose}
      className="w-96"
      ariaHideApp={false}
    >
      <div className="flex gap-3">
        <ExclamationMarkIcon />
        <h3 className="page-title-small">Are you sure?</h3>
      </div>
      <p className="font-normal text-sm leading-5">
        Information will not be saved once you remove the personal guarantor.
      </p>
      <div className="flex justify-between mt-6">
        <Button variant="linkBlue" onClick={handleClose}>
          Cancel
        </Button>
        <Button type="button" onClick={handleRemove}>
          Remove
        </Button>
      </div>
    </Modal>
  );
};

RemovePersonalGuarantorModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleRemove: PropTypes.func,
};

RemovePersonalGuarantorModal.defaultProps = {
  open: false,
  handleClose: () => {},
  handleRemove: () => {},
};
