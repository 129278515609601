import PropTypes from 'prop-types';

export const BillingMethod = ({ icon, title, containerClassName, onClick }) => {
  return (
    <div
      role="button"
      tabIndex={0}
      className={`w-full flex gap-4 items-center ${containerClassName}`}
      onClick={() => onClick?.(title)}
    >
      <span className="flex items-center gap-2">
        {icon}

        <div className="flex flex-col gap-2">
          <span className="card-subtitle-bold">{title}</span>
        </div>
      </span>
    </div>
  );
};
BillingMethod.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  onClick: PropTypes.func,
};
BillingMethod.defaultProps = {
  containerClassName: '',
  onClick: null,
};
