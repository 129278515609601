import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import { ReactComponent as CrossIcon } from '../assets/cross-icon.svg';
import './Modal.scss';

const modalVariants = {
  medium: 'modal-medium',
  large: 'modal-large',
  loader: 'modal-loader',
  error: 'modal-error',
};

const modalOverlayVariants = {
  medium: 'modal-overlay-base',
  loader: 'modal-overlay-loader',
  error: 'modal-overlay-loader',
};

export function Modal({
  variant,
  isOpen,
  onClose,
  className,
  header,
  footer,
  headerClassName,
  footerClassName,
  wrapperClassName,
  overlayClassName,
  children,
  showCloseIcon,
}) {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      overlayClassName={`
        ${modalOverlayVariants[variant]} flex justify-center fixed inset-0 bg-vartana-gray-140/95
        ${overlayClassName}
      `}
      className={`
       h-screen md:h-fit md:max-h-95vh overflow-y-auto outline-0 bg-white rounded-lg
       ${modalVariants[variant]} ${className}
     `}
    >
      <div className="flex justify-end h-10 p-3">
        {showCloseIcon && <CrossIcon className="w-4 h-4 cursor-pointer" onClick={onClose} />}
      </div>
      <div className={`px-10 pb-10 flex flex-col gap-4 ${wrapperClassName}`}>
        {header && <div className={`modal-heading ${headerClassName}`}>{header}</div>}
        {children}
        {footer && <div className={`modal-footer ${footerClassName}`}>{footer}</div>}
      </div>
    </ReactModal>
  );
}

Modal.propTypes = {
  variant: PropTypes.oneOf(Object.keys(modalVariants)),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  footer: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  headerClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  overlayClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  className: PropTypes.string,
  showCloseIcon: PropTypes.bool,
};

Modal.defaultProps = {
  variant: 'medium',
  isOpen: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
  className: '',
  headerClassName: '',
  footerClassName: '',
  wrapperClassName: '',
  overlayClassName: '',
  header: null,
  footer: null,
  children: null,
  showCloseIcon: false,
};

export default Modal;
