/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';

import { CustomSelect, InputField } from '@vartana-repo/base-components/form';

const ManualBankForm = ({ formik, accountTypes }) => {
  const { errors, touched } = formik;

  return (
    <div className="flex flex-col gap-4 pt-8 md:grid md:grid-cols-6">
      <div className="col-span-6 md:col-span-3">
        <InputField
          name="bankName"
          type="text"
          label="Name of bank"
          errorMsg={errors.bankName}
          touched={touched.bankName}
        />
      </div>

      <div className="col-span-6 md:col-span-3">
        <InputField
          name="accountNickname"
          type="text"
          label="Account nickname"
          errorMsg={errors.accountNickname}
          touched={touched.accountNickname}
        />
      </div>

      <div className="col-span-6 md:col-span-3">
        <CustomSelect name="accountType" label="Account type" options={accountTypes} />
      </div>

      <div className="col-span-6 md:col-span-3">
        <InputField
          name="routingNumber"
          type="text"
          label="Routing number"
          errorMsg={errors.routingNumber}
          touched={touched.routingNumber}
        />
      </div>

      <div className="col-span-6 md:col-span-3">
        <InputField
          name="accountNumber"
          type="text"
          label="Account number"
          errorMsg={errors.accountNumber}
          touched={touched.accountNumber}
        />
      </div>

      <div className="col-span-6 md:col-span-3">
        <InputField
          name="confirmAccountNumber"
          type="text"
          label="Confirm account number"
          errorMsg={errors.confirmAccountNumber}
          touched={touched.confirmAccountNumber}
        />
      </div>
    </div>
  );
};

ManualBankForm.propTypes = {
  formik: PropTypes.shape({}).isRequired,
  accountTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

ManualBankForm.defaultProps = {
  accountTypes: [],
};

export default ManualBankForm;
