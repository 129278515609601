import { useCallback, useEffect, useRef, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { pick, get } from 'lodash';
import PropTypes from 'prop-types';

import { Button } from '@vartana-repo/base-components/buttons';
import { plaidConnectionStates } from './constants';
import {
  AttentionIcon,
  exchangePublicToken,
  getAccount,
  getLinkToken,
  mapKeysToCamelCase,
} from '../../../assets';

const ErrorAlertBox = () => (
  <div className="flex items-start gap-1 py-3 px-4 bg-vartana-red-20">
    <AttentionIcon className="w-4 h-4" />

    <span className="flex flex-col p-small text-vartana-red-160">
      Unable to connect bank account. Please retry or use a different account
    </span>
  </div>
);

export const BankConnectingOptions = ({ onConnectPlaid, onInstant, onManual }) => {
  const selectedBankingOption = useRef('manual');
  const [showPlaidError, setShowPlaidError] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    const createLinkToken = async () => {
      const { token: plaidToken = '' } = await getLinkToken();
      setToken(plaidToken);
    };

    createLinkToken();
  }, []);

  const accountCallback = useCallback(
    async (response) => {
      const accountResponse = await getAccount(response);
      if (!accountResponse) {
        setShowPlaidError(true);
        onConnectPlaid({ state: plaidConnectionStates.ERROR });
        selectedBankingOption.current = '';
        return;
      }
      const mergedResponse = pick(
        {
          id: '',
          ...response,
          ...accountResponse,
          logo: get(accountResponse, 'institution.logo', null),
        },
        [
          'id',
          'accountId',
          'accessToken',
          'itemId',
          'requestId',
          'accountName',
          'accountNumber',
          'routingNumber',
          'accountType',
          'bank',
          'logo',
        ]
      );
      if (accountResponse) {
        onConnectPlaid({
          state: plaidConnectionStates.CONNECTED,
          payload: mergedResponse,
        });
      }
    },
    [onConnectPlaid]
  );

  const onSuccess = useCallback(
    async (publicToken, metaData) => {
      onConnectPlaid({ state: plaidConnectionStates.LOADING });
      const tokenResponse = await exchangePublicToken(publicToken, metaData.account_id);
      const cleanedResponse = mapKeysToCamelCase({
        ...tokenResponse,
        ...metaData,
      });
      await accountCallback(cleanedResponse);
    },
    [accountCallback, onConnectPlaid]
  );

  const onExit = useCallback(async () => {
    onConnectPlaid({ state: plaidConnectionStates.CANCELLED });
    selectedBankingOption.current = '';
  }, [onConnectPlaid]);

  const { open, ready } = usePlaidLink({
    token,
    onSuccess,
    onExit,
  });

  return (
    <>
      {showPlaidError && <ErrorAlertBox />}
      <p className="text-vartana-black-100 body">Connect to bank:</p>
      <div className="flex flex-row justify-center gap-4">
        <Button
          className="w-1/2 shadow-lift-2"
          variant="selector"
          isSelected={selectedBankingOption.current === 'instantly'}
          onClick={() => {
            selectedBankingOption.current = 'instantly';
            setShowPlaidError(false);
            onInstant();
            open();
          }}
          disabled={!ready}
        >
          <span className="btn-text">Instantly</span>
        </Button>

        <Button
          className="w-1/2 shadow-lift-2"
          variant="selector"
          isSelected={selectedBankingOption.current === 'manual'}
          onClick={() => {
            setShowPlaidError(false);
            selectedBankingOption.current = 'manual';
            onManual();
          }}
        >
          <span className="btn-text">Manually</span>
        </Button>
      </div>
    </>
  );
};

BankConnectingOptions.propTypes = {
  onConnectPlaid: PropTypes.func.isRequired,
  onInstant: PropTypes.func.isRequired,
  onManual: PropTypes.func.isRequired,
};
