import { gql } from '@apollo/client';

export const PAYMENT_METHOD_FIELDS = gql`
  fragment PaymentMethodDetails on PaymentMethod {
    id
    accountName
    accountNumber
    accountType
    bank
    contactName
    invoiceType
    street
    suite
    city
    state
    zip
    country
    isDefault
    logo
    paymentMode
    routingNumber
  }
`;

export const BUYER_DETAIL_FIELDS = gql`
  ${PAYMENT_METHOD_FIELDS}
  fragment BuyerDetailFields on Company {
    name
    legalName
    number
    logoUrl
    faviconUrl
    paymentMethods {
      ...PaymentMethodDetails
    }
    seller {
      name
      businessName
      logoUrl
      faviconUrl
      testMode
    }
    einIsVerified
    entityInformationEin
  }
`;

export const ORDER_PROPOSAL_FIELDS = gql`
  fragment OrderProposalFields on OrderProposal {
    id
    number
    title
    sortOrder
    isSelected
  }
`;

export const ORDER_DETAIL_FIELDS = gql`
  ${BUYER_DETAIL_FIELDS}
  ${ORDER_PROPOSAL_FIELDS}
  fragment OrderDetailFields on Order {
    id
    number
    state
    term
    paymentTerm
    billingFrequency
    paymentType
    buyerSummary
    currentCheckoutProgress
    currentCheckoutStates
    enabledPaymentMethods
    formattedFee
    formattedFeePercentage
    formattedPayment
    formattedFirstPayment
    formattedSummaryTotal
    factor
    formattedFactor
    formattedPaymentTerm
    formattedAmount
    formattedCustomerApplicationFee
    formattedTerm
    defaultPaymentMethod {
      id
    }
    orderProposals {
      ...OrderProposalFields
    }
    reviewDocuments
    startDate
    endDate
    orderItems {
      id
      name
      formattedUnitPrice
      formattedAmount
      quantity
    }
    company {
      ...BuyerDetailFields
      kycPreferences
    }
    userDocuments {
      id
      type
      url
      filename
    }
    useVartanaFinancing
  }
`;

export const ADDRESS_FIELDS = gql`
  fragment AddressFields on Address {
    street
    city
    state
    zip
  }
`;
