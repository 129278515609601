import { useIntercom } from 'react-use-intercom';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ThankYouIcon } from '../../../assets';
import { EXPIRE_SESSION } from '../../../assets/graphql/personalGuarantor';

const headers = {
  title: 'Submission received',
  subtitle: 'We will get back to you shortly with updates on your payment options.',
};

export function ThankYouPG() {
  const location = useLocation();
  const { show: showIntercom } = useIntercom();
  const [pageHeaders, setPageHeaders] = useState(headers);

  const [expireSession] = useMutation(EXPIRE_SESSION, {
    onError: (error) => console.error('[EXPIRE_SESSION]', error),
  });

  useEffect(() => {
    if (location?.state) setPageHeaders(location?.state);
    window.history.pushState(location?.state, document.title);
    expireSession();
  }, [location, expireSession]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col justify-center items-center bg-white h-full lg:pt-4">
        <ThankYouIcon className="w-72 md:w-96 h-56 md:h-64" />
        <div className="flex flex-col gap-1 px-6 items-center pt-8 text-center">
          <h5 className="card-title-bold md:page-title">{pageHeaders.title}</h5>
          <span className="body-text">
            {pageHeaders.subtitle}
            <br />
          </span>
          <p className="body-text">
            If you have any questions, please contact the
            <span
              role="button"
              onClick={() => showIntercom()}
              tabIndex={0}
              className="ml-1 text-link-bold cursor-pointer text-sm"
            >
              Vartana help desk.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
