import { useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { Navigate, useLocation, useOutletContext, useParams } from 'react-router-dom';
import { get } from 'lodash';

import { PageHeader } from '../../PageHeader/PageHeader';
import { mapStateToPath, ORDER_STATES } from '../../routes';
import { ConfirmationIcon } from '../../../assets';

export function ThankYou() {
  const location = useLocation();
  const { orderNumber } = useParams();
  const { show: showIntercom } = useIntercom();
  const [{ orderDetail }] = useOutletContext();
  const [pageHeaders, setPageHeaders] = useState({});

  useEffect(() => {
    if (location?.state) setPageHeaders(location?.state);

    // Clear browser history to prevent user from going back
    window.history.pushState(location?.state, document.title);
  }, [location]);

  const orderState = get(orderDetail, 'state', '');
  if (orderState !== ORDER_STATES.completed) {
    return <Navigate to={mapStateToPath(orderNumber, orderState)} />;
  }

  return (
    <div>
      {pageHeaders?.title ? (
        <div className="pt-4">
          <PageHeader title={pageHeaders?.title} subTitle={pageHeaders?.subtitle} />
        </div>
      ) : (
        <div className="pt-4 md:pt-10 flex flex-col	items-center">
          <ConfirmationIcon className="w-48 h-48 md:h-fit md:w-fit" />
          <span className="pb-1 md:pb-4 text-lg md:text-[2rem] font-bold text-vartana-black-100 pt-6 md:pt-8">
            Thank you!
          </span>
          <span className="body">We received your order.</span>
          <span className="body">
            Contact our{' '}
            <span
              role="button"
              tabIndex={0}
              className="cursor-pointer	text-vartana-blue-120"
              onClick={showIntercom}
            >
              help desk
            </span>{' '}
            if you have any questions.
          </span>
        </div>
      )}
    </div>
  );
}
