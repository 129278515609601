import { useEffect, useMemo } from 'react';
import { get } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import { GET_ORDER_DETAIL, UPDATE_FIRST_OPENED_AT, getAPIURL } from '../assets';
import { AppLoadingModal } from '../components/Modals';
import { INITIAL_ORDER_STATE, mapStateToPath } from './routes';

async function setServerCookie(token) {
  return fetch(`${getAPIURL()}/set-cookie`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      token,
      app: 'checkout-app',
    }),
    credentials: 'include',
  });
}

function CheckoutRoot({ setMetaData }) {
  const [searchParams] = useSearchParams();
  const urlParams = useParams();
  const navigate = useNavigate();

  const token = searchParams.get('t');
  const { orderNumber } = urlParams;

  const [fetchOrderDetail, { data: orderDetail }] = useLazyQuery(GET_ORDER_DETAIL, {
    variables: { number: orderNumber },
    onError: (error) => console.error('[GET_ORDER_DETAIL]', error),
  });

  const [updateFirstOpenedAt] = useMutation(UPDATE_FIRST_OPENED_AT);

  useEffect(() => {
    if (token) {
      setServerCookie(token).then((response) => response.ok && fetchOrderDetail());
    } else {
      navigate('/unauthorize');
    }
  }, [fetchOrderDetail, navigate, token]);

  const { faviconUrl, title, orderState } = useMemo(
    () => ({
      faviconUrl: get(orderDetail, 'buyerOrder.company.seller.faviconUrl'),
      title: get(orderDetail, 'buyerOrder.company.seller.businessName', ''),
      orderState: get(orderDetail, 'buyerOrder.state'),
    }),
    [orderDetail]
  );

  useEffect(() => {
    if (orderState) {
      if (orderState === INITIAL_ORDER_STATE) {
        updateFirstOpenedAt({
          variables: { currentStep: orderState },
          onError: (error) => console.error('[UPDATE_FIRST_OPENED_AT]', error),
          onCompleted: (response) => {
            const newState = get(response, 'updateFirstOpenedAt.order.state', '');
            navigate(mapStateToPath(orderNumber, newState));
          },
        });
      } else {
        const nextRoute = mapStateToPath(orderNumber, orderState);
        navigate(nextRoute);
      }
    }
  }, [navigate, orderNumber, orderState, updateFirstOpenedAt]);

  useEffect(() => {
    if (faviconUrl && title) setMetaData({ faviconUrl, title });
  }, [faviconUrl, setMetaData, title]);

  return <AppLoadingModal isLoading />;
}

CheckoutRoot.propTypes = {
  setMetaData: PropTypes.func.isRequired,
};

export default CheckoutRoot;
