import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

export const initialPersonalGuarantorObj = {
  key: uuidv4(),
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  dob: '',
  ssn: '',
  title: '',
  percentageOfOwnership: '',
  street: '',
  city: '',
  state: '',
  zip: '',
  primary: true,
};

const today = new Date();

export const initialPGFormValues = {
  personalGuarantors: [initialPersonalGuarantorObj],
  pgDisclaimer: false,
};

export const personalGuarantorFormSchema = Yup.object().shape({
  personalGuarantors: Yup.array().of(
    Yup.object({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      phone: Yup.string().test('len', 'Phone number is required', (val) => {
        if (val) {
          return !val.includes('_');
        }
        return false;
      }),
      ssn: Yup.string()
        .required('SSN is required')
        .test('Filled', 'SSN is required', (value) => value && !value.includes('X')),
      dob: Yup.date()
        .typeError('Invalid date of birth')
        .max(
          new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()),
          'Personal guarantor must be 18 years of age or older.'
        )
        .required('DOB is required'),
      title: Yup.string().required('Job title is required'),
      percentageOfOwnership: Yup.string().required('Percentage of ownership is required'),
      street: Yup.string().required('Address is required'),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      zip: Yup.string().required('Zip code is required'),
      primary: Yup.boolean()
        .required('Please choose a default signer.')
        .test((_, context) => {
          if (
            context.from[1].value.personalGuarantors.length > 1 &&
            context.from[1].value.personalGuarantors.every(
              (personalGuarantor) => !personalGuarantor.primary
            )
          ) {
            return context.createError({
              message: 'Please choose a default signer.',
            });
          }
          return true;
        }),
    })
  ),
  pgDisclaimer: Yup.bool(),
});

export const percentageOfOwnershipOptions = [
  { label: 'None', value: 'none' },
  { label: 'Less than 10%', value: 'Less than 10%' },
  { label: '10 to 20%', value: '10 to 20%' },
  { label: '20% or more', value: '20% or more' },
];

export const initialBusinessDetailsFormValues = {
  businessName: '',
  dba: '',
  ein: '',
  dateStarted: '',
  annualRevenue: '',
  street: '',
  city: '',
  state: '',
  zip: '',
  financialDocs: [],
};

export const businessDetailsFormSchema = Yup.object().shape({
  businessName: Yup.string().required('Legal name is required'),
  dba: Yup.string(),
  ein: Yup.string().required('EIN number is required'),
  dateStarted: Yup.date()
    .typeError('Invalid founded year')
    .max(
      new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1),
      'Please enter a valid founding year.'
    )
    .required('Business start is required'),
  annualRevenue: Yup.string().required('Annual revenue is required'),
  street: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string().required('Zip code is required'),
});

export const financialDocsFormSchema = Yup.object().shape({
  financialDocs: Yup.array()
    .nullable()
    .required('Financial document(s) are required')
    .min(1, 'Financial document(s) are required'),
});

export const authSignFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().test('len', 'Phone number is required', (val) => {
    if (val) {
      return !val.includes('_');
    }
    return false;
  }),
  title: Yup.string().required('Job title is required'),
  street: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string().required('Zip code is required'),
  authSignerDisclaimer: Yup.bool()
    .required('This is a required field.')
    .oneOf([true], 'This is a required field.'),
});

export const initialAuthSignerFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  title: '',
  street: '',
  city: '',
  state: '',
  zip: '',
  authSignerDisclaimer: false,
};
