import { useEffect, useState, useCallback, useReducer, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { Button } from '@vartana-repo/base-components/buttons';
import { Loader } from '@vartana-repo/base-components/loader';
import {
  selectedPaymentMethodReducer,
  GET_PAYMENT_METHODS,
  LeftArrow,
  RightArrow,
  useMoveBack,
  paymentModes,
  useUpsertPaymentMethod,
  convertArrayToObject,
} from '../../../assets';

import { AchModal, ForwardApplicationModal } from '../../../components/Modals';
import BillingMethodAccordions from './BillingMethodAccordions';
import { invoiceTypes, paymentMethodInitialState } from './constants';
import { PageHeader } from '../../PageHeader/PageHeader';
import {
  getAchBillingMethods,
  isAchOrPlaidAccount,
  isInvoiceAccount,
  isValidAchAccount,
  validateAccount,
} from '../../../assets/utils/banks.utils';
import { AchCardContent } from './AchCard';
import { InvoiceAccordion } from './InvoiceAccordion';

const modalType = {
  ACH: 'ach',
  ACH_BILLING: 'ach-with-billing-only',
  INVOICE: 'invoice',
  RECIPIENT: 'recipient',
};

export const SelectBillingMethod = ({ onNext }) => {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [currentModal, setCurrentModal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [noOfPGUsers, setNoOfPGUsers] = useState(0);
  const [isPgRequired, setIsPgRequired] = useState(false);

  const [selectedInvoiceType, setSelectedInvoiceType] = useState('');

  const [selectedAccordion, setSelectedAccordion] = useState('-1');
  const [selectedAccount, setSelectedAccount] = useReducer(
    selectedPaymentMethodReducer,
    paymentMethodInitialState
  );

  const [{ orderDetail, sessionDetail }, updateOrderDetail, updatePaymentSummary] =
    useOutletContext();
  const isValidAccount = useMemo(() => validateAccount(selectedAccount), [selectedAccount]);

  const { enabledPaymentMethods, paymentType } = useMemo(
    () => ({
      paymentType: get(orderDetail, 'paymentType', ''),
      enabledPaymentMethods: get(orderDetail, 'enabledPaymentMethods', []),
    }),
    [orderDetail]
  );

  const {
    data: paymentMethodsData,
    loading: loadingDefaultPaymentMethod,
    refetch: fetchPaymentMethods,
  } = useQuery(GET_PAYMENT_METHODS, {
    onError: (error) => {
      throw new Error('GraphQL [GET_PAYMENT_METHODS]:', error);
    },
  });

  const [upsertPaymentMethod] = useUpsertPaymentMethod();
  const [backBtnLoading, onBackBtnClick] = useMoveBack(updateOrderDetail);

  const [defaultPaymentMethod, achBillingMethods, invoicePaymentMethods] = useMemo(() => {
    const paymentMethods = get(paymentMethodsData, 'session.user.company.paymentMethods', []);
    let selectedDefaultPaymentMethod = get(
      paymentMethodsData,
      'session.user.company.defaultPaymentMethod',
      null
    );
    const invoiceEnabled = get(paymentMethodsData, 'session.order.invoiceEnabled');

    // setting defaultPaymentMethod as null
    // in case pay by invoice is disabled and invoice is default payment method
    if (!invoiceEnabled && isInvoiceAccount(selectedDefaultPaymentMethod)) {
      selectedDefaultPaymentMethod = null;
    }

    return [
      selectedDefaultPaymentMethod,
      getAchBillingMethods(paymentMethods, selectedAccount),
      paymentMethods.filter((methods) => methods.paymentMode === paymentModes.invoice),
    ];
  }, [paymentMethodsData, selectedAccount]);

  // Set invoiceType for defaultPaymentMethod
  useEffect(() => {
    if (defaultPaymentMethod) {
      const invoiceType = get(defaultPaymentMethod, 'invoiceType', '');
      setSelectedInvoiceType(invoiceType);
    } else {
      setSelectedInvoiceType(invoiceTypes.PAY_BY_CHECK);
    }
  }, [defaultPaymentMethod]);

  useEffect(() => {
    const isSinglePaymentMethodAvailable = enabledPaymentMethods?.length === 1;

    if (!loadingDefaultPaymentMethod && isSinglePaymentMethodAvailable) {
      setSelectedAccordion(enabledPaymentMethods[0]);
    }
  }, [enabledPaymentMethods, loadingDefaultPaymentMethod]);

  // Pre-select accordion on page load based on defaultPaymentMethod
  useEffect(() => {
    if (!isEmpty(defaultPaymentMethod)) {
      setSelectedAccount({
        type: get(defaultPaymentMethod, 'paymentMode', 'init'),
        payload: defaultPaymentMethod,
      });

      if (isAchOrPlaidAccount(defaultPaymentMethod)) {
        setSelectedAccordion(paymentModes.ach);
      } else if (isInvoiceAccount(defaultPaymentMethod)) {
        setSelectedAccordion(paymentModes.invoice);
      }
    }
  }, [defaultPaymentMethod]);

  useEffect(() => {
    setNoOfPGUsers(get(sessionDetail, 'session.creditAppraisal.pgContacts', []).length);
    setIsPgRequired(get(sessionDetail, 'session.creditAppraisal.pgRequired', false));
  }, [sessionDetail]);

  const onSelectAchAccordion = () => {
    if (achBillingMethods.length > 0 && isInvoiceAccount(selectedAccount)) {
      const accountToShow = achBillingMethods[0];
      setSelectedAccount({ type: accountToShow.paymentMode, payload: accountToShow });
    }
  };

  const onAchModalSubmit = (newOrder) => {
    const reviewDocuments = get(newOrder, 'reviewDocuments', []);
    const buyerSummary = convertArrayToObject(get(newOrder, 'buyerSummary', []));

    setCurrentModal('');
    fetchPaymentMethods();
    updatePaymentSummary({
      useVartanaFinancing: get(newOrder, 'useVartanaFinancing'),
      reviewDocuments,
      ...buyerSummary,
    });
    // TODO - check how to update this
    // if (isEmpty(billingRecipients)) {
    //   setSelectedAccordion(isInvoiceAccount(selectedAccount) ? paymentModes.invoice : '-1');
    // }
  };

  const onNextBtnClick = useCallback(async () => {
    let account;
    if (selectedAccordion === paymentModes.ach) {
      account = achBillingMethods.find(({ isDefault }) => isDefault === true);
    } else {
      account = {
        paymentMode: paymentModes.invoice,
        invoiceType: selectedInvoiceType,
      };
      const selectedMethod = invoicePaymentMethods.find(
        (method) => method.invoiceType === selectedInvoiceType
      );
      if (selectedMethod) account.id = selectedMethod.id;
    }

    setIsLoading(true);
    upsertPaymentMethod({
      payload: account,
      onSuccess: async () => {
        onNext();
      },
      onError: () => {
        setIsLoading(false);
      },
    });
  }, [
    achBillingMethods,
    invoicePaymentMethods,
    upsertPaymentMethod,
    onNext,
    selectedAccordion,
    selectedInvoiceType,
  ]);

  const pageHeaders = useMemo(() => {
    const headers = { title: 'Set up billing' };
    if (!isPgRequired || (isPgRequired && noOfPGUsers > 1)) {
      headers.subtitle = "Don't have information?";
      headers.linkText = 'Forward order';
      headers.linkAction = () => setShowHelpModal(true);
    }
    return headers;
  }, [isPgRequired, noOfPGUsers]);

  const nextEnabled = useMemo(() => {
    if (selectedAccordion === paymentModes.invoice) return true;
    if (selectedAccordion === paymentModes.ach) return isValidAchAccount(selectedAccount);
    return false;
  }, [selectedAccordion, selectedAccount]);

  // check in case no accordion is selected
  useEffect(() => {
    if (selectedAccordion !== '-1') {
      updatePaymentSummary((prevSummary) => ({
        ...prevSummary,
        billingMethod: selectedAccordion,
      }));
    }
  }, [selectedAccordion, updatePaymentSummary]);

  // setting an account when defaultPaymentMethod is empty
  // when pay by invoice is disabled and invoice payment method is marked as default
  useEffect(() => {
    if (isEmpty(defaultPaymentMethod) && achBillingMethods.length > 0) {
      // setting the last ACH Method as selected account
      const account = achBillingMethods[achBillingMethods.length - 1];
      setSelectedAccount({ type: account.paymentMode, payload: account });
    }
  }, [defaultPaymentMethod, achBillingMethods]);

  return (
    <>
      <PageHeader
        title={pageHeaders.title}
        subTitle={pageHeaders.subtitle}
        message={pageHeaders.message}
        linkText={pageHeaders.linkText}
        linkAction={pageHeaders.linkAction}
      />

      <Loader
        isLoading={isEmpty(orderDetail) || loadingDefaultPaymentMethod}
        className="w-10 h-10 mt-36"
      >
        <BillingMethodAccordions
          billingMethods={enabledPaymentMethods}
          selectedAccordion={selectedAccordion}
          onChange={(id) => {
            setSelectedAccordion(id);
            setSelectedInvoiceType(
              id === paymentModes.invoice &&
                get(defaultPaymentMethod, 'invoiceType') !== paymentModes.invoice
                ? invoiceTypes.PAY_BY_CHECK
                : selectedInvoiceType
            );
            if (id === paymentModes.ach) onSelectAchAccordion();
          }}
          AchCardContent={
            <AchCardContent
              isLoading={loadingDefaultPaymentMethod}
              paymentMethods={achBillingMethods}
              selectedAccount={selectedAccount}
              isValidAccount={isValidAccount}
              setSelectedAccount={setSelectedAccount}
              handleUseAnotherBank={() => setCurrentModal(modalType.ACH_BILLING)}
            />
          }
          InvoiceCardContent={
            <InvoiceAccordion
              invoiceType={selectedInvoiceType}
              onSelect={(account) => {
                setSelectedInvoiceType(account);
              }}
            />
          }
        />

        <div className="flex items-center justify-between w-full pt-8">
          <Button variant="linkBlue" isLoading={backBtnLoading} onClick={onBackBtnClick}>
            <LeftArrow /> Back
          </Button>

          <Button isLoading={isLoading} disabled={!nextEnabled} onClick={onNextBtnClick}>
            Next <RightArrow />
          </Button>
        </div>

        <AchModal
          isOpen={currentModal === modalType.ACH || currentModal === modalType.ACH_BILLING}
          onSubmit={onAchModalSubmit}
          onClose={() => setCurrentModal('')}
          isLoading={loadingDefaultPaymentMethod}
          paymentType={paymentType}
          paymentMethods={achBillingMethods}
          updateOrderDetail={updateOrderDetail}
        />
      </Loader>
      {!isPgRequired || (isPgRequired && noOfPGUsers > 1) ? (
        <ForwardApplicationModal
          isOpen={showHelpModal}
          onClose={() => setShowHelpModal(false)}
          updateOrderDetail={updateOrderDetail}
          isPgRequired={isPgRequired}
        />
      ) : null}
    </>
  );
};

SelectBillingMethod.propTypes = {
  onNext: PropTypes.func.isRequired,
};
