import 'moment-timezone';
import moment from 'moment';
import { FileChecksum } from '@rails/activestorage/src/file_checksum';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { camelCase, isNull, isUndefined, mapKeys, multiply, get } from 'lodash';
import { createBrowserHistory as createHistory } from 'history';
import featureFlags from './feature_flags.json';
import { formattedPaymentFrequencies, PaymentTypes, SPAM_ALERTS } from '../constants';
import { navigationService } from '../graphql/navigation.service';

export const history = createHistory();
export const getAPIURL = () => process.env.NX_API_URL;
export const mapKeysToCamelCase = (obj) => mapKeys(obj, (v, k) => camelCase(k));
export const maskAccountNumberString = (str) => `x${str.substr(str.length - 4)}`;
export const maskAccountNumberStringFullLength = (str) => {
  const charsToHide = Number(str.length - 4);
  const mask = new Array(charsToHide + 1).join('*');
  return `${mask}${str.substr(str.length - 4)}`;
};

/**
 * Format keys of object to camelCase
 *
 * @param obj {obj} Input object
 * @return {obj} Object with camelCase keys (property names)
 */
export const formatObjectToCamelKeys = (obj) => {
  const formattedObj = {};
  if (obj) {
    Object.entries(obj).forEach(([key, value]) => {
      formattedObj[camelCase(key)] = value;
    });
  }
  return formattedObj;
};

/**
 * Takes an array of [key, value] pairs; each element of array is [key, value].
 * Converts this array into an object with keys and values
 *
 * @param {array} arr - Nested array of [key, value] pairs
 * @return {obj} Converted object
 */
export const convertArrayToObject = (arr) => {
  const formattedObj = {};
  arr.forEach(([key, value]) => {
    formattedObj[camelCase(key)] = value;
  });
  return formattedObj;
};

export const formatGraphqlErrors = (errors = {}) => {
  const formattedErrors = {};
  if (errors) {
    Object.entries(errors).forEach(([key, val]) => (formattedErrors[camelCase(key)] = val));
  }
  return formattedErrors;
};

export const handleDocumentPreview = (agreement, number) => {
  const title = get(agreement, 'title');
  const doc = get(agreement, 'document');
  const linkSource = `data:application/pdf;base64,${doc}`;
  const downloadLink = document.createElement('a');
  const fileName = `${number}-${title}.pdf`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const handleDocumentPreviewAll = (agreements, number) => {
  agreements.forEach((agreement) => {
    handleDocumentPreview(agreement, number);
  });
};

export const cleanPhoneNumber = (phoneNumber = '') => {
  return phoneNumber
    .replace(/\s+/g, '') // remove spaces
    .replace(/[\])}[{(]/g, '') // remove braces ()
    .replace(/-/g, ''); // remove hyphens
};

export const humanDateFormat = (dateString) =>
  dateString ? moment(dateString).format('MMM D, YYYY @ h:mmaz') : '';

export const getUSDateFormat = (dateString) =>
  dateString ? moment(dateString).format('MM/DD/YY') : '';

export const getStartEndDate = (startDate, endDate) => {
  const formattedStartDate = getUSDateFormat(startDate);
  return endDate ? `${formattedStartDate} - ${getUSDateFormat(endDate)}` : formattedStartDate;
};

export const formattedDateTime = (date) => {
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
};

export const getAmountDue = ({ paymentType, amount, frequency }) => {
  if (!paymentType || !amount || !frequency) return null;
  return paymentType === PaymentTypes.INSTALLMENTS
    ? `${amount}${formattedPaymentFrequencies[frequency]}`
    : amount;
};

/**
 * A helper that returns false only if value is one of [null, undefined, false]
 * Useful in cases where we want a conditional to run as long as the value is neither of the above
 * @param {any} val
 * @returns true/false
 */
export const isDefined = (val) => !(isUndefined(val) || isNull(val) || val === false);

/**
 * A helper that returns formatted payment due when value is defined
 * @param {Number} paymentTerm
 * @returns string output or null
 */
export const getPaymentDue = ({ paymentTerm }) => {
  if (!isDefined(paymentTerm)) return null;
  if (paymentTerm === 0) return 'Upon reciept';
  return `In ${paymentTerm} days`;
};

export const percentageFeeCalculator = (price) => {
  return `${multiply(price, 100)}% fee`;
};

export const calculateChecksum = (file) => {
  return new Promise((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (error) reject(error);
      else resolve(checksum);
    });
  });
};

export const calculateFilesMetadata = (files) => {
  return new Promise((resolve, reject) => {
    const pendingJobs = files.map((file) => calculateChecksum(file));
    Promise.all(pendingJobs)
      .then((checksums) => {
        return files.map((file, index) => ({
          filename: file.name,
          byteSize: file.size,
          checksum: checksums[index],
          contentType: file.type,
        }));
      })
      .then((filesMetadata) => {
        resolve(filesMetadata);
      })
      .catch((error) => reject(error));
  });
};

/**
 * For local env, return values from feature_flags.json
 * For all other environments, return values from LaunchDarkly
 */
export function useVartanaFeatureFlags() {
  const flags = useFlags();
  if (process.env.NX_NODE_ENV === 'development') {
    return featureFlags;
  }

  return flags;
}

export const reportError = (errorMessage, info = {}) => {
  if (SPAM_ALERTS.some((spam) => errorMessage.includes(spam))) {
    navigationService.rollbar.info(errorMessage, info);
  } else {
    navigationService.rollbar.error(errorMessage, info);
  }
};

export const reportInfo = (infoMessage) => {
  navigationService.rollbar.info(infoMessage);
};
