import { get, isEmpty } from 'lodash';
import { paymentModes } from '../constants';

/**
 * A helper that matches accounts based on accountNumber and accountName
 *
 * @param paymentMethod
 * @param selectedAccount
 * @return {boolean}
 */
export const accountMatches = (paymentMethod, selectedAccount) => {
  const { accountNumber, accountName } = selectedAccount;

  return (
    paymentMethod.accountNumber === accountNumber && paymentMethod.accountName === accountName
  );
};

export const isInvoiceAccount = (account) => account?.paymentMode === paymentModes.invoice;

export const isAchOrPlaidAccount = (account) => {
  if (isEmpty(account) || isEmpty(account.paymentMode)) return false;
  return !isInvoiceAccount(account);
};

export const isDuplicateAccount = (accounts, accountToFind) => {
  if (isEmpty(accounts)) return false;
  return accounts.find((account) => accountMatches(account, accountToFind));
};

export const isValidAchAccount = (account) => {
  if ([paymentModes.ach, paymentModes.plaid].includes(account?.paymentMode)) {
    return !(
      isEmpty(account?.accountName) ||
      isEmpty(account?.accountType) ||
      isEmpty(account?.accountNumber)
    );
  }
  return false;
};

export const validateAccount = (account) => {
  const paymentMode = get(account, 'paymentMode', '');
  if (!paymentMode) return false;
  if (paymentMode === paymentModes.invoice) return !isEmpty(account);

  return isValidAchAccount(account);
};

export const getAchBillingMethods = (billingMethods, selectedAccount) => {
  if (isEmpty(billingMethods) && !validateAccount(selectedAccount)) return [];

  let achBillingMethods;
  const isSelectedAccountNew = !isDuplicateAccount(billingMethods, selectedAccount);

  achBillingMethods = billingMethods.filter((billingMethod) =>
    isAchOrPlaidAccount(billingMethod)
  );

  if (isAchOrPlaidAccount(selectedAccount) && isSelectedAccountNew) {
    achBillingMethods = [...achBillingMethods, selectedAccount];
  }

  if (isAchOrPlaidAccount && !isSelectedAccountNew) {
    achBillingMethods = achBillingMethods.map((billingMethod) => {
      if (billingMethod.id === selectedAccount.id) {
        return { ...billingMethod, isDefault: true };
      }
      return { ...billingMethod, isDefault: false };
    });
  }

  return achBillingMethods;
};
