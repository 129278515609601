import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { Button } from '@vartana-repo/base-components/buttons';
import { Modal } from '@vartana-repo/base-components/modal';
import { CustomSelect, InputField, TextArea } from '@vartana-repo/base-components/form';
import { get, omit } from 'lodash';
import { FORWARD_APPLICATION, LeftArrow, ForwardIcon } from '../../assets';
import { routes, mapStateToPath, ORDER_STATES } from '../../app/routes';

const initialValues = {
  firstName: '',
  lastName: '',
  fullName: '',
  email: '',
  phone: '',
  message: '',
};

const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  fullName: Yup.string(),
  email: Yup.string().email('Email must be valid').required('Email is required'),
  phone: Yup.string().test('len', 'Invalid phone number', (val) => {
    if (val) {
      return val !== '+1 (999) 999-9999';
    }
    return false;
  }),
  message: Yup.string().optional(),
});

const Heading = () => (
  <div className="flex flex-col gap-1 pb-4">
    <div className="flex items-center gap-2 modal-heading">
      <ForwardIcon className="w-8 h-8" />
      <span>Forward Order</span>
    </div>
    <div className="font-normal text-sm leading-5 text-vartana-gray-160">
      Forward the link to your team member
    </div>
  </div>
);

export const ForwardApplicationModal = ({
  isOpen,
  onClose,
  updateOrderDetail,
  isPgRequired,
}) => {
  const [{ sessionDetail }] = useOutletContext();
  const [pgUsers, setPgUsers] = useState([]);
  const { orderNumber } = useParams();
  const navigate = useNavigate();

  const [forwardApplication, { loading }] = useMutation(FORWARD_APPLICATION, {
    onError: (error) => console.error('[FORWARD_APPLICATION]', error),
  });

  useEffect(() => {
    const users = get(sessionDetail, 'session.creditAppraisal.pgContacts', []);
    const filtered = users.filter((el) => {
      return el.id !== get(sessionDetail, 'session.user.id');
    });
    setPgUsers(filtered);
  }, [sessionDetail]);

  const onSubmit = useCallback(
    async (values, { setErrors }) => {
      const { data, errors } = await forwardApplication({
        variables: { ...omit(values, ['fullName']) },
      });

      if (errors) setErrors(errors);
      if (data?.sendApplication) {
        updateOrderDetail((order) => ({ ...order, state: routes.completed }));
        navigate(mapStateToPath(orderNumber, ORDER_STATES.completed), {
          state: {
            title: 'Order forwarded',
            subtitle: `Access to this order has been updated. Order link has been forwarded to ${values.fullName} for sign-off agreement.`,
          },
        });
      }
    },
    [forwardApplication, navigate, orderNumber, updateOrderDetail]
  );

  const handleUserChange = (selectedUserId, setFieldValue) => {
    const selectedUser = pgUsers.find((user) => user.id === selectedUserId);
    setFieldValue('fullName', selectedUser.fullName);
    setFieldValue('firstName', selectedUser.firstName);
    setFieldValue('lastName', selectedUser.lastName);
    setFieldValue('email', selectedUser.email);
    setFieldValue('phone', selectedUser.formattedPhone);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={<Heading />}
      overlayClassName="md:pt-20 items-start"
      showCloseIcon
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched, isValid, dirty, setFieldValue }) => {
          const isDisabled = !(isValid && dirty);
          return (
            <Form className="grid grid-cols-6 gap-4">
              <div className="col-span-6 md:col-span-3">
                {isPgRequired ? (
                  <CustomSelect
                    id="firstName"
                    name="firstName"
                    label="Signer first name"
                    onChange={(option) => handleUserChange(option.value, setFieldValue)}
                    options={pgUsers.map((user) => ({ label: user.fullName, value: user.id }))}
                  />
                ) : (
                  <InputField
                    name="firstName"
                    type="text"
                    label="Contact first name"
                    errorMsg={errors.firstName}
                    touched={touched.firstName}
                  />
                )}
              </div>
              <div className="col-span-6 md:col-span-3">
                <InputField
                  name="lastName"
                  type="text"
                  label="Contact last name"
                  errorMsg={errors.lastName}
                  touched={touched.lastName}
                  disabled={isPgRequired}
                />
              </div>
              <div className="col-span-6 md:col-span-3">
                <InputField
                  name="email"
                  type="email"
                  placeholder="example@gmail.com"
                  label="Email"
                  errorMsg={errors.email}
                  touched={touched.email}
                  disabled={isPgRequired}
                />
              </div>
              <div className="col-span-6 md:col-span-3">
                <InputField
                  name="phone"
                  type="text"
                  label="Phone"
                  mask="+1 (999) 999-9999"
                  placeholder="+1 (___) ___-____"
                  errorMsg={errors.phone}
                  touched={touched.phone}
                  disabled={isPgRequired}
                />
              </div>
              <div className="col-span-6">
                <Field
                  as="textarea"
                  name="message"
                  rows={4}
                  component={TextArea}
                  label={
                    isPgRequired
                      ? 'Message for signer (optional)'
                      : 'Message for recipient (optional)'
                  }
                  placeholder={
                    isPgRequired ? 'Include a note for the contract recipient...' : 'Hello...'
                  }
                />
              </div>
              <div className="flex items-center col-span-3 md:col-span-2">
                <Button variant="linkBlue" onClick={onClose}>
                  <LeftArrow /> Cancel
                </Button>
              </div>
              <div className="col-span-3 md:col-span-4 flex justify-end">
                <Button
                  type="submit"
                  isLoading={loading}
                  disabled={isDisabled}
                  className="w-48"
                >
                  Forward
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

ForwardApplicationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updateOrderDetail: PropTypes.func.isRequired,
  isPgRequired: PropTypes.bool,
};

ForwardApplicationModal.defaultProps = {
  isPgRequired: false,
};
