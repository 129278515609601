import { get } from 'lodash';

export const selectedPaymentMethodReducer = (state, { type, payload }) => {
  switch (type) {
    case 'ach':
      return {
        ...state,
        paymentMode: 'ach',
        id: get(payload, 'id', null),
        bank: get(payload, 'bank', ''),
        accountName: get(payload, 'accountName', ''),
        accountType: get(payload, 'accountType', ''),
        routingNumber: get(payload, 'routingNumber', ''),
        confirmRoutingNumber: get(payload, 'routingNumber', ''),
        accountNumber: get(payload, 'accountNumber', ''),
        confirmAccountNumber: get(payload, 'accountNumber', ''),
        logo: get(payload, 'logo', ''),
      };
    case 'invoice':
      return {
        ...state,
        paymentMode: 'invoice',
        id: get(payload, 'id', null),
        contactName: get(payload, 'contactName', ''),
        phone: get(payload, 'phone', ''),
        email: get(payload, 'email', ''),
        street: get(payload, 'street', ''),
        suite: get(payload, 'suite', ''),
        city: get(payload, 'city', ''),
        state: get(payload, 'state', ''),
        zip: get(payload, 'zip', ''),
        logo: get(payload, 'logo', ''),
      };
    case 'plaid':
      return {
        ...state,
        paymentMode: 'plaid',
        id: get(payload, 'id', null),
        accountId: get(payload, 'accountId', ''),
        accessToken: get(payload, 'accessToken', ''),
        itemId: get(payload, 'itemId', ''),
        requestId: get(payload, 'requestId', ''),
        accountName: get(payload, 'accountName', ''),
        accountNumber: get(payload, 'accountNumber', ''),
        routingNumber: get(payload, 'routingNumber', ''),
        accountType: get(payload, 'accountType', ''),
        bank: get(payload, 'bank', ''),
        logo: get(payload, 'logo', ''),
      };
    case 'init':
      return {
        ...state,
        ...payload,
      };
    default:
      return { ...state };
  }
};
