import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { SearchField } from '@vartana-repo/base-components/form';
import { fetchJobTitles } from './JobTitle.api';

export const JobTitles = ({ name, label, placeholder, className }) => {
  const [jobTitles, setJobTitles] = useState([]);

  useEffect(() => {
    const getJobTitles = async () => {
      const response = await fetchJobTitles();
      setJobTitles(response.titles);
    };
    getJobTitles();
  }, []);

  return (
    <div className={className}>
      <SearchField name={name} label={label} options={jobTitles} placeholder={placeholder} />
    </div>
  );
};

JobTitles.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

JobTitles.defaultProps = {
  name: '',
  label: '',
  className: '',
  placeholder: '',
};
