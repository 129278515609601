import { useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { get, isEmpty, omit } from 'lodash';

import { Modal } from '@vartana-repo/base-components/modal';
import { Loader } from '@vartana-repo/base-components/loader';
import { Checkbox } from '@vartana-repo/base-components/form';
import { Button } from '@vartana-repo/base-components/buttons';
import {
  BankIcon,
  LeftArrow,
  paymentModes,
  PaymentTypes,
  useUpsertPaymentMethod,
} from '../../../assets';

import { BankConnectingOptions } from './BankConnectingOptions';
import ManualBankForm from './ManualBankForm';
import { BankCard } from '../../Cards';
import { plaidConnectionStates } from './constants';
import { validateAccount } from '../../../assets/utils/banks.utils';

const accountTypes = [
  { value: 'checking', label: 'Checking' },
  { value: 'savings', label: 'Savings' },
];

const validationSchema = Yup.object({
  bankName: Yup.string().required('This field is required'),
  accountNickname: Yup.string().required('This field is required'),
  accountType: Yup.string()
    .required('This field is required')
    .oneOf(accountTypes.map((obj) => obj.value)),
  routingNumber: Yup.string()
    .required('This field is required')
    .test('length', 'Must be exactly 9 digits', (value) => value && value.length === 9),
  accountNumber: Yup.string()
    .min(6, 'Too Short!')
    .max(17, 'Too Long!')
    .required('This field is required'),
  confirmAccountNumber: Yup.string()
    .required('This field is required')
    .oneOf([Yup.ref('accountNumber'), null], "Account number doesn't match"),
  saveInfo: Yup.boolean(),
  installmentsAgree: Yup.boolean(),
});

const initialValues = {
  bankName: '',
  accountNickname: '',
  accountType: accountTypes[0].value,
  routingNumber: '',
  accountNumber: '',
  confirmAccountNumber: '',
  saveInfo: false,
  installmentsAgree: false,
};

const plaidAccountInitState = {
  accountName: '',
  accountNumber: '',
  accountType: '',
  paymentMode: paymentModes.plaid,
  logo: '',
};

const isInvalid = ({ isBNPL, isValid, dirty, formValues, selectedPaymentMode }) => {
  switch (true) {
    case selectedPaymentMode === paymentModes.ach && (!isValid || !dirty):
      return true;
    case isBNPL && formValues.installmentsAgree === false:
      return true;
    default:
      return false;
  }
};

const formatAchModalPayload = (formData) => {
  return omit(
    {
      ...formData,
      bank: formData.bankName,
      accountName: formData.accountName || formData.accountNickname,
      accountType: formData.accountType?.toLowerCase() || '',
      routingNumber: formData.routingNumber,
      paymentMode: formData.paymentMode,
    },
    [
      'bankName',
      'accountNickname',
      'confirmAccountNumber',
      'saveInfo',
      'installmentsAgree',
      'logo',
    ]
  );
};

export const AchModal = ({
  isOpen,
  onSubmit,
  onClose,
  isLoading,
  paymentType,
  updateOrderDetail,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(paymentModes.ach);
  const [plaidState, setPlaidState] = useState(false);
  const [newPlaidAccount, setNewPlaidAccount] = useState(plaidAccountInitState);
  const [upsertPaymentMethod] = useUpsertPaymentMethod();

  const showManualBankForm = selectedPaymentMode === paymentModes.ach;
  const isBNPL = useMemo(() => paymentType === PaymentTypes.INSTALLMENTS, [paymentType]);
  const showAgreementCheckbox =
    isBNPL && (selectedPaymentMode === paymentModes.ach || validateAccount(newPlaidAccount));

  const handleClose = () => {
    /**
     * Once mounted, our modal components do not actually unmount, instead they are just not
     * visible on the UI, so we are performing the state reset, on unmount, here instead of doing it
     * inside a useEffect return statement
     */
    const handleUnMount = () => {
      setBtnLoading(false);
      setSelectedPaymentMode(paymentModes.ach);
      setPlaidState(false);
      setNewPlaidAccount(plaidAccountInitState);
    };
    handleUnMount();
    onClose();
  };

  const handlePlaidStateUpdate = ({ state, payload }) => {
    setPlaidState(state);
    if (!isEmpty(payload)) {
      setNewPlaidAccount({ ...payload, paymentMode: paymentModes.plaid });
    }
  };

  const handleSubmit = useCallback(
    async (formData, { setErrors }) => {
      const payload = formatAchModalPayload(formData);
      setBtnLoading(true);
      upsertPaymentMethod({
        payload,
        verificationRequired: true,
        onError: (errors) => {
          setErrors(errors);
        },
        onSuccess: ({ data }) => {
          const newOrder = get(data, 'upsertPaymentMethod.order', {});
          updateOrderDetail(newOrder);
          onSubmit(newOrder);
        },
      }).then(() => setBtnLoading(false));
    },
    [upsertPaymentMethod, onSubmit, updateOrderDetail]
  );

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Loader isLoading={isLoading}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={null}
        >
          {(formik) => {
            const { dirty, isValid, values, setFieldValue, resetForm } = formik;
            const disabled = isInvalid({
              isBNPL,
              isValid,
              dirty,
              formValues: values,
              selectedPaymentMode,
            });

            return (
              <Form>
                <div className="flex flex-col gap-2">
                  <div className="flex items-center pb-2 lg:pb-4 gap-2 lg:gap-4">
                    <BankIcon className="w-8 h-8" />
                    <span className="page-title-small text-vartana-black-100">
                      Billing for ACH
                    </span>
                  </div>

                  <div className="flex flex-col gap-3">
                    <BankConnectingOptions
                      setPlaidInProgress={plaidState === plaidConnectionStates.LOADING}
                      onConnectPlaid={(params) => {
                        setFieldValue('installmentsAgree', false);
                        handlePlaidStateUpdate(params);
                      }}
                      onInstant={() => {
                        resetForm();
                        setPlaidState(plaidConnectionStates.INIT);
                        setFieldValue('installmentsAgree', false);
                        setSelectedPaymentMode(paymentModes.plaid);
                      }}
                      onManual={() => {
                        resetForm();
                        setPlaidState(plaidConnectionStates.INIT);
                        setFieldValue('installmentsAgree', false);
                        setSelectedPaymentMode(paymentModes.ach);
                      }}
                    />
                  </div>

                  {showManualBankForm ? (
                    <ManualBankForm accountTypes={accountTypes} formik={formik} />
                  ) : (
                    <div className="pt-4">
                      <Loader isLoading={plaidState === plaidConnectionStates.LOADING}>
                        <BankCard selectedAccount={newPlaidAccount} variant="primary" />
                      </Loader>
                    </div>
                  )}
                </div>
                {/* If BNPL, checkbox for installments agreement */}
                {showAgreementCheckbox && (
                  <Checkbox
                    name="installmentsAgree"
                    label={`
                      By checking this box, I agree to use this billing method for scheduled payment(s),
                      which will be automatically withdrawn on the invoice due date.
                    `}
                    longText
                    labelClassName="pt-8"
                  />
                )}

                <div className="flex justify-between gap-3 w-full pt-8">
                  <Button
                    variant="linkBlue"
                    onClick={() => {
                      setSelectedPaymentMode('');
                      handleClose();
                    }}
                  >
                    <LeftArrow /> Cancel
                  </Button>

                  <Button
                    type="submit"
                    disabled={disabled || btnLoading}
                    isLoading={btnLoading}
                    onClick={() => {
                      if (selectedPaymentMode === paymentModes.plaid) {
                        handleSubmit(newPlaidAccount, formik);
                      } else if (selectedPaymentMode === paymentModes.ach) {
                        handleSubmit({ ...values, paymentMode: paymentModes.ach }, formik);
                      }
                    }}
                    className="text-xs md:text-base"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Loader>
    </Modal>
  );
};

AchModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  paymentType: PropTypes.oneOf(['', ...Object.values(PaymentTypes)]).isRequired,
  updateOrderDetail: PropTypes.func.isRequired,
};

export default AchModal;
