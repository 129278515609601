import PropTypes from 'prop-types';

import { Button } from '@vartana-repo/base-components/buttons';
import { UserIcon, VerifiedIcon } from '../../../assets';
import {
  OptionCheckedIcon,
  OptionUncheckedIcon,
} from '../../../../../../libs/base-components/assets';

const personInfoCardVariants = {
  defaultTheme: 'defaultTheme',
  optionsTheme: 'optionsTheme',
};

export const PersonInfoCard = ({
  variant,
  person,
  verified,
  selected,
  onEdit,
  onRemove,
  onSelect,
  suppressOnRemove,
}) => {
  return (
    <div
      className={`
        flex justify-between items-center gap-3 md:gap-4 w-full py-4 xl:py-6 px-4 md:px-6
        border border-vartana-gray-40 rounded-md h-24
      `}
    >
      <div className="flex items-center gap-3">
        {variant === personInfoCardVariants.optionsTheme && (
          <span role="button" tabIndex={0} onClick={onSelect}>
            {selected ? <OptionCheckedIcon /> : <OptionUncheckedIcon />}
          </span>
        )}
        {variant === personInfoCardVariants.defaultTheme && <UserIcon className="w-10 h-10" />}
        <div className="flex flex-col gap-1 max-w-48 sm:max-w-content">
          <div className="flex items-center gap-1">
            <h2 className="text-vartana-gray-160 card-subtitle-bold">{person.fullName}</h2>
            {verified && <VerifiedIcon />}
          </div>
          <div
            className={`flex flex-col xl:flex-row text-vartana-gray-140 gap-1 xl:gap-4 p-small-bold
          `}
          >
            {person.paymentMode === 'invoice' ? (
              <span className="line-clamp-2">{person.fullAddress}</span>
            ) : (
              <>
                <span>{person.email}</span>
                <span className="truncate">{person.formattedPhone}</span>
                <span>{person.formattedTitle}</span>
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className={`
          text-link-bold
          flex flex-col md:flex-row items-start md:items-center md:justify-end
          gap-4 md:gap-2 underline md:divide-x md:divide-vartana-gray-40
        `}
      >
        {onEdit && (
          <Button variant="link" className="cursor-pointer" onClick={onEdit}>
            Edit
          </Button>
        )}
        {onRemove && !suppressOnRemove && (
          <Button variant="link" className="md:pl-2 cursor-pointer" onClick={onRemove}>
            Remove
          </Button>
        )}
      </div>
    </div>
  );
};

PersonInfoCard.propTypes = {
  person: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    formattedPhone: PropTypes.string,
    formattedTitle: PropTypes.string,
    paymentMode: PropTypes.string,
    fullAddress: PropTypes.string,
  }).isRequired,
  suppressOnRemove: PropTypes.bool,
  variant: PropTypes.oneOf(Object.keys(personInfoCardVariants)),
  selected: PropTypes.bool,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
  verified: PropTypes.bool,
};

PersonInfoCard.defaultProps = {
  suppressOnRemove: false,
  variant: 'defaultTheme',
  selected: false,
  onEdit: null,
  onRemove: null,
  onSelect: null,
  verified: false,
};
