import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { Modal } from '@vartana-repo/base-components/modal';
import { InputField } from '@vartana-repo/base-components/form';
import { Button } from '@vartana-repo/base-components/buttons';
import { LeftArrow, RightArrow, PartialClockIcon as ModalIcon } from '../../assets';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().test('len', 'Invalid phone number', (val) => {
    if (val) {
      return !val.includes('_');
    }
    return false;
  }),
});

const Heading = ({ isEditMode }) => {
  return (
    <div className="flex flex-col pb-4">
      <div className="flex items-center gap-2 modal-heading">
        <ModalIcon className="w-8 h-8" />
        <span className="page-title-small">{isEditMode ? 'Edit' : 'Add'} contact</span>
      </div>
    </div>
  );
};
Heading.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
};

export const AddEditRecipientModal = ({
  isOpen,
  isEditMode,
  recipientDetail,
  isSubmitting,
  onSubmit,
  onClose,
  onBack,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} header={<Heading isEditMode={isEditMode} />}>
      <Formik
        initialValues={recipientDetail}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ errors, touched, isValid, dirty }) => {
          const disabled = !isEmpty(errors) || !(isValid && dirty);
          return (
            <Form className="grid grid-cols-6 gap-4">
              <div className="col-span-6 md:col-span-3">
                <InputField
                  name="firstName"
                  label="Contact first name"
                  errorMsg={errors.firstName}
                  touched={touched.firstName}
                />
              </div>
              <div className="col-span-6 md:col-span-3">
                <InputField
                  name="lastName"
                  label="Contact last name"
                  errorMsg={errors.lastName}
                  touched={touched.lastName}
                />
              </div>
              <div className="col-span-6 md:col-span-3">
                <InputField
                  name="email"
                  label="Email"
                  errorMsg={errors.email}
                  touched={touched.email}
                />
              </div>
              <div className="col-span-6 md:col-span-3">
                <InputField
                  name="phone"
                  label="Phone"
                  mask="+1 (999) 999-9999"
                  placeholder="+1 (___) ___-____"
                  errorMsg={errors.phone}
                  touched={touched.phone}
                />
              </div>

              <div className="col-span-3 flex items-center mt-8">
                <Button variant="linkBlue" onClick={onBack}>
                  <LeftArrow /> Cancel
                </Button>
              </div>
              <div className="col-span-3 self-end flex items-center justify-end mt-8">
                <Button
                  type="submit"
                  disabled={disabled || isSubmitting}
                  isLoading={isSubmitting}
                >
                  Submit <RightArrow />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
AddEditRecipientModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool,
  recipientDetail: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

AddEditRecipientModal.defaultProps = {
  isEditMode: false,
  recipientDetail: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
};
