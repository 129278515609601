import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { UPSERT_PAYMENT_METHOD } from '../graphql';

/**
 *
 * @return {function} upsertPaymentMethod - GraphQL mutation for `UPSERT_PAYMENT_METHOD`
 */
export const useUpsertPaymentMethod = () => {
  const [upsertPaymentMethod] = useMutation(UPSERT_PAYMENT_METHOD, {
    onError: (error) => console.error(error),
  });

  const handleNewPaymentMethod = useCallback(
    async (payload) => {
      return upsertPaymentMethod({ variables: { ...payload, validateOnly: false } });
    },
    [upsertPaymentMethod]
  );

  const verifyNewPaymentMethod = useCallback(
    async (payload) => {
      return upsertPaymentMethod({ variables: { ...payload, validateOnly: true } });
    },
    [upsertPaymentMethod]
  );

  const addOrUpdatePaymentMethod = useCallback(
    async ({ payload, verificationRequired = false, onSuccess, onError }) => {
      let isVerified = !verificationRequired;
      if (verificationRequired) {
        const response = await verifyNewPaymentMethod(payload);
        if (response.errors) {
          onError?.(response.errors);
          isVerified = false;
        } else isVerified = true;
      }
      if (isVerified) {
        const response = await handleNewPaymentMethod(payload);
        if (response.errors) {
          onError?.(response.errors);
        } else onSuccess?.(response);
      }
    },
    [handleNewPaymentMethod, verifyNewPaymentMethod]
  );

  return [addOrUpdatePaymentMethod];
};
