// FYI - https://github.com/JedWatson/react-select/issues/4094

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { useField } from 'formik';
import { isEmpty } from 'lodash';

import { Label } from '../label/Label';
import '../form.scss';

const CustomControl = ({ cx, getStyles, innerRef, children, ...props }) => {
  return (
    <components.Control {...props} innerRef={innerRef} getStyles={getStyles} cx={cx}>
      {children}
    </components.Control>
  );
};

const CustomInput = ({ cx, getStyles, innerRef, children, ...props }) => {
  const { options, selectOption } = props;

  /*
    When user autocompletes the fields, the input value is set but the option is never
    selected, we have to explicitly set the selectedOption.
  */
  useEffect(() => {
    if (!isEmpty(options)) {
      const option = options.find((opt) => opt.label === props.value);
      if (!isEmpty(option)) selectOption?.(option);
    }
  }, [options, selectOption, props.value]);

  return (
    <components.Input {...props} innerRef={innerRef} getStyles={getStyles} cx={cx}>
      {children}
    </components.Input>
  );
};

const disabledStyles = () => ({
  control: (styles) => ({
    ...styles,
    border: 0,
    boxShadow: 'none',
    borderRadius: '8px',
    fontSize: '14px',
    padding: '0.25rem',
    lineHeight: '1.25rem',
    background: '#EEEEEE',
  }),
  // For controlling css of options wrapper
  menu: (styles) => ({
    ...styles,
    marginTop: '0.25rem',
  }),
  // For controlling css of options list
  menuList: (styles) => ({
    ...styles,
    paddingTop: 0,
  }),
  option: (styles) => ({
    ...styles,
    fontSize: '14px',
    paddingLeft: '12px',
  }),
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: '14px',
    color: '#CACCCF',
  }),
  singleValue: (styles) => ({ ...styles, color: '#121212' }), // vartana-red-160, vartana-black-100
});

const customStyles = (hasError) => ({
  // For controlling css of select input field
  control: (styles) => ({
    ...styles,
    border: 0,
    boxShadow: 'none',
    borderRadius: '8px',
    fontSize: '14px',
    padding: '0.25rem',
    lineHeight: '1.25rem',
  }),
  // For controlling css of options wrapper
  menu: (styles) => ({
    ...styles,
    marginTop: '0.25rem',
  }),
  // For controlling css of options list
  menuList: (styles) => ({
    ...styles,
    paddingTop: 0,
  }),
  option: (styles) => ({
    ...styles,
    fontSize: '14px',
    paddingLeft: '12px',
  }),
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: '14px',
    color: '#CACCCF',
  }),
  singleValue: (styles) => ({ ...styles, color: hasError ? '#C23934' : '#121212' }), // vartana-red-160, vartana-black-100
});

export function CustomSelect({ name, label, options, hasError, onChange, disabled }) {
  const [field, meta, { setValue }] = useField(name);

  const handleOnChange = (option) => {
    setValue(option.value);
    onChange(option);
  };
  return (
    <>
      <div className="form-label-focus-within field-wrapper">
        {label && (
          <Label
            htmlFor={name}
            className={`${
              meta.touched && (meta.error || hasError) ? 'text-vartana-red-160' : ''
            }`}
          >
            {label}
          </Label>
        )}
        <div
          className={`border rounded-lg ${
            meta.touched && (meta.error || hasError)
              ? 'border-vartana-red-160'
              : 'border-vartana-gray-100'
          } ${disabled ? 'border-vartana-gray-30' : ''}`}
        >
          <Select
            classNamePrefix="react-custom-select"
            // className={disabled ? 'bg-vartana-gray-30' : ''}
            isDisabled={disabled}
            components={{
              Control: CustomControl,
              Input: CustomInput,
              IndicatorSeparator: () => null,
            }}
            styles={disabled ? disabledStyles() : customStyles(hasError)}
            menuPosition="fixed"
            value={options ? options.find((option) => option.value === field.value) : ''}
            name={field.name}
            inputId={field.name}
            options={options}
            hasError={meta.touched && (meta.error || hasError)}
            onBlur={(e) => field.onBlur(e)}
            onChange={(option) => handleOnChange(option)}
          />
        </div>
      </div>
      {meta.touched && meta.error && (
        <div className="field--error field-helper-text">{meta.error}</div>
      )}
    </>
  );
}

CustomSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    })
  ),
  onChange: PropTypes.func,
};

CustomSelect.defaultProps = {
  label: '',
  options: [
    {
      label: 'label',
      value: 'value',
    },
  ],
  onChange: () => {},
};

export default CustomSelect;
