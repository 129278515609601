import { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Persona from 'persona';
import { capitalize, get, map } from 'lodash';

import { Button } from '@vartana-repo/base-components/buttons';
import { Loader } from '@vartana-repo/base-components/loader';

import { initiateVerificationInquiry, getVerificationSummary } from '../../../assets';

import { PersonInfoCard } from '../../../components/Cards';

function PersonaKYC({
  isLoading = false,
  user,
  isTestMode,
  kycPreferences,
  onSuccess,
  setPersonaVerificationFailed,
  setOfacVerificationFailed,
  disablePersonaFields,
  setDisablePersonaFields,
}) {
  const [verificationSummaryLoading, setVerificationSummaryLoading] = useState(false);
  const [personaCompleted, setPersonaCompleted] = useState(false);
  const [personaInquiryId, setPersonaInquiryId] = useState(null);
  const [clientLoading, setClientLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const personaEnvironment = useMemo(() => {
    return isTestMode
      ? process.env.NX_PERSONA_SANDBOX_ENVIRONMENT
      : process.env.NX_PERSONA_ENVIRONMENT;
  }, [isTestMode]);

  const getLatestVerificationSummary = useCallback(async () => {
    if (personaInquiryId) {
      setVerificationSummaryLoading(true);
      const { status } = await getVerificationSummary(personaInquiryId);

      if (status === 'approved' || get(user, 'isPersonaVerificationBypassed', false)) {
        onSuccess();
        setVerified(true);
        setPersonaVerificationFailed(false);
        setOfacVerificationFailed(false);
      } else if (status === 'declined') {
        setPersonaVerificationFailed(false);
        setOfacVerificationFailed(true);
        setDisablePersonaFields(true);
        setVerified(false);
      } else {
        setVerified(false);
      }

      setVerificationSummaryLoading(false);
      setPersonaCompleted(false);
    }
  }, [
    personaInquiryId,
    user,
    onSuccess,
    setOfacVerificationFailed,
    setPersonaVerificationFailed,
    setDisablePersonaFields,
  ]);

  useEffect(() => {
    setVerified(get(user, 'verified', false));
    setUserInfo({
      fullName: user?.fullName,
      email: user?.email,
      formattedPhone: user?.formattedPhone,
      formattedTitle: map(user?.title?.split('_'), capitalize).join(' '),
    });
  }, [user]);

  useEffect(() => {
    if (personaCompleted) getLatestVerificationSummary();
  }, [getLatestVerificationSummary, personaCompleted]);

  useEffect(() => {
    const inquiryStatus = get(user, 'personaInquiryStatus', '');
    setOfacVerificationFailed(inquiryStatus === 'declined');
    setDisablePersonaFields(inquiryStatus === 'declined');
  }, [user, setOfacVerificationFailed, setDisablePersonaFields]);

  const onButtonClick = useCallback(
    (inquiryTemplateId) => {
      if (user && inquiryTemplateId?.length) {
        setClientLoading(true);
        const { id, fullName, email, formattedPhone } = user;
        const personaConfig = {
          templateId: inquiryTemplateId,
          environment: personaEnvironment,
          fields: {
            nameFirst: user?.firstName,
            nameLast: user?.lastName,
          },
          onLoad: (error) => {
            if (error)
              throw new Error(
                `Persona: Failed with code: ${error.code} and message ${error.message}`
              );
          },
          onStart: async (newInquiryId) => {
            await initiateVerificationInquiry(newInquiryId);
            setPersonaInquiryId(newInquiryId);
          },
          onError: (error) => {
            throw new Error('Persona Error', error);
          },
          onFail: () => {
            setPersonaVerificationFailed(true);
            setOfacVerificationFailed(true);
          },
          onComplete: () => {
            setUserInfo({
              fullName,
              email,
              formattedPhone,
            });
            setPersonaCompleted(true);
          },
          onEvent: (name, metadata) => {
            if (metadata?.status === 'declined') {
              setPersonaVerificationFailed(false);
              setOfacVerificationFailed(true);
              setDisablePersonaFields(true);
            }
          },
          referenceId: id,
        };

        const client = new Persona.Client(personaConfig);
        client.open();

        setClientLoading(false);
      }
    },
    [
      user,
      personaEnvironment,
      setOfacVerificationFailed,
      setPersonaVerificationFailed,
      setDisablePersonaFields,
    ]
  );

  const allowedVerificationKYCPreferences = useMemo(() => {
    const allowed = [];

    const options = {
      ssn: (
        <Button
          key="ssn"
          variant="primary"
          onClick={() => onButtonClick(`${process.env.NX_DB_VERIFICATION_TEMPLATE}`)}
          disabled={
            clientLoading ||
            isLoading ||
            verified ||
            verificationSummaryLoading ||
            disablePersonaFields
          }
          className="rounded-full !h-12 w-60"
        >
          Verify your SSN
        </Button>
      ),
    };

    if (kycPreferences.length) kycPreferences.forEach((p) => allowed.push(options[p]));
    else Object.keys(options).forEach((p) => allowed.push(options[p]));

    return allowed;
  }, [
    kycPreferences,
    clientLoading,
    isLoading,
    onButtonClick,
    verificationSummaryLoading,
    verified,
    disablePersonaFields,
  ]);

  return verified ? (
    <div className="flex flex-col gap-2">
      <span className="text-vartana-black-100 body">You’re verified!</span>
      <PersonInfoCard person={userInfo} verified />
    </div>
  ) : (
    <div className="flex justify-center">
      <Loader loading={isLoading} containerClassName="m-20">
        {allowedVerificationKYCPreferences}
      </Loader>
    </div>
  );
}

PersonaKYC.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isTestMode: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    formattedPhone: PropTypes.string,
    personaInquiryId: PropTypes.string,
    verified: PropTypes.bool,
  }).isRequired,
  kycPreferences: PropTypes.arrayOf(PropTypes.string),
  onSuccess: PropTypes.func.isRequired,
  setPersonaVerificationFailed: PropTypes.func.isRequired,
  setOfacVerificationFailed: PropTypes.func.isRequired,
  disablePersonaFields: PropTypes.bool.isRequired,
  setDisablePersonaFields: PropTypes.func.isRequired,
};

PersonaKYC.defaultProps = {
  kycPreferences: [],
};

export default PersonaKYC;
