import { ErrorMessage, Field, useField } from 'formik';
import PropTypes from 'prop-types';
import { InputErrorField } from '../inputField/InputErrorField';

export function ToggleButton({ name, label, onChange, showError }) {
  const [, , { setValue }] = useField(name);

  const handleOnChange = (e) => {
    setValue(e.target.checked);
    onChange(e);
  };
  return (
    <>
      <label className="relative inline-flex gap-2 items-center cursor-pointer">
        <Field
          type="checkbox"
          name={name}
          className="sr-only peer"
          onChange={handleOnChange}
        />
        <div className="w-9 h-5 bg-vartana-gray-80 dark:peer-focus:ring-blue-800 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-vartana-blue-120"></div>
        <span className="body-text">{label}</span>
      </label>
      {showError ? <ErrorMessage name={name} component={InputErrorField} /> : null}
    </>
  );
}

ToggleButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showError: PropTypes.bool,
};

ToggleButton.defaultProps = {
  showError: true,
};

export default ToggleButton;
