import PropTypes from 'prop-types';

import { ProgressStep as WorkflowStep } from '@vartana-repo/base-components/progress-step';
import { Button } from '@vartana-repo/base-components/buttons';
import { ProgressBar } from '../components/ProgressBar/ProgressBar';

export function Navbar({ currentProgress, logoUrl, isHidden, showViewDetail }) {
  return (
    <div className={`${isHidden ? 'hidden' : ''} flex flex-col gap-0 lg:gap-1`}>
      <div
        className={`
        flex justify-between lg:items-center
        px-4 md:px-8 lg:p-0 relative h-16 lg:h-20
      `}
      >
        {logoUrl && (
          <div className="flex items-center w-fit lg:absolute lg:left-3">
            <img src={logoUrl} alt="Company Logo" className="max-h-8" />
          </div>
        )}

        {/* Mobile Navbar */}
        {showViewDetail && (
          <Button
            variant="linkBlue"
            className="lg:hidden"
            onClick={() => showViewDetail(true)}
          >
            Order summary
          </Button>
        )}

        {/* Desktop Navbar */}
        <div className="hidden lg:flex items-center justify-center lg:w-full gap-20">
          {currentProgress.steps.length > 0
            ? currentProgress.steps.map((step) => <WorkflowStep key={step.name} {...step} />)
            : ''}
        </div>
      </div>
      <ProgressBar classProp="pt-4" progress={currentProgress.progress} />
    </div>
  );
}

Navbar.propTypes = {
  currentProgress: PropTypes.shape({
    progress: PropTypes.number,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        step: PropTypes.number,
        status: PropTypes.string,
      })
    ),
  }).isRequired,
  logoUrl: PropTypes.string.isRequired,
  showViewDetail: PropTypes.func,
  isHidden: PropTypes.bool,
};

Navbar.defaultProps = {
  showViewDetail: null,
  isHidden: false,
};
