import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { NotFoundIcon, errors, VartanaLogo, SomethingWentWrongIcon } from '../../../assets';

export function ErrorPage({ errorTitle, errorMessage }) {
  const { state } = useLocation();
  const errorTitleText = state?.errorTitle || errorTitle;
  const errorMessageText = state?.errorMsg || errorMessage;
  const selectedIcon =
    errorTitleText === 'Page not found' ? (
      <NotFoundIcon className="w-80 md:w-96 h-56 md:h-64" />
    ) : (
      <SomethingWentWrongIcon className="w-80 md:w-96 h-56 md:h-64" />
    );

  return (
    <div className="flex flex-col w-screen h-screen">
      <div className="flex flex-col justify-center items-center bg-white h-full">
        {selectedIcon}
        <h5 className="font-bold text-vartana-gray-140 text-2xl pt-8">{errorTitleText}</h5>
        <div className="mt-2 text-vartana-gray-140 text-center max-w-sm md:max-w-md">
          {errorMessageText}
        </div>
      </div>

      <div className="flex justify-center md:justify-end items-center gap-2 text-xs font-medium text-vartana-gray-140 pb-10 md:pr-24">
        In partnership with <VartanaLogo />
      </div>
    </div>
  );
}

ErrorPage.propTypes = {
  errorTitle: PropTypes.string,
  errorMessage: PropTypes.string,
};

ErrorPage.defaultProps = {
  errorTitle: errors.title,
  errorMessage: errors.message,
};
