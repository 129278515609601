import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { AccordionPanel, ExpandableCard } from '@vartana-repo/base-components/accordion';
import { DirectPaymentIcon, InvoiceIcon, paymentModes } from '../../../assets';
import './BillingMethodAccordions.scss';

/**
 * Expandable accordions for each billing method available
 *
 * @param selectedAccordion
 * @param onChange A callback to handle clicking of accordion panel
 * @param billingMethods Allowed payment methods for given order
 * @param AchCardContent Component to show when ACH accordion is expanded
 * @param InvoiceCardContent Component to show when Invoice accordion is expanded
 */
const BillingMethodAccordions = ({
  selectedAccordion,
  onChange,
  billingMethods,
  AchCardContent,
  InvoiceCardContent,
}) => {
  // Keys of this object must match values of billingMethods
  const accordionParams = {
    ach: {
      title: 'ACH',
      icon: <DirectPaymentIcon className="w-8 h-8" />,
      content: AchCardContent,
      panelProps: {
        className: 'panel--content-overflow',
      },
    },
    invoice: {
      title: 'Send Invoice',
      icon: <InvoiceIcon />,
      content: InvoiceCardContent,
      panelProps: {
        className: 'panel--content-overflow',
      },
    },
  };

  const accordionItems = billingMethods.map((method) => ({
    id: method,
    header: (
      <AccordionPanel
        isSelected={selectedAccordion === method}
        title={accordionParams[method].title}
        Logo={accordionParams[method].icon}
        className="card-subtitle-bold text-vartana-gray-160 pl-4 md:pl-6"
      />
    ),
    content: accordionParams[method].content,
    panelProps: accordionParams[method].panelProps,
  }));

  const isExpandedView = [paymentModes.ach, paymentModes.invoice].includes(selectedAccordion);

  if (!isEmpty(accordionItems)) {
    return (
      <ExpandableCard
        key={selectedAccordion}
        items={accordionItems}
        defaultActiveKey={selectedAccordion}
        onChange={onChange}
        expandIcon={() => null}
        panelProps={{
          collapsible: isExpandedView && accordionItems.length === 1 ? 'disabled' : '-',
        }}
      />
    );
  }
  return 'No billing methods available';
};

BillingMethodAccordions.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedAccordion: PropTypes.string.isRequired,
  billingMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  AchCardContent: PropTypes.element.isRequired,
  InvoiceCardContent: PropTypes.element.isRequired,
};

export default BillingMethodAccordions;
