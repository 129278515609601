import { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, useField } from 'formik';

import { placesSuggestions, placeDetails } from '../../utils/google';
import { Label } from '../label/Label';
import { InputErrorField } from '../inputField/InputErrorField';
import '../form.scss';

export const SearchAddress = ({
  name,
  placeholder,
  label,
  value,
  afterPlaceSelect,
  disabled,
}) => {
  const elementRef = useRef();
  const [field, meta, { setValue }] = useField(name);
  const inputRef = useRef();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [addresses, setAddresses] = useState();
  const [cursor, setCursor] = useState(0);
  const [debounceId, setDebounceId] = useState(null);
  const [addressComponents, setAddressComponents] = useState({
    city: '',
    state: { shortName: '', longName: '' },
    zip: '',
  });

  const isInputFocused = useCallback(() => document?.activeElement === inputRef?.current, []);

  const loadPlaceSuggestions = useCallback(
    (inputValue) => {
      placesSuggestions(inputValue, (predictions) => {
        if (predictions.length > 0) {
          setAddresses(
            predictions.map((prediction) => ({
              label: prediction?.description,
              value: prediction?.structured_formatting?.main_text,
              placeId: prediction?.place_id,
            }))
          );
          if (cursor > predictions.length - 1) setCursor(predictions.length - 1);
          if (isInputFocused()) setDropdownVisible(true);
        } else {
          setDropdownVisible(false);
        }
      });
    },
    [cursor, isInputFocused]
  );

  const onPlaceSelect = useCallback(
    (address) => {
      setDropdownVisible(false);
      setValue(address.value);
      placeDetails(address.placeId, (place) => {
        if (place) {
          const newAddressComponents = { ...addressComponents };
          place.address_components.forEach((component) => {
            const { types } = component;
            if (types.includes('administrative_area_level_1')) {
              newAddressComponents.state.longName = component.long_name;
              newAddressComponents.state.shortName = component.short_name;
            } else if (types.includes('postal_code')) {
              newAddressComponents.zip = component.long_name;
            } else if (types.includes('locality')) {
              newAddressComponents.city = component.long_name;
            }
          });
          setAddressComponents(newAddressComponents);
          afterPlaceSelect(newAddressComponents);
        }
      });
    },
    [addressComponents, afterPlaceSelect, setValue]
  );

  const inputProps = {
    ...field,
    className: `w-full field--base field-text ${
      disabled ? 'bg-vartana-gray-30 border-vartana-gray-30' : ''
    } ${meta.touched && meta.error ? 'field--error' : ''}`,
    type: 'text',
    name: field.name,
    id: field.name,
    value: value || field.value,
    placeholder,
    autoComplete: 'nope',
    disabled,
    ref: inputRef,
    onChange: (e) => {
      const inputValue = e.target.value;
      if (inputValue.length === 0) setAddresses([]);
      setValue(inputValue);
      if (debounceId) clearTimeout(debounceId);
      setDebounceId(setTimeout(() => loadPlaceSuggestions(inputValue), 1000));
    },
    onBlur: (e) => {
      field.onBlur(e);
      setDropdownVisible(false);
    },
    onKeyDown(e) {
      if (e.key === 'ArrowUp' && cursor > 0) {
        setCursor((prevCursor) => prevCursor - 1);
      } else if (e.key === 'ArrowDown' && cursor < addresses.length - 1) {
        setCursor((prevCursor) => prevCursor + 1);
      } else if (e.key === 'Enter' && addresses.length > 0) {
        onPlaceSelect(addresses[cursor]);
      }
    },
  };

  return (
    <div className="relative">
      <div ref={elementRef} className="field-wrapper">
        {label ? (
          <Label
            htmlFor={name}
            className={meta.touched && meta.error ? 'text-vartana-red-160' : ''}
          >
            {label}
          </Label>
        ) : null}
        <input {...inputProps} />
      </div>
      {dropdownVisible && addresses.length > 0 ? (
        <div
          className={`
            field-text mt-2 m-0 absolute bg-white
            border border-gray-100 rounded-md shadow-sm z-10
          `}
          style={{
            width: elementRef?.current?.clientWidth || 0,
          }}
        >
          <ul role="listbox" tabIndex="0">
            {addresses.map((address, index) => (
              <li
                aria-selected
                className={`
                  px-3 cursor-pointer z-20
                  ${cursor === index ? 'bg-vartana-blue-90 text-white' : ''}
                `}
                key={address.placeId}
                role="option"
                onMouseDown={() => onPlaceSelect(address)}
                onMouseEnter={() => setCursor(index)}
              >
                <div className="py-3">{address.label}</div>
              </li>
            ))}
          </ul>
          {/* Todo: add power by google svg */}
        </div>
      ) : null}
      <ErrorMessage name={name} component={InputErrorField} />
    </div>
  );
};

SearchAddress.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  afterPlaceSelect: PropTypes.func,
  disabled: PropTypes.bool,
};

SearchAddress.defaultProps = {
  name: '',
  placeholder: '',
  label: '',
  value: '',
  className: '',
  disabled: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  afterPlaceSelect: () => {},
};
