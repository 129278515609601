import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { capitalize, get, isEmpty } from 'lodash';
import { useIntercom } from 'react-use-intercom';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import {
  checkoutProgressInitialState,
  GET_SESSION_DETAIL,
  getAPIURL,
  VartanaLogo,
} from '../assets';
import { AppLoadingModal } from '../components/Modals';
import { Navbar } from './Navbar';
import { getRouteForPG } from './routes';
import { UPDATE_APPLICATION_FIRST_OPENED_AT } from '../assets/graphql/personalGuarantor';

async function setServerCookie(token) {
  return fetch(`${getAPIURL()}/set-cookie`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      token,
      app: 'checkout-app',
    }),
    credentials: 'include',
  });
}

function initIntercomWithUser(user, updateIntercom) {
  updateIntercom({
    name: capitalize(`${user.firstName} ${user.lastName}`),
    email: user.email,
    created_at: new Date(user.createdAt).valueOf(),
    customAttributes: {
      application: 'checkout-app',
      environment: process.env.NX_NODE_ENV,
      resourceId: user.id,
      resourceType: 'User',
    },
  });
}

export const PersonalGuarantorLayout = ({ setMetaData }) => {
  const [searchParams] = useSearchParams();
  const { update: updateIntercom } = useIntercom();
  const navigate = useNavigate();
  const token = searchParams.get('t');
  const [updateApplicationFirstOpenedAt] = useMutation(UPDATE_APPLICATION_FIRST_OPENED_AT, {
    onError: (error) => console.error('[UPDATE_APPLICATION_FIRST_OPENED_AT]', error),
  });

  const [
    fetchUserSession,
    { data: sessionDetail, loading: sessionLoading, refetch: refetchSessionDetails },
  ] = useLazyQuery(GET_SESSION_DETAIL, {
    notifyOnNetworkStatusChange: true,
    onCompleted: async (response) => {
      const currentApplicationProgress = get(
        response,
        'session.creditAppraisal.currentApplicationProgress.steps',
        []
      ).find((step) => step.status === 'complete');
      await updateApplicationFirstOpenedAt({
        variables: {
          currentStep: (currentApplicationProgress
            ? currentApplicationProgress.name
            : get(response, 'session.creditAppraisal.applicationSteps.0.name', '')
          )
            .toLowerCase()
            .replace(' ', '_'),
        },
      });
      if (get(response, 'session', null)) {
        navigate(
          getRouteForPG(
            get(response, 'session.creditAppraisal.currentApplicationProgress.steps', [])
          )
        );
      } else navigate('/unauthorize');
    },
    onError: (error) => console.error('[GET_SESSION_DETAIL]', error),
  });

  useEffect(() => {
    if (token) {
      setServerCookie(token).then((response) => response.ok && fetchUserSession());
    } else fetchUserSession();
  }, [fetchUserSession, token]);

  const user = get(sessionDetail, 'session.user', {});
  useEffect(() => {
    if (!isEmpty(user)) {
      initIntercomWithUser(user, updateIntercom);
    }
  }, [updateIntercom, user]);

  useEffect(() => {
    const faviconUrl = get(sessionDetail, 'session.creditAppraisal.company.seller.faviconUrl');
    const title = get(
      sessionDetail,
      'session.creditAppraisal.company.seller.businessName',
      ''
    );

    if (faviconUrl && title) setMetaData({ faviconUrl, title });
  }, [sessionDetail, setMetaData]);

  return (
    <div
      id="app-wrapper"
      className={`
        flex flex-col lg:items-center w-full max-w-vartana-bound h-full
        p-0 lg:px-24
      `}
    >
      <AppLoadingModal isLoading={sessionLoading} />

      {/* App Content */}
      {sessionDetail && (
        <div
          id="app-content"
          className="grid grid-rows-personal-guarantor-layout-mobile md:grid-rows-personal-guarantor-layout h-full w-full sm:rounded-xl"
        >
          <Navbar
            currentProgress={get(
              sessionDetail,
              'session.creditAppraisal.currentApplicationProgress',
              checkoutProgressInitialState
            )}
            logoUrl={get(sessionDetail, 'session.creditAppraisal.company.seller.logoUrl', '')}
          />

          <div id="app-body" className="flex justify-center overflow-y-auto h-full">
            <div className="flex flex-col py-6 lg:pt-10 px-4 md:px-8 w-full lg:w-9/12 h-fit">
              <Outlet context={[{ sessionDetail }, refetchSessionDetails]} />
            </div>
          </div>

          <span className="w-full flex h-px bg-vartana-gray-40"></span>

          <div
            id="app-footer"
            className={`
              flex items-center gap-2 justify-center md:justify-end w-full py-6
              field-helper-text text-vartana-gray-140 shadow-lift-2-inverted md:shadow-none
            `}
          >
            In partnership with <VartanaLogo />
          </div>
        </div>
      )}
    </div>
  );
};

PersonalGuarantorLayout.propTypes = {
  setMetaData: PropTypes.func.isRequired,
};
