import PropTypes from 'prop-types';

import { Button } from '@vartana-repo/base-components/buttons';
import { Loader } from '@vartana-repo/base-components/loader';

import { PlusIcon } from '../../../assets';
import { isInvoiceAccount } from '../../../assets/utils/banks.utils';
import { BankCard } from '../../../components/Cards';
import { RegisteredBanks } from '../../../components/Modals/AchModal/RegisteredBanks';

export const AchCardContent = ({
  isLoading,
  isValidAccount,
  paymentMethods,
  selectedAccount,
  setSelectedAccount,
  handleUseAnotherBank,
}) => {
  const isBankCardVisible = isValidAccount && !isInvoiceAccount(selectedAccount);
  return (
    <Loader isLoading={isLoading}>
      <div className="flex flex-col gap-2 pb-4">
        {isBankCardVisible && (
          <RegisteredBanks
            paymentMethods={paymentMethods}
            onSelect={(account) => {
              setSelectedAccount({
                type: account.paymentMode,
                payload: account,
              });
            }}
          />
        )}
        {isBankCardVisible ? (
          <Button
            variant="linkBlue"
            onClick={handleUseAnotherBank}
            className="place-self-center md:place-self-end"
          >
            <PlusIcon /> Add another bank
          </Button>
        ) : (
          <Button onClick={handleUseAnotherBank} className="mt-4 place-self-center">
            Add billing for ACH
          </Button>
        )}
      </div>
    </Loader>
  );
};

AchCardContent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isValidAccount: PropTypes.bool.isRequired,
  paymentMethods: PropTypes.arrayOf(BankCard.propTypes.selectedAccount).isRequired,
  selectedAccount: BankCard.propTypes.selectedAccount.isRequired,
  setSelectedAccount: PropTypes.func.isRequired,
  handleUseAnotherBank: PropTypes.func.isRequired,
};
