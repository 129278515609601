import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { get, omit } from 'lodash';
import PropTypes from 'prop-types';

import { Loader } from '@vartana-repo/base-components/loader';
import { Button } from '@vartana-repo/base-components/buttons';
import {
  GET_INVOICE_RECIPIENTS,
  LeftArrow,
  RightArrow,
  UPSERT_INVOICE_RECIPIENT,
  UPDATE_INVOICE_RECIPIENTS,
  UserIcon,
} from '../../../assets';
import { AddEditRecipientModal } from '../../../components/Modals';
import { RecipientsList } from './RecipientsList';
import { PageHeader } from '../../PageHeader/PageHeader';
import { mapStateToPath } from '../../routes';

const achRecipientInitState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

const pageHeaders = {
  title: 'Add billing recipient',
  subtitle: 'Let us know who should receive invoice notifications. You can select multiple.',
};

export const AddBillingReceipt = ({ onBack }) => {
  const [selectedRecipient, setSelectedRecipient] = useState(achRecipientInitState);
  const [showRecipientModal, setShowRecipientModal] = useState(false);
  const [billingRecipients, setBillingRecipients] = useState([]);

  const urlParams = useParams();
  const navigate = useNavigate();
  const { orderNumber } = urlParams;
  const [, updateOrderDetails] = useOutletContext();

  const [fetchRecipients, { data: sessionData, loading }] = useLazyQuery(
    GET_INVOICE_RECIPIENTS,
    { onError: (error) => console.error(error) }
  );
  const [upsertRecipient, { loading: isAddEditRecipientLoading }] = useMutation(
    UPSERT_INVOICE_RECIPIENT,
    { onError: (error) => console.error(error) }
  );
  const [updateInvoiceRecipients, { loading: isUpsertInvoiceRecipientsLoading }] = useMutation(
    UPDATE_INVOICE_RECIPIENTS,
    { onError: (error) => console.error(error) }
  );

  const handleRecipientModalClose = () => {
    setShowRecipientModal(false);
    setSelectedRecipient(achRecipientInitState);
  };

  const handleRecipientEdit = (recipient) => {
    setSelectedRecipient({
      ...omit(recipient, ['formattedPhone', 'fullName', 'id', 'role']),
      phone: get(recipient, 'formattedPhone', ''),
      contactEmail: get(recipient, 'contactEmail', ''),
    });
    setShowRecipientModal(true);
  };

  const handleRecipientSubmit = async (values, { setErrors }) => {
    const { data, errors } = await upsertRecipient({
      variables: { ...values },
    });

    if (errors) setErrors(errors);
    if (data?.upsertInvoiceRecipient) {
      fetchRecipients();
      handleRecipientModalClose();
    }
  };

  useEffect(() => {
    const recipients = get(sessionData, 'session.order.company.users', []).map(
      (recipient) => ({
        ...recipient,
        selected: false,
      })
    );
    setBillingRecipients(recipients);
  }, [sessionData]);

  useEffect(() => {
    fetchRecipients();
  }, [fetchRecipients]);

  const handleNextButtonClick = useCallback(async () => {
    const { data, errors } = await updateInvoiceRecipients({
      variables: {
        users: [
          ...billingRecipients.map((recipients) => ({
            id: recipients.id,
            receiveInvoice: recipients.selected,
          })),
        ],
      },
    });

    if (errors) {
      console.error('[UPDATE_INVOICE_RECIPIENTS]', errors);
      return;
    }
    const state = get(data, 'updateInvoiceRecipients.state', '');
    if (state) {
      updateOrderDetails((order) => ({
        ...order,
        state: data.updateInvoiceRecipients.state,
        currentCheckoutProgress: get(
          data,
          'updateInvoiceRecipients.currentCheckoutProgress',
          {}
        ),
      }));
      const newRoute = mapStateToPath(orderNumber, state);
      navigate(newRoute);
    }
  }, [billingRecipients, navigate, orderNumber, updateOrderDetails, updateInvoiceRecipients]);

  const toggleRecipientSelection = (recipient) => {
    setBillingRecipients((prevState) => {
      return prevState.map((billingRecipient) => {
        if (billingRecipient.id === recipient.id) {
          return { ...billingRecipient, selected: !billingRecipient.selected };
        }
        return billingRecipient;
      });
    });
  };

  return (
    <>
      <PageHeader title={pageHeaders.title} subTitle={pageHeaders.subtitle} />
      <Loader
        isLoading={loading || isUpsertInvoiceRecipientsLoading}
        className="w-10 h-10 mt-32"
      >
        <div className="card-border-blue px-6">
          <div className="h-16 py-4 flex items-center gap-2">
            <UserIcon className="w-6 h-6" />
            <span className="card-subtitle-bold">Recipient of payment notification</span>
          </div>
          <div className="pb-8">
            {billingRecipients?.length > 0 ? (
              <RecipientsList
                recipients={billingRecipients}
                onSelect={(recipient) => toggleRecipientSelection(recipient)}
                onAdd={() => setShowRecipientModal(true)}
                onEdit={handleRecipientEdit}
              />
            ) : (
              <Button className="mt-4 place-self-center">Add new recipient</Button>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between w-full pt-8">
          <Button variant="linkBlue" onClick={onBack}>
            <LeftArrow /> Back
          </Button>

          <Button
            isLoading={loading || isUpsertInvoiceRecipientsLoading}
            disabled={
              billingRecipients.length === 0 || !billingRecipients.find((r) => r.selected)
            }
            onClick={handleNextButtonClick}
          >
            Next <RightArrow />
          </Button>
        </div>
      </Loader>
      <AddEditRecipientModal
        isOpen={showRecipientModal}
        isSubmitting={isAddEditRecipientLoading}
        recipientDetail={selectedRecipient}
        onSubmit={handleRecipientSubmit}
        onBack={handleRecipientModalClose}
        onClose={handleRecipientModalClose}
      />
    </>
  );
};
AddBillingReceipt.propTypes = {
  onBack: PropTypes.func.isRequired,
};
