import PropTypes from 'prop-types';

export const Label = ({ children, className, ...rest }) => {
  return (
    <label className={`field-label ${className}`} {...rest}>
      {children}
    </label>
  );
};
Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
};
Label.defaultProps = {
  className: '',
};
