import { ExpandIcon } from '../../ExpandIcons';
import { Accordion } from '../../lib/BaseComponentsAccordion';

export function ExpandableCard({
  items,
  label,
  collapseProps,
  panelProps,
  expandIcon,
  defaultActiveKey,
  ...props
}) {
  return (
    <Accordion
      label={label}
      expandIcon={expandIcon}
      items={items}
      defaultActiveKey={defaultActiveKey}
      collapseProps={{
        isCollapsibleMode: collapseProps.isCollapsibleMode,
        className: `bg-white flex flex-col gap-2 ${collapseProps.className}`,
      }}
      panelProps={{
        headerClass: `flex flex-row justify-between p-0 ${panelProps.headerClass}`,
        ...panelProps,
      }}
      {...props}
    />
  );
}

ExpandableCard.propTypes = Accordion.propTypes;

ExpandableCard.defaultProps = {
  label: '',
  variant: 'expandable-card',
  items: [],
  labelCss: '',
  wrapperCss: '',
  defaultActiveKey: '-1',
  collapseProps: {
    isCollapsibleMode: false,
    className: '',
  },
  panelProps: {
    headerClass: '',
    className: '',
  },
  expandIcon: (props) => <ExpandIcon {...props} format="arrows" />,
  onChange: () => null,
};

export default ExpandableCard;
