export const errors = {
  title: 'Error',
  message: 'Please try again later',
};

export const TERMS_AND_CONDITIONS_URL = 'https://www.vartana.com/terms-and-conditions';

export const checkoutProgressInitialState = {
  steps: [],
  progress: 0,
};
// Rollbar alerts which need to be ignored
export const SPAM_ALERTS = ['Failed to fetch'];
