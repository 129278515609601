import PropTypes from 'prop-types';
import { Modal } from '@vartana-repo/base-components/modal';

export const AppLoadingModal = ({ isLoading }) => {
  return (
    <Modal isOpen={isLoading} variant="loader" wrapperClassName="gap-8">
      <div className="w-16 h-16 border-2 block lds-dual-ring m-auto" />
      <div className="text-center text-vartana-gray-140 text-sm font-semibold">
        Give us a minute while we create your checkout.
      </div>
    </Modal>
  );
};
AppLoadingModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default AppLoadingModal;
