import Collapse from 'rc-collapse';
import PropTypes from 'prop-types';
import 'rc-collapse/assets/index.css';
import './Accordion.scss';

const { Panel } = Collapse;

export function Accordion({
  label,
  onChange,
  expandIcon,
  wrapperCss,
  collapseProps,
  panelProps,
  labelCss,
  defaultActiveKey,
  variant,
  items,
}) {
  return (
    <div className={`accordion-wrapper--${variant} ${wrapperCss}`}>
      {label && <span className={labelCss}>{label}</span>}
      <Collapse
        accordion={!collapseProps.isCollapseMode}
        expandIcon={expandIcon}
        defaultActiveKey={defaultActiveKey}
        onChange={(id) => onChange?.(id)}
        className={`accordion-collapse ${collapseProps.className}`}
      >
        {items.length > 0
          ? items.map((item, index) => {
              return (
                <Panel
                  key={item.id || index}
                  header={item.header}
                  headerClass={panelProps.headerClass}
                  className={panelProps.className}
                  panelKey={index}
                  {...panelProps}
                  {...item.panelProps}
                >
                  {item.content}
                </Panel>
              );
            })
          : 'No items found'}
      </Collapse>
    </div>
  );
}

Accordion.propTypes = {
  variant: PropTypes.oneOf(['', 'order-detail', 'expandable-card', 'bank-selection']),
  items: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.string,
  labelCss: PropTypes.string,
  wrapperCss: PropTypes.string,
  defaultActiveKey: PropTypes.string,
  collapseProps: PropTypes.shape({
    isCollapseMode: PropTypes.bool,
    className: PropTypes.string,
  }),
  panelProps: PropTypes.shape({
    headerClass: PropTypes.string,
    className: PropTypes.string,
  }),
  expandIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onChange: PropTypes.func,
};

Accordion.defaultProps = {
  variant: 'order-detail',
  items: [],
  label: '',
  labelCss: '',
  wrapperCss: '',
  defaultActiveKey: '-1',
  collapseProps: {
    isCollapseMode: false,
    className: '',
  },
  panelProps: {
    headerClass: '',
    className: '',
  },
  expandIcon: null,
  onChange: () => null,
};

export default Accordion;
