/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import { Loader } from '@vartana-repo/base-components/loader';
import './Buttons.scss';

const variantClasses = {
  primary: 'primary-btn',
  secondary: 'secondary-btn',
  ghost: 'ghost-btn',
  selector: 'selector-btn',
  link: 'link-btn',
  linkBlue: 'link-btn--blue',
};

export const Button = ({
  type,
  variant,
  disabled,
  children,
  className,
  isSelected,
  isLoading,
  ...props
}) => {
  return (
    <button
      type={type}
      className={`
        btn
        ${variantClasses[variant]}
        ${isSelected ? 'selected' : ''}
        ${className}
      `}
      disabled={disabled || isLoading}
      {...props}
    >
      <Loader isLoading={isLoading} className="w-6 h-6">
        {children}
      </Loader>
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variant: PropTypes.oneOf(Object.keys(variantClasses)),
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSelected: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array])
    .isRequired,
  className: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  disabled: false,
  isLoading: false,
  isSelected: false,
  className: '',
};

export default Button;
