import { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, useField } from 'formik';
import { useDropzone } from 'react-dropzone';
import { truncate } from 'lodash';

import { Label } from '../label/Label';
import { InputErrorField } from '../inputField/InputErrorField';
import { ReactComponent as AttachmentIcon } from '../../assets/attachment.svg';
import { ReactComponent as FileIcon } from '../../assets/file.svg';

const styleClasses = {
  default: {
    borders: 'border-vartana-blue-90',
    text: 'text-vartana-blue-180',
    icon: FileIcon,
  },
  selected: {
    borders: 'border-vartana-gray-100',
    text: 'text-vartana-black',
    icon: AttachmentIcon,
  },
};

export const MultiFileUpload = ({ name, label, accept, uploadText, acceptMultipleFiles }) => {
  const [field, meta, { setValue, setTouched }] = useField(name);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (field.value) {
      setFiles(Array.isArray(field.value) ? field.value : [{ name: field.value }]);
    } else {
      setFiles([]);
    }
  }, [field.value]);

  const stateClasses = useMemo(
    () => (files.length ? styleClasses.selected : styleClasses.default),
    [files]
  );

  const showAddFileMessage = useMemo(
    () => (acceptMultipleFiles ? true : files.length === 0),
    [files, acceptMultipleFiles]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      let filteredFiles = [];
      if (acceptMultipleFiles) {
        // To filter only unique selections and avoid reselection of the same file again
        const uniqFiles = acceptedFiles.filter((acceptedFile) => {
          return !files.some((prevFile) => acceptedFile.name === prevFile.name);
        });

        filteredFiles = [...files, ...uniqFiles];
      } else {
        filteredFiles = [...acceptedFiles];
      }
      setFiles(filteredFiles);
      setValue(filteredFiles);
    },
    [setFiles, setValue, files, acceptMultipleFiles]
  );

  const handleRemoveFile = (event, file) => {
    event.stopPropagation(); // Needed to avoid the parent click trigger
    setFiles((prevFiles) => {
      return prevFiles.filter((f) => f.name !== file.name);
    });
    setTouched(true);
    setValue([...files.filter((f) => f.name !== file.name)]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onFileDialogCancel: () => setTouched(true),
    onDrop,
    accept,
    multiple: acceptMultipleFiles,
    maxFiles: acceptMultipleFiles ? -1 : 1, // -1 means no limit
  });

  return (
    <section>
      {label ? (
        <Label
          htmlFor={name}
          className={`${meta.touched && meta.error ? 'text-vartana-red-160' : ''}`}
        >
          {label}
        </Label>
      ) : null}
      <div
        {...getRootProps()}
        className={`border ${stateClasses.borders} rounded-md cursor-pointer`}
      >
        <div className={`relative py-4 ${stateClasses.text}`}>
          <input
            {...getInputProps()}
            id={field.name}
            name={field.name}
            type="file"
            className="sr-only"
          />
          {isDragActive ? (
            <div className="absolute w-full top-0 left-0 h-full text-center z-10 bg-white bg-opacity-90 rounded-md">
              <p className="text-sm inline-block transform-gpu translate-y-2/4">
                Drop the files here ...
              </p>
            </div>
          ) : null}
          {files.length ? (
            <div className="overflow-y-auto max-h-56 w-full">
              {files.map((file) => (
                <ul key={`${file.name}`}>
                  <li
                    className={`grid grid-cols-12 items-center py-4 px-6 ${
                      showAddFileMessage ? 'border-b border-spacing-1' : ''
                    }`}
                  >
                    <div className="space-x-2 col-span-10 flex items-center">
                      <AttachmentIcon />
                      <p className="text-xs text-sm inline-block">
                        {truncate(file.name, {
                          length: 35,
                          omission: '...',
                        })}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={(e) => handleRemoveFile(e, file)}
                      className="text-xs text-sm text-vartana-blue-120"
                    >
                      Remove
                    </button>
                  </li>
                </ul>
              ))}
            </div>
          ) : null}
          {showAddFileMessage && (
            <div className="flex items-center	space-x-2 py-4 px-6">
              <FileIcon />
              <p className="text-xs text-link-bold  text-vartana-blue-180">{uploadText}</p>
            </div>
          )}
        </div>
      </div>
      <ErrorMessage name={name} component={InputErrorField} />
    </section>
  );
};

MultiFileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  accept: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
  }),
  uploadText: PropTypes.string,
  acceptMultipleFiles: PropTypes.bool,
};

MultiFileUpload.defaultProps = {
  label: '',
  accept: {
    'application/pdf': ['.pdf'],
  },
  uploadText: 'Upload or drag and drop PDF file',
  acceptMultipleFiles: false,
};
