import PropTypes from 'prop-types';

import { RegisteredBanksAccordion } from '../../Accordions';
import { BankCard } from '../../Cards';

export const RegisteredBanks = ({ paymentMethods, onSelect }) => {
  return (
    <>
      {paymentMethods.length === 1 && (
        <BankCard selectedAccount={paymentMethods[0]} variant="primary" />
      )}
      {paymentMethods.length > 1 && (
        <RegisteredBanksAccordion registeredBanks={paymentMethods} onSelect={onSelect} />
      )}
    </>
  );
};

RegisteredBanks.propTypes = {
  paymentMethods: PropTypes.arrayOf(BankCard.propTypes.selectedAccount).isRequired,
  onSelect: PropTypes.func.isRequired,
};
