import PropTypes from 'prop-types';

import { OptionUncheckedIcon, OptionCheckedIcon } from '../../../../assets';

export const OptionCard = ({ groupIndex, label, selected, isDisabled, onClick }) => (
  <div
    className={`flex items-center py-4 px-6 gap-4 h-16 rounded cursor-pointer
      ${
        selected
          ? 'bg-vartana-white-100 border-2 border-vartana-blue-80 shadow-lift-3'
          : 'bg-vartana-gray-20 shadow-lift-1'
      }
      ${isDisabled ? 'cursor-not-allowed' : ''}
    `}
    onClick={isDisabled ? () => {} : onClick}
    role="radio"
    tabIndex={groupIndex}
    aria-checked={selected}
  >
    {selected ? <OptionCheckedIcon /> : <OptionUncheckedIcon />}
    <div className="card-subtitle-bold">{label}</div>
  </div>
);

OptionCard.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  groupIndex: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
};

OptionCard.defaultProps = {
  selected: false,
  onClick: undefined,
  isDisabled: false,
};
