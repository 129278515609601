export const routes = {
  page_not_found: '/',
  error: '/error',
  something_went_wrong: '/something-went-wrong',
  checkout: 'checkout',
  payment_options: 'payment-options',
  payment_method: 'payment-method',
  personal_guarantee: 'personal-guarantee',
  verification: 'verification',
  pg_verification: 'pg-verification',
  signatures: 'signatures',
  completed: 'completed',
  personal_guarantor: 'personal-guarantor',
  business_information: 'business-information',
  authorized_signer: 'authorized-signer',
};

export const ORDER_STATES = {};
Object.entries(routes).forEach(([key, value]) => {
  ORDER_STATES[key] = value.replace('-', '_');
});

export const INITIAL_ORDER_STATE = ORDER_STATES.checkout;

export const mapStateToPath = (orderNumber, orderState, pgRequired = false) => {
  if (orderState === ORDER_STATES.verification && pgRequired) {
    return orderState ? `/order/${orderNumber}/checkout/pg-verification` : '';
  }
  return orderState ? `/order/${orderNumber}/checkout/${orderState.replaceAll('_', '-')}` : '';
};

export const getRouteForPG = (availableSteps) => {
  const allStepsCompleted = availableSteps.every((step) => step.status === 'complete');
  if (allStepsCompleted) return routes.completed;
  const currentActiveStepIdx = availableSteps.findIndex(
    (step) => step.status === 'pending' || step.status === 'active'
  );
  const currentStep = availableSteps[currentActiveStepIdx].name
    .toLowerCase()
    .replace(' ', '_');
  return routes[currentStep];
};

export default routes;
