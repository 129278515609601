import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import { App } from './app/App';
import { client } from './assets';
import { rollbarConfig } from './configs';
import { ErrorPage } from './app/pages';

const root = ReactDOM.createRoot(document.getElementById('root'));
const handleMetaData = ({ faviconUrl, title }) => {
  document.getElementById('favicon').href = faviconUrl;
  document.title = title;
};
async function Main() {
  const clientSideID =
    process.env.NX_NODE_ENV === 'development' ? '' : process.env.NX_LAUNCHDARKLY_CLIENT_ID;
  const LDProvider = await asyncWithLDProvider({
    clientSideID,
    options: {
      streaming: true,
    },
  });

  return root.render(
    <StrictMode>
      <LDProvider>
        <RollbarProvider config={rollbarConfig}>
          <ErrorBoundary fallbackUI={ErrorPage}>
            <ApolloProvider client={client}>
              <div className="font-sans h-screen w-screen flex items-center justify-center overflow-hidden">
                <BrowserRouter>
                  <App setMetaData={handleMetaData} />
                </BrowserRouter>
              </div>
            </ApolloProvider>
          </ErrorBoundary>
        </RollbarProvider>
      </LDProvider>
    </StrictMode>
  );
}

Main();
