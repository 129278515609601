import PropTypes from 'prop-types';
import { ReactComponent as WhiteTick } from '../assets/white-tick.svg';

const checkoutProgressStates = {
  COMPLETED: 'complete',
  ACTIVE: 'active',
  IN_COMPLETE: 'inactive',
};

export function ProgressStep({ name, step, status }) {
  const countClass =
    status === checkoutProgressStates.COMPLETED || status === checkoutProgressStates.ACTIVE
      ? 'bg-vartana-blue-90'
      : 'bg-vartana-gray-110';
  const labelClass =
    status === checkoutProgressStates.COMPLETED || status === checkoutProgressStates.ACTIVE
      ? 'text-vartana-blue-90'
      : 'text-vartana-gray-110';

  return (
    <div className="flex flex-col items-center justify-end gap-1">
      <span
        className={`w-6 h-6 flex justify-center items-center text-sm rounded-full text-white ${countClass}`}
      >
        {status === checkoutProgressStates.COMPLETED ? <WhiteTick /> : step}
      </span>
      <span className={`text-xs ${labelClass}`}>{name}</span>
    </div>
  );
}
ProgressStep.propTypes = {
  name: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

export default ProgressStep;
