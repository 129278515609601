import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';

import { omit } from 'lodash';
import { formatGraphqlErrors, getAPIURL, reportError, reportInfo } from '../utils';
import { navigationService } from './navigation.service';
import { HIDDEN_FIELDS } from './utils';
import { SPAM_ALERTS } from '../constants';
import { errorSubject } from './error.service';

const extensions = {
  SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
  THANK_YOU: 'THANK_YOU',
};

const onErrorLink = onError(({ response, graphQLErrors, networkError, operation }) => {
  const operationVars = omit(operation.variables, HIDDEN_FIELDS);

  if (graphQLErrors) {
    const err = graphQLErrors[0];
    const errorString = `[${err?.extensions?.code || 'UNHANDLED_ERROR'}] Operation: ${
      operation.operationName
    }, Variables: ${JSON.stringify(operationVars)}, Message: ${err.message}`;

    switch (err?.extensions?.code) {
      case extensions.NOT_FOUND: {
        reportInfo(errorString);
        navigationService.navigate('/error', {
          state: {
            title: 'RESOURCE NOT FOUND',
            message: "We couldn't find what you were looking for",
          },
        });
        break;
      }

      case extensions.AUTHENTICATION_ERROR: {
        reportError(errorString);
        navigationService.navigate('/unauthorize');
        break;
      }

      case extensions.VALIDATION_ERROR: {
        try {
          const parsedErrors = JSON.parse(err.message);
          response.errors = formatGraphqlErrors(parsedErrors);
        } catch (e) {
          reportError(errorString);
        }
        break;
      }

      case extensions.NOT_ALLOWED: {
        reportInfo(errorString);
        navigationService.navigate('/unauthorize');
        break;
      }

      case extensions.SERVER_ERROR: {
        reportError(errorString);
        navigationService.navigate('/error');
        break;
      }

      default:
        reportError(errorString);
        errorSubject.next(true);
    }
  }

  if (networkError) {
    const errorString = `[NETWORK_ERROR] Operation: ${
      operation.operationName
    }, Variables: ${JSON.stringify(operationVars)},  Message: ${networkError.message}`;
    if (SPAM_ALERTS.includes(networkError.message)) {
      navigationService.rollbar.info(errorString, networkError);
    } else {
      reportError(errorString);
    }
    navigationService.navigate('/network-error', {
      state: {
        errorTitle: 'Network error',
        errorMsg: 'Something went wrong',
      },
    });
  }
});

const httpLink = createUploadLink({
  uri: `${getAPIURL()}/graphql`,
  credentials: 'include',
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: onErrorLink.concat(httpLink),
  defaultOptions,
});
