import { gql } from '@apollo/client';
import { ORDER_DETAIL_FIELDS, ADDRESS_FIELDS, PAYMENT_METHOD_FIELDS } from './fragments';

// App Layout Component (Data shared by all pages)
export const GET_SESSION_DETAIL = gql`
  query getSessionDetail {
    session {
      id
      user {
        id
        firstName
        lastName
        fullName
        company {
          number
          name
        }
        dob
        formattedPhone
        email
        company {
          id
        }
      }
      creditAppraisal {
        id
        number
        currentApplicationProgress
        applicationSteps
        company {
          seller {
            logoUrl
            faviconUrl
            businessName
            number
          }
        }
        pgRequired
        pgContacts {
          id
          fullName
          firstName
          lastName
          dob
          formattedPhone
          email
        }
      }
    }
  }
`;

export const GET_ORDER_DETAIL = gql`
  ${ORDER_DETAIL_FIELDS}
  query getOrderDetail($number: String!) {
    buyerOrder(number: $number) {
      ...OrderDetailFields
    }
  }
`;

// Payment Methods Page
export const GET_INVOICE_RECIPIENTS = gql`
  query getInvoiceRecipients {
    session {
      order {
        company {
          users {
            id
            firstName
            lastName
            fullName
            email
            role
            formattedPhone
          }
        }
      }
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  ${PAYMENT_METHOD_FIELDS}
  query getPaymentMethods {
    session {
      id
      user {
        company {
          id
          name
          paymentMethods {
            ...PaymentMethodDetails
          }
          defaultPaymentMethod {
            ...PaymentMethodDetails
          }
        }
      }
      order {
        invoiceEnabled
      }
    }
  }
`;

// Personal Guarantee Page
export const GET_USER_INFO_FOR_PG = gql`
  ${ADDRESS_FIELDS}
  query getUserInfoForPG {
    session {
      user {
        id
        firstName
        lastName
        dob
        defaultAddress {
          ...AddressFields
        }
      }
    }
  }
`;

// Verification Page
export const GET_SESSION_FOR_USER_INFO = gql`
  query getSessionForUserInfo {
    session {
      id
      user {
        id
        personaInquiryId
        verifiedAt
        verified
        firstName
        lastName
        title
        fullName
        email
        phone
        formattedPhone
        formattedTitle
        isPersonaVerificationBypassed
        personaInquiryStatus
      }
      order {
        user {
          firstName
          lastName
          email
        }
      }
      creditAppraisal {
        pgRequired
      }
    }
  }
`;
