import PropTypes from 'prop-types';

import { Modal } from '@vartana-repo/base-components/modal';
import { Button } from '@vartana-repo/base-components/buttons';
import { ForwardIcon } from '../../assets';

const Heading = () => (
  <div className="flex flex-col gap-1 pb-4">
    <div className="flex items-center gap-2">
      <ForwardIcon className="w-8 h-8" />
      <span>Message sent!</span>
    </div>
  </div>
);

export const MessageSentModal = ({ isOpen, onClose, sellerName }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} header={<Heading sellerName={sellerName} />}>
      <div className="mb-6">
        Thank you for reaching out! {sellerName} will get back to you as soon as possible.
      </div>
      <div className="col-span-3 md:col-span-4 flex justify-end">
        <Button variant="secondary" onClick={onClose}>
          Return to checkout
        </Button>
      </div>
    </Modal>
  );
};

MessageSentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sellerName: PropTypes.string.isRequired,
};

export default MessageSentModal;
