export async function fetchJobTitles() {
  try {
    const response = await fetch(`${process.env.NX_API_URL}/utils/signer-titles`, {
      credentials: 'include',
    });
    return response.json();
  } catch (e) {
    console.error(e);
    return {};
  }
}
