import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ExpandableCard } from '@vartana-repo/base-components/accordion';
import { BankCard } from '../../Cards';
import { useFormattedBanks } from './RegisteredBanks.hooks';
import './RegisteredBanksAccordion.scss';

export const RegisteredBanksAccordion = ({ registeredBanks, onSelect }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { formattedBanks, formatBanks } = useFormattedBanks();

  useEffect(() => {
    const handleOptionSelect = (bank) => {
      setIsExpanded(!isExpanded);
      onSelect(bank);
    };
    formatBanks({
      registeredBanks,
      onSelect: handleOptionSelect,
    });
  }, [registeredBanks, formatBanks, isExpanded, onSelect]);

  return formattedBanks?.header ? (
    <ExpandableCard
      key={isExpanded}
      variant="bank-selection"
      items={[formattedBanks]}
      wrapperCss="relative w-full h-24"
      panelProps={{
        className: 'bg-vartana-white-100',
        headerClass: '',
      }}
      collapseProps={{
        isCollapsibleMode: false,
        className: 'registered-banks border-0 relative flex flex-col gap-3',
      }}
    />
  ) : null;
};

RegisteredBanksAccordion.propTypes = {
  registeredBanks: PropTypes.arrayOf(BankCard.propTypes.selectedAccount).isRequired,
  onSelect: PropTypes.func.isRequired,
};
