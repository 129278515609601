import {
  CustomSelect,
  DateInput,
  InputField,
  InputErrorField,
  SearchAddress,
  ToggleButton,
} from '@vartana-repo/base-components/form';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import { percentageOfOwnershipOptions, states } from '../../../assets';
import { JobTitles } from '../Verification/JobTitles';

export function PersonalGuarantorForm({
  index,
  setFieldValue,
  errors,
  touched,
  showToggleBtn,
  handleOnToggleChange,
}) {
  const formErrors =
    errors.personalGuarantors && errors.personalGuarantors[index]
      ? errors.personalGuarantors[index]
      : {};
  const formTouched =
    touched.personalGuarantors && touched.personalGuarantors[index]
      ? touched.personalGuarantors[index]
      : {};

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-6">
        <InputField
          id={`personalGuarantors.${index}.firstName`}
          name={`personalGuarantors.${index}.firstName`}
          label="Legal first name"
          errorMsg={formErrors.firstName}
          touched={formTouched.firstName}
        />
      </div>

      <div className="col-span-12 md:col-span-6">
        <InputField
          id={`personalGuarantors.${index}.lastName`}
          name={`personalGuarantors.${index}.lastName`}
          label="Last name"
          errorMsg={formErrors.lastName}
          touched={formTouched.lastName}
        />
      </div>

      <div className="col-span-12 md:col-span-6">
        <InputField
          id={`personalGuarantors.${index}.email`}
          name={`personalGuarantors.${index}.email`}
          label="Email"
          placeholder="example@email.com"
          errorMsg={formErrors.email}
          touched={formTouched.email}
        />
      </div>
      <div className="col-span-12 md:col-span-6">
        <InputField
          id={`personalGuarantors.${index}.phone`}
          name={`personalGuarantors.${index}.phone`}
          label="Phone number"
          mask="+1 (999) 999-9999"
          placeholder="+1 (___) ___-____"
          errorMsg={formErrors.phone}
          touched={formTouched.phone}
        />
      </div>
      <div className="col-span-12 md:col-span-6">
        <DateInput
          id={`personalGuarantors.${index}.dob`}
          name={`personalGuarantors.${index}.dob`}
          label="DOB"
          placeholder="MM/DD/YYYY"
        />
      </div>
      <div className="col-span-12 md:col-span-6">
        <InputField
          id={`personalGuarantors.${index}.ssn`}
          name={`personalGuarantors.${index}.ssn`}
          label="Social security number"
          mask="999-99-9999"
          placeholder="___-__-____"
          errorMsg={formErrors.ssn}
          touched={formTouched.ssn}
          disableFullStory
        />
      </div>
      <div className="col-span-12 md:col-span-6">
        <JobTitles
          id={`personalGuarantors.${index}.title`}
          name={`personalGuarantors.${index}.title`}
          label="Job title"
        />
      </div>
      <div className="col-span-12 md:col-span-6">
        <CustomSelect
          id={`personalGuarantors.${index}.percentageOfOwnership`}
          name={`personalGuarantors.${index}.percentageOfOwnership`}
          label="Percentage of ownership"
          options={percentageOfOwnershipOptions}
          placeholder="Select"
        />
      </div>

      <div className="col-span-12">
        <SearchAddress
          id={`personalGuarantors.${index}.street`}
          name={`personalGuarantors.${index}.street`}
          label="Primary address"
          afterPlaceSelect={(addressComponents) => {
            if (addressComponents.city)
              setFieldValue(`personalGuarantors.${index}.city`, addressComponents.city);
            if (addressComponents.state?.shortName)
              setFieldValue(
                `personalGuarantors.${index}.state`,
                addressComponents.state.shortName
              );
            if (addressComponents.zip)
              setFieldValue(`personalGuarantors.${index}.zip`, addressComponents.zip);
          }}
        />
      </div>
      <div className="col-span-12 md:col-span-6">
        <InputField
          id={`personalGuarantors.${index}.city`}
          name={`personalGuarantors.${index}.city`}
          label="City"
          errorMsg={formErrors.city}
          touched={formTouched.city}
        />
      </div>
      <div className="col-span-12 md:col-span-3">
        <CustomSelect
          id={`personalGuarantors.${index}.state`}
          name={`personalGuarantors.${index}.state`}
          label="State"
          options={states}
        />
      </div>
      <div className="col-span-12 md:col-span-3">
        <InputField
          id={`personalGuarantors.${index}.zip`}
          name={`personalGuarantors.${index}.zip`}
          label="Zip"
          placeholder="XXXXX"
          errorMsg={formErrors.zip}
          touched={formTouched.zip}
        />
      </div>
      {showToggleBtn && (
        <div className="col-span-12">
          <div className="flex w-fit bg-vartana-blue-40 rounded-full p-2">
            <ToggleButton
              name={`personalGuarantors.${index}.primary`}
              label="Default signer for all orders"
              onChange={handleOnToggleChange}
              showError={false}
            />
          </div>
          <ErrorMessage
            name={`personalGuarantors.${index}.primary`}
            component={InputErrorField}
          />
        </div>
      )}
    </div>
  );
}

PersonalGuarantorForm.propTypes = {
  index: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    personalGuarantors: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  touched: PropTypes.shape({
    personalGuarantors: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  showToggleBtn: PropTypes.bool.isRequired,
  handleOnToggleChange: PropTypes.func.isRequired,
};
