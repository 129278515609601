import PropTypes from 'prop-types';

import { InputField } from '@vartana-repo/base-components/form';
import { JobTitles } from './JobTitles';

const UserFields = ({ verifyFailed, errors, touched }) => (
  <div className="flex flex-col gap-4 md:grid md:grid-cols-6">
    <div className="col-span-6 md:col-span-3">
      <InputField
        name="firstName"
        label="Signer's first name"
        errorMsg={errors.firstName || (verifyFailed ? 'Invalid' : '')}
        touched={touched.firstName || verifyFailed}
      />
    </div>

    <div className="col-span-6 md:col-span-3">
      <InputField
        name="lastName"
        label="Signer's last name"
        errorMsg={errors.lastName || (verifyFailed ? 'Invalid' : '')}
        touched={touched.lastName || verifyFailed}
      />
    </div>

    <div className="col-span-6">
      <JobTitles name="title" label="Job title" />
    </div>
  </div>
);

UserFields.propTypes = {
  verifyFailed: PropTypes.bool,
  errors: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    firstName: PropTypes.bool,
    lastName: PropTypes.bool,
    dob: PropTypes.bool,
  }).isRequired,
};

UserFields.defaultProps = {
  verifyFailed: false,
};

export default UserFields;
