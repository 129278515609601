import { useState } from 'react';
import { AddBillingReceipt } from './AddBillingReceipt';
import { SelectBillingMethod } from './SelectBillingMethod';

const pages = {
  SELECT_BILLING_METHOD: 'SELECT_BILLING_METHOD',
  ADD_BILLING_RECEIPT: 'ADD_BILLING_RECEIPT',
};

export const BillingMethodPage = () => {
  const [currentPage, setCurrentPage] = useState(pages.SELECT_BILLING_METHOD);

  return currentPage === pages.SELECT_BILLING_METHOD ? (
    <SelectBillingMethod onNext={() => setCurrentPage(pages.ADD_BILLING_RECEIPT)} />
  ) : (
    <AddBillingReceipt onBack={() => setCurrentPage(pages.SELECT_BILLING_METHOD)} />
  );
};
