export const PaymentTypes = {
  NET_TERMS: 'net_terms',
  INSTALLMENTS: 'installments',
  DIRECT: 'direct_payment',
};

export const formattedPaymentFrequencies = {
  monthly: '/month',
  quarterly: '/quarter',
  annually: '/year',
};

export const paymentModes = {
  ach: 'ach',
  plaid: 'plaid',
  invoice: 'invoice',
};
