import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { get } from 'lodash';

import {
  ATTACH_BLOBS_CREDIT_APPRAISAL,
  CREATE_DIRECT_UPLOADS,
} from '../graphql/personalGuarantor';
import { calculateFilesMetadata } from '../utils';
import { performUpload } from '../utils/directUpload';

export const useSetAddressFields = (setFieldValue, validateField) => {
  const [selectedPlace, setSelectedPlace] = useState({});
  useEffect(() => {
    if (selectedPlace.city) {
      setFieldValue('city', selectedPlace.city).then(() => validateField('city'));
    }
    if (selectedPlace.state?.shortName) {
      setFieldValue('state', selectedPlace.state.shortName).then(() => validateField('state'));
    }
    if (selectedPlace.zip) {
      setFieldValue('zip', selectedPlace.zip).then(() => validateField('zip'));
    }
  }, [selectedPlace, setFieldValue, validateField]);
  return setSelectedPlace;
};

export const useDirectUploadFilesAppraisal = () => {
  const [createDirectUploads] = useMutation(CREATE_DIRECT_UPLOADS);
  const [attachBlobs] = useMutation(ATTACH_BLOBS_CREDIT_APPRAISAL);

  return [
    function directUploadFilesAppraisal(files, attachBlobData) {
      return new Promise((resolve, reject) => {
        calculateFilesMetadata(files)
          .then((filesMetadata) => {
            createDirectUploads({
              variables: {
                directUploads: filesMetadata,
              },
            })
              .then((responseData) => {
                const directUploads = get(
                  responseData,
                  'data.createDirectUploads.directUploads'
                );
                const pendingUploads = directUploads.map((directUpload, index) => {
                  return performUpload(
                    files[index],
                    JSON.parse(directUpload.headers),
                    directUpload.url
                  );
                });
                Promise.all(pendingUploads)
                  .then(() => {
                    const merged = files.map((file, index) => ({
                      file,
                      metadata: { ...directUploads[index] },
                    }));
                    const attachBlobsInput = merged.map((file) => ({
                      ...attachBlobData,
                      blobId: file.metadata.blobId,
                    }));
                    attachBlobs({
                      variables: {
                        attachBlobs: attachBlobsInput,
                      },
                    })
                      .then(() => {
                        resolve(merged);
                      })
                      .catch((error) => reject(error));
                  })
                  .catch((error) => reject(error));
              })
              .catch((error) => reject(error));
          })
          .catch((error) => reject(error));
      });
    },
  ];
};
