import PropTypes from 'prop-types';

import { Button } from '@vartana-repo/base-components/buttons';
import { DocumentIcon } from '../../../assets';

export const FileInfoCard = ({ name, loading, onClick }) => {
  return (
    <div className="flex bg-vartana-white-100 justify-between items-center gap-6 border border-vartana-gray-100 py-4 pl-4 pr-5 rounded-lg">
      <div className="flex items-center gap-2">
        <DocumentIcon />
        <span className="text-vartana-black-100 body-bold">{name}</span>
      </div>

      {loading ? (
        <span className="text-sm text-vartana-gray-110 font-normal">Downloading...</span>
      ) : (
        <Button
          variant="link"
          className="text-vartana-blue-120 text-link-bold cursor-pointer"
          onClick={onClick}
        >
          Review
        </Button>
      )}
    </div>
  );
};
FileInfoCard.propTypes = {
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FileInfoCard;
