import PropTypes from 'prop-types';
import { Button } from '@vartana-repo/base-components/buttons';
import { CrossIcon, ExclamationIcon } from '../../assets';

export function CloseModal({ onCloseClick, onConfirmClick }) {
  return (
    <div className="w-full h-full bg-vartana-gray-140/95 fixed bottom-0 left-0 top-0 right-0 flex justify-center">
      <div className="bg-white h-full w-full md:h-fit md:w-fit md:rounded-xl md:mt-40">
        <div className="w-full flex justify-end p-2">
          <div className="cursor-pointer w-6 h-6 flex items-center justify-center">
            <CrossIcon onClick={onCloseClick} />
          </div>
        </div>
        <div className="px-4 md:px-10 md:pb-10">
          <div className="flex">
            <ExclamationIcon />
            <p className="text-xl font-bold ml-2">Are you sure?</p>
          </div>
          <p className="text-sm	my-6">Information will not be saved if you leave the page.</p>
          <div className="flex justify-between pt-2">
            <Button variant="ghost" onClick={onCloseClick} className="py-3 px-6 !h-auto">
              <span className="text-sm">Cancel</span>
            </Button>
            <Button variant="primary" onClick={onConfirmClick} className="py-3 px-6 !h-auto">
              <span className="text-sm">Confirm</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

CloseModal.propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
};

CloseModal.defaultProps = {
  onCloseClick: () => {},
  onConfirmClick: () => {},
};

export default CloseModal;
