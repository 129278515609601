import { get } from 'lodash';
import PropTypes from 'prop-types';

import { validateAccount } from '../../../assets/utils/banks.utils';
import { BankIcon, maskAccountNumberString, paymentModes } from '../../../assets';

const variants = {
  primary:
    'h-24 bg-vartana-white-100 border border-solid border-vartana-gray-40 rounded-md p-4 md:px-6',
  option:
    'h-16 -mx-px p-4 md:px-6 bg-vartana-white-100 hover:bg-vartana-blue-30 border-vartana-gray-40 rounded-none first:rounded-t-md last:rounded-b-md',
};

export const BankCard = ({ selectedAccount, className, variant, onClick }) => {
  const formattedLogo =
    selectedAccount.paymentMode === paymentModes.plaid
      ? `data:image/png;base64,${get(selectedAccount, 'logo', '')}`
      : selectedAccount.logo;
  if (validateAccount(selectedAccount)) {
    return (
      <div
        className={`
        flex items-center gap-3
        ${variant ? variants[variant] : ''}
        ${className}
      `}
        role="button"
        tabIndex={0}
        onClick={onClick}
      >
        {selectedAccount?.logo ? (
          <img className="object-contain w-15 h-15" src={formattedLogo} alt="bank-logo" />
        ) : (
          <BankIcon className="w-8 h-8" />
        )}

        <div className="flex flex-col gap-2">
          <span className="text-vartana-gray-160 card-subtitle-bold">
            {selectedAccount.accountName}
          </span>
          <span className="text-vartana-gray-140 p-small-bold">
            <span className="capitalize">{selectedAccount.accountType} </span>
            {maskAccountNumberString(selectedAccount.accountNumber)}
          </span>
        </div>
      </div>
    );
  }
  return null;
};

BankCard.propTypes = {
  variant: PropTypes.oneOf(['', ...Object.keys(variants)]),
  className: PropTypes.string,
  selectedAccount: PropTypes.shape({
    accountName: PropTypes.string,
    accountType: PropTypes.string,
    paymentMode: PropTypes.string,
    accountNumber: PropTypes.string,
    logo: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

BankCard.defaultProps = {
  variant: '',
  className: '',
  selectedAccount: {
    accountName: '',
    accountType: '',
    paymentMode: '',
    accountNumber: '',
    logo: '',
  },
  onClick: null,
};
