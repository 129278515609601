import { getAPIURL, reportError } from './helpers';

export async function getLinkToken() {
  const defaultResponse = {
    link_token: '',
  };

  try {
    const response = await fetch(`${getAPIURL()}/plaid/link_token`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    });

    if (response.status === 200) {
      return response.json();
    }
    return defaultResponse;
  } catch (e) {
    reportError(e);
    return defaultResponse;
  }
}

export async function exchangePublicToken(publicToken) {
  try {
    const response = await fetch(`${getAPIURL()}/plaid/public_token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        public_token: publicToken,
      }),
    });
    return response.json();
  } catch (e) {
    reportError(e);
    return null;
  }
}

export async function getInstitutionById(id) {
  try {
    const response = await fetch(`${getAPIURL()}/plaid/institution?institution_id=${id}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    });
    return response.json();
  } catch (e) {
    reportError(e);
    return null;
  }
}

export async function getAccount({ accessToken, accountId }) {
  try {
    const response = await fetch(`${getAPIURL()}/plaid/account`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        access_token: accessToken,
        account_id: accountId,
      }),
    });
    if (response.status === 200) {
      return response.json();
    }
    return null;
  } catch (e) {
    reportError(e);
    return null;
  }
}
