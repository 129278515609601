import PropTypes from 'prop-types';

import { Button } from '@vartana-repo/base-components/buttons';
import { PersonInfoCard } from '../../../components/Cards';

export const RecipientsList = ({ recipients, onSelect, onAdd, onEdit }) => {
  return (
    <>
      <span className="body">Select recipient(s):</span>
      <div className="flex flex-col gap-4 max-h-80 overflow-y-auto mt-2">
        {recipients?.length > 0
          ? recipients.map((recipient) => (
              <PersonInfoCard
                key={recipient.id || recipient.fullName}
                variant="optionsTheme"
                person={recipient}
                suppressOnRemove
                selected={recipient.selected}
                onSelect={() => onSelect(recipient)}
                onEdit={() => onEdit(recipient)}
              />
            ))
          : 'No Recipients Found'}
      </div>
      <div className="shadow-lift-2-inverted" />

      {onAdd ? (
        <div className="flex justify-center items-center gap-0.5 cursor-pointer mt-8">
          <Button variant="secondary" onClick={onAdd}>
            Add new recipient
          </Button>
        </div>
      ) : null}
    </>
  );
};
RecipientsList.propTypes = {
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fullName: PropTypes.string,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};
