import PropTypes from 'prop-types';
import { ReactComponent as OptionChecked } from '../assets/option-checked.svg';
import { ReactComponent as OptionUnchecked } from '../assets/option-unchecked.svg';

export const AccordionPanel = ({ Logo, title, subTitle, className, isSelected, onClick }) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={`w-full flex gap-4 items-center p-6 ${className}`} onClick={onClick}>
      {isSelected ? <OptionChecked /> : <OptionUnchecked />}
      <span className="flex items-center gap-2">
        {Logo}

        <div className="flex flex-col gap-2">
          <span className="card-subtitle-bold">{title}</span>
          {subTitle && <span className="card-subtitle-bold"> {subTitle}</span>}
        </div>
      </span>
    </div>
  );
};
AccordionPanel.propTypes = {
  Logo: PropTypes.node,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};
AccordionPanel.defaultProps = {
  Logo: null,
  subTitle: '',
  className: '',
  isSelected: false,
  onClick: null,
};
