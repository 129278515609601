import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { get } from 'lodash';

import { MOVE_TO_PREV_STEP } from '../graphql';
import { mapStateToPath } from '../../app/routes';

export const useMoveBack = (updateOrderDetails) => {
  const [backBtnLoading, setBackBtnLoading] = useState(false);

  const { orderNumber } = useParams();
  const navigate = useNavigate();

  const [proceedToPrevStep] = useMutation(MOVE_TO_PREV_STEP);

  const onBackBtnClick = useCallback(async () => {
    setBackBtnLoading(true);
    const { data, errors } = await proceedToPrevStep();
    setBackBtnLoading(false);

    if (errors) console.error('[MOVE_TO_PREV_STEP]', errors);
    const newOrder = get(data, 'moveToPreviousStep');
    if (newOrder) {
      updateOrderDetails((order) => ({
        ...order,
        state: newOrder.state,
      }));
      const newRoute = mapStateToPath(orderNumber, newOrder?.state);
      navigate(newRoute);
    }
  }, [navigate, orderNumber, proceedToPrevStep, updateOrderDetails]);

  return [backBtnLoading, onBackBtnClick];
};
