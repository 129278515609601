import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { capitalize, get, isEmpty } from 'lodash';
import { useIntercom } from 'react-use-intercom';
import { Outlet, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  checkoutProgressInitialState,
  convertArrayToObject,
  GET_ORDER_DETAIL,
  GET_SESSION_DETAIL,
  VartanaLogo,
} from '../assets';
import { AppLoadingModal } from '../components/Modals';
import { Navbar } from './Navbar';
import { SummarySection } from './SummarySection/SummarySection';

function initIntercomWithUser(user, updateIntercom) {
  updateIntercom({
    name: capitalize(`${user.firstName} ${user.lastName}`),
    email: user.email,
    created_at: new Date(user.createdAt).valueOf(),
    customAttributes: {
      application: 'checkout-app',
      environment: process.env.NX_NODE_ENV,
      resourceId: user.id,
      resourceType: 'User',
    },
  });
}

const InnerPage = ({ children, showSummaryOnMobile }) => (
  <div
    className={`
      flex flex-col overflow-y-auto py-6 lg:pt-10 px-4 md:px-8
      ${showSummaryOnMobile ? 'hidden' : ''} w-full lg:w-7/12 h-inherit
    `}
  >
    {children}
  </div>
);

export const AppLayout = ({ setMetaData }) => {
  const [showSummaryOnMobile, setShowSummaryOnMobile] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [paymentSummary, setPaymentSummary] = useState({});
  const [summaryLoading, setSummaryLoading] = useState(false);

  const { update: updateIntercom } = useIntercom();
  const urlParams = useParams();
  const { orderNumber } = urlParams;

  const { loading: orderDetailLoading } = useQuery(GET_ORDER_DETAIL, {
    variables: { number: orderNumber },
    onError: (error) => console.error('[GET_ORDER_DETAIL]', error),
    onCompleted: ({ buyerOrder }) => {
      const buyerSummary = convertArrayToObject(get(buyerOrder, 'buyerSummary', []));
      setPaymentSummary({
        useVartanaFinancing: get(buyerOrder, 'useVartanaFinancing'),
        reviewDocuments: get(buyerOrder, 'reviewDocuments', []),
        ...buyerSummary,
      });
      setOrderDetail(buyerOrder);
    },
  });
  const { data: sessionDetail, loading: sessionLoading } = useQuery(GET_SESSION_DETAIL, {
    onError: (error) => console.error('[GET_SESSION_DETAIL]', error),
  });

  const user = get(sessionDetail, 'session.user', {});
  useEffect(() => {
    if (!isEmpty(user)) {
      initIntercomWithUser(user, updateIntercom);
    }
  }, [updateIntercom, user]);

  useEffect(() => {
    const faviconUrl = get(orderDetail, 'company.seller.faviconUrl');
    const title = get(orderDetail, 'company.seller.businessName', '');

    if (faviconUrl && title) setMetaData({ faviconUrl, title });
  }, [orderDetail, setMetaData]);

  return (
    <div
      id="app-wrapper"
      className={`
        flex flex-col lg:items-center w-full max-w-vartana-bound h-full md:h-96vh
        p-0 lg:px-24
      `}
    >
      <AppLoadingModal isLoading={orderDetailLoading || sessionLoading} />

      {/* App Content */}
      {orderDetail && sessionDetail && (
        <div
          id="app-content"
          className={`grid ${
            showSummaryOnMobile ? 'grid-rows-summary-layout' : 'grid-rows-layout'
          }
            h-full w-full sm:rounded-xl`}
        >
          <Navbar
            currentProgress={get(
              orderDetail,
              'currentCheckoutProgress',
              checkoutProgressInitialState
            )}
            isHidden={showSummaryOnMobile}
            showViewDetail={(show) => setShowSummaryOnMobile(show)}
            logoUrl={get(orderDetail, 'company.seller.logoUrl', '')}
          />

          <div id="app-body" className="flex justify-center overflow-y-hidden h-full">
            <InnerPage showSummaryOnMobile={showSummaryOnMobile}>
              <Outlet
                context={[
                  { orderDetail, paymentSummary, summaryLoading, sessionDetail },
                  setOrderDetail,
                  setPaymentSummary,
                  setSummaryLoading,
                ]}
              />
            </InnerPage>

            <SummarySection
              summaryLoading={summaryLoading}
              paymentSummary={paymentSummary}
              orderState={get(orderDetail, 'state', '')}
              showSummaryOnMobile={showSummaryOnMobile}
              onClose={() => setShowSummaryOnMobile(false)}
            />
          </div>

          <div
            id="app-footer"
            className={`
              flex items-center gap-2 justify-center md:justify-end w-full py-6
              field-helper-text text-vartana-gray-140 shadow-lift-2-inverted md:shadow-none
            `}
          >
            In partnership with <VartanaLogo />
          </div>
        </div>
      )}
    </div>
  );
};

InnerPage.propTypes = {
  children: PropTypes.node.isRequired,
  showSummaryOnMobile: PropTypes.bool.isRequired,
};

AppLayout.propTypes = {
  setMetaData: PropTypes.func.isRequired,
};
