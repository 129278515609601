/* eslint-disable no-underscore-dangle */
class NavigationService {
  constructor() {
    this._navigation = null;
    this._rollbar = null;
  }

  set navigate(nav) {
    this._navigation = nav;
  }

  get navigate() {
    return this._navigation;
  }

  set rollbar(rollbar) {
    this._rollbar = rollbar;
  }

  get rollbar() {
    return this._rollbar;
  }
}

export const navigationService = new NavigationService();
