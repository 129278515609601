import { useMemo } from 'react';
import PropTypes from 'prop-types';

import 'react-step-progress-bar/styles.css';
import { ProgressBar as ProgressBarWrapper, Step } from 'react-step-progress-bar';

const wizardStyles = {
  default: {
    className: 'complete',
    color: '#1B6DFF',
  },
  isComplete: {
    className: 'finished',
    color: '#1FC198',
  },
  inActive: {
    className: 'inactive',
    color: '#FF8A72',
  },
};

export function ProgressBar({ progress, state }) {
  const { className, color } = useMemo(() => {
    return wizardStyles[state] || wizardStyles.default;
  }, [state]);

  return (
    <ProgressBarWrapper percent={progress * 100} filledBackground={color} height={5}>
      <Step>
        {({ accomplished }) => (
          <div
            className={`indexed-step  ${accomplished && progress > 0 ? className : ''}`}
          ></div>
        )}
      </Step>
    </ProgressBarWrapper>
  );
}

ProgressBar.propTypes = {
  progress: PropTypes.number,
  state: PropTypes.string,
};

ProgressBar.defaultProps = {
  progress: 0,
  state: 'default',
};

export default ProgressBar;
