import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { AccordionPanel, ExpandableCard } from '@vartana-repo/base-components/accordion';
import { Loader } from '@vartana-repo/base-components/loader';
import { SummaryData } from '../../../components/OrderSummary/SummaryData';
import { OrderSummary } from '../../../components/OrderSummary';
import { FileInfoCard } from '../../../components/Cards';
import { handleDocumentPreview, PREVIEW_DOCUMENT } from '../../../assets';
import './PaymentOptionsAccordions.scss';

export const PaymentOptionAccordions = ({
  options,
  paymentSummary,
  summaryLoading,
  onSelectOption,
  onClearSelection,
  wrapperClassName,
}) => {
  const [selectedAccordion, setSelectedAccordion] = useState('');
  const [filesDownloading, setFilesDownloading] = useState([]);

  const isExpandedView = options.find((option) => option.id === selectedAccordion);

  const [previewDocuments] = useMutation(PREVIEW_DOCUMENT);

  const onFileReviewClick = useCallback(
    (file) => {
      const updateDownloadingFiles = () => {
        setFilesDownloading((prevState) => [
          ...prevState.filter((filename) => filename !== file.filename),
        ]);
      };
      setFilesDownloading((prevState) => [...prevState, file.filename]);
      previewDocuments({
        variables: {
          docId: file.id,
          docType: file.type,
          filename: file.filename,
        },
        onCompleted: (response) => {
          updateDownloadingFiles();
          if (response.previewDocuments) {
            const docs = get(response, 'previewDocuments.encodedDocuments');
            const docNumber = get(response, 'previewDocuments.number');
            handleDocumentPreview(docs[0], docNumber);
          }
        },
        onError: (error) => {
          updateDownloadingFiles();
          console.error('[PREVIEW_DOCUMENTS] - ', error);
        },
      });
    },
    [previewDocuments]
  );

  const handleOptionClick = useCallback(
    (option) => {
      if (option && selectedAccordion !== option.id && !summaryLoading) {
        onSelectOption(option);
      }
    },
    [onSelectOption, selectedAccordion, summaryLoading]
  );

  const paymentOptions = useMemo(() => {
    const reviewDocuments = get(paymentSummary, 'reviewDocuments', []);

    return options?.map((option) => {
      return {
        id: option.id,
        header: (
          <AccordionPanel
            isSelected={selectedAccordion === option.id}
            title={option.label}
            titleClassName="text-base"
            className="card-subtitle-bold text-vartana-gray-160"
            onClick={() => handleOptionClick(option)}
          />
        ),
        content: (
          <Loader isLoading={summaryLoading}>
            <div className="grid grid-cols-6 gap-x-12 pb-2">
              <div className="col-span-6 grid grid-cols-6 gap-y-4 pb-6">
                <SummaryData paymentSummary={paymentSummary} />
              </div>
              {/* Terms and Conditions */}
              <div className="col-span-6 flex flex-col gap-3">
                {reviewDocuments.length > 0 ? (
                  <div className="flex flex-col gap-4">
                    {reviewDocuments.map((file) => (
                      <FileInfoCard
                        key={file.id}
                        name={file.filename}
                        document={file.document}
                        loading={filesDownloading.includes(file.filename)}
                        onClick={() => onFileReviewClick(file)}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </Loader>
        ),
      };
    });
  }, [
    paymentSummary,
    options,
    selectedAccordion,
    summaryLoading,
    handleOptionClick,
    filesDownloading,
    onFileReviewClick,
  ]);

  const collapsible = useMemo(() => {
    return (isExpandedView && paymentOptions.length === 1) || summaryLoading
      ? 'disabled'
      : '-';
  }, [isExpandedView, paymentOptions.length, summaryLoading]);

  useEffect(() => {
    setSelectedAccordion(options.find(({ isSelected }) => isSelected)?.id);
  }, [options]);

  if (paymentOptions.length > 0) {
    return (
      <div className={wrapperClassName}>
        <ExpandableCard
          key={selectedAccordion}
          items={paymentOptions}
          defaultActiveKey={selectedAccordion}
          onChange={(id) => {
            if (!id) onClearSelection();
            setSelectedAccordion(id);
          }}
          expandIcon={() => null}
          panelProps={{
            collapsible,
          }}
          collapseProps={{
            className: 'payment-options',
          }}
        />
      </div>
    );
  }
  return null;
};

PaymentOptionAccordions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      isSelected: PropTypes.bool.isRequired,
    })
  ).isRequired,
  summaryLoading: PropTypes.bool,
  paymentSummary: OrderSummary.propTypes.paymentSummary,
  onSelectOption: PropTypes.func.isRequired,
  onClearSelection: PropTypes.func.isRequired,
  wrapperClassName: PropTypes.string,
};
PaymentOptionAccordions.defaultProps = {
  summaryLoading: false,
  paymentSummary: {},
  wrapperClassName: '',
};
