import PropTypes from 'prop-types';

export const SummaryItem = ({ title, value }) => {
  return (
    <div className="col-span-3">
      <div className="flex flex-col gap-0.5">
        <span className="text-vartana-gray-110 p-small-bold">{title}</span>
        <span className="text-vartana-black-100 body">{value}</span>
      </div>
    </div>
  );
};
SummaryItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
