import { useState } from 'react';
import { Button } from '@vartana-repo/base-components/buttons';
import { Checkbox } from '@vartana-repo/base-components/form';
import { FieldArray, Form, Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { ToolTip } from '@vartana-repo/base-components/miscellaneous';
import { get, omit } from 'lodash';
import { useMutation } from '@apollo/client';
import { useOutletContext } from 'react-router-dom';

import { UPSERT_PERSONAL_GUARANTORS } from '../../../assets/graphql/personalGuarantor';
import {
  AddAnotherIcon,
  AddAnotherIconDisabled,
  CrossIcon,
  FilesIcon,
  InfoIcon,
  RightArrow,
} from '../../../assets';
import { ForwardPGModal, RemovePersonalGuarantorModal } from '../../../components/Modals';
import { PersonalGuarantorForm } from './PersonalGuarantorForm';
import {
  initialPGFormValues,
  initialPersonalGuarantorObj,
  personalGuarantorFormSchema,
} from '../../../assets/constants/personalGuarantor.constants';

const PageHeader = ({ titleIcon, title, subtitle1, subtitle2, linkAction, linkText }) => (
  <div className="flex flex-col gap-2 pb-6">
    <span className="flex gap-2 pb-4 page-title-small lg:page-title text-vartana-black-100">
      {titleIcon}
      {title}
    </span>
    <h1 className="body inline relative pb-3">
      {subtitle1}
      <span className="absolute pt-0.5 pl-1">
        <ToolTip
          element={<InfoIcon className="w-4 h-4" />}
          tooltipContent={
            <p>
              A PG or personal guaranty is an agreement <br />
              confirming that the individual who signs is <br />
              responsible for paying back a loan should the <br />
              business ever becomes unable to make payments. <br />
              The guarantor should be an authorized signer with <br />
              ownership or executive responsibilities
            </p>
          }
        />
      </span>
    </h1>
    <div className="flex flex-col md:flex-row gap-2 body items-start lg:items-center">
      <span className="body">{subtitle2}</span>
      {linkText && (
        <Button variant="linkBlue" onClick={linkAction}>
          {linkText}
        </Button>
      )}
    </div>
  </div>
);

PageHeader.propTypes = {
  titleIcon: PropTypes.node,
  title: PropTypes.string.isRequired,
  subtitle1: PropTypes.string.isRequired,
  subtitle2: PropTypes.string,
  linkText: PropTypes.string,
  linkAction: PropTypes.func,
};

PageHeader.defaultProps = {
  titleIcon: null,
  subtitle2: '',
  linkText: '',
  linkAction: undefined,
};

export function PersonalGuarantor() {
  const [, refetchSessionDetails] = useOutletContext();
  const [showForwardPGModal, setShowForwardPGModal] = useState(false);
  const [isRemovePGDialogOpen, setIsRemovePGDialogOpen] = useState(false);
  const [selectedPGIndex, setSelectedPGIndex] = useState(null);
  const [upsertPersonalGuarantors] = useMutation(UPSERT_PERSONAL_GUARANTORS);

  const pageHeaders = {
    titleIcon: <FilesIcon />,
    title: 'You’re almost there',
    subtitle1:
      'A personal guaranty is required for your orders. Please provide details of members from your business that can act as the personal guarantor.',
    subtitle2: "Don't have information?",
    linkText: 'Forward information request',
    linkAction: () => setShowForwardPGModal(true),
  };

  const isPersonalGuarantorEmpty = (personalGuarantorObj) => {
    return Object.values(
      omit(personalGuarantorObj, ['key', 'title', 'vartanaFinancing'])
    ).every((val) => !val);
  };

  const handleRemovePersonalGuarantor = (index) => {
    setIsRemovePGDialogOpen(true);
    setSelectedPGIndex(index);
  };

  const removeSelectedPG = (remove) => {
    remove(selectedPGIndex);
    setIsRemovePGDialogOpen(false);
    setSelectedPGIndex(null);
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (values.personalGuarantors.length === 1) values.personalGuarantors[0].primary = true;
    upsertPersonalGuarantors({
      variables: {
        personalGuarantors: values.personalGuarantors.map((personalGuarantor) =>
          omit(personalGuarantor, ['key'])
        ),
      },
    })
      .then((response) => {
        const errors = get(response, 'errors', {});
        if (Object.keys(errors).length) {
          setErrors({ personalGuarantors: [errors] });
        } else {
          refetchSessionDetails();
        }
      })
      .catch((error) => {
        // Todo: Add ui changes for error.
        console.error('[UPSERT_PERSONAL_GUARANTORS] - ', error);
      });
  };

  const handleOnToggleChange = async (e, index, values, setFieldValue) => {
    if (e.target.checked) {
      values.personalGuarantors.forEach((_, idx) => {
        if (index !== idx) setFieldValue(`personalGuarantors.${idx}.primary`, false);
      });
    }
  };

  return (
    <>
      <PageHeader
        titleIcon={pageHeaders.titleIcon}
        title={pageHeaders.title}
        subtitle1={pageHeaders.subtitle1}
        subtitle2={pageHeaders.subtitle2}
        message={pageHeaders.message}
        linkText={pageHeaders.linkText}
        linkAction={pageHeaders.linkAction}
      />
      <Formik
        initialValues={initialPGFormValues}
        validationSchema={personalGuarantorFormSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isValid, dirty, setFieldValue, errors, touched }) => (
          <Form>
            <FieldArray name="personalGuarantors">
              {({ remove, push }) => (
                <div className="flex flex-col gap-6">
                  {values.personalGuarantors.length
                    ? values.personalGuarantors.map((personalGuarantor, index) => (
                        <div key={personalGuarantor.key}>
                          <div className="relative pb-4">
                            <span className="card-title-bold">
                              Personal guarantor{' '}
                              {values.personalGuarantors.length > 1 ? index + 1 : null}
                            </span>
                            {values.personalGuarantors.length > 1 ? (
                              <div className="absolute right-0 top-0 p-3 cursor-pointer">
                                <CrossIcon
                                  onClick={() =>
                                    isPersonalGuarantorEmpty(personalGuarantor)
                                      ? remove(index)
                                      : handleRemovePersonalGuarantor(index)
                                  }
                                />
                              </div>
                            ) : null}
                          </div>
                          <PersonalGuarantorForm
                            index={index}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            showToggleBtn={values.personalGuarantors.length > 1}
                            handleOnToggleChange={(e) =>
                              handleOnToggleChange(e, index, values, setFieldValue)
                            }
                          />
                          {values.personalGuarantors.length !== index + 1 && (
                            <span className="w-full flex h-px bg-vartana-gray-40 mt-12 mb-3"></span>
                          )}
                        </div>
                      ))
                    : null}
                  <div className="flex flex-col gap-4 divide-y">
                    {values.personalGuarantors.length < 5 ? (
                      <div className="flex justify-center md:justify-start">
                        <button
                          type="button"
                          className="flex items-center gap-1 w-fit"
                          disabled={!isValid || !dirty}
                          onClick={() =>
                            push({
                              ...initialPersonalGuarantorObj,
                              primary: false,
                              key: uuidv4(),
                            })
                          }
                        >
                          {!isValid || !dirty ? (
                            <AddAnotherIconDisabled className="w-8 h-8" />
                          ) : (
                            <AddAnotherIcon className="w-8 h-8" />
                          )}
                          <span
                            className={`text-vartana-${
                              !isValid || !dirty ? 'gray-100' : 'blue-120'
                            }`}
                          >
                            Add another guarantor
                          </span>
                        </button>
                      </div>
                    ) : null}
                    <Checkbox
                      name="pgDisclaimer"
                      labelClassName="text-vartana-gray-140 pt-6"
                      longText
                      label={
                        <span>
                          By checking this box, I acknowledge that I am providing personal
                          information of the individual(s) that can act as the personal
                          guarantor. Vartana will review their credit history through a soft
                          credit check which will not impact their credit score.
                        </span>
                      }
                    />
                  </div>
                  <div className="flex justify-end">
                    <Button
                      type="submit"
                      disabled={!isValid || !dirty || !values.pgDisclaimer}
                      className="w-40 flex gap-0.5 items-center"
                    >
                      Next <RightArrow />
                    </Button>
                  </div>
                  <RemovePersonalGuarantorModal
                    open={isRemovePGDialogOpen}
                    handleRemove={() => removeSelectedPG(remove)}
                    handleClose={() => setIsRemovePGDialogOpen(false)}
                  />
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>

      <ForwardPGModal
        isOpen={showForwardPGModal}
        onClose={() => setShowForwardPGModal(false)}
      />
    </>
  );
}
