import PropTypes from 'prop-types';
import { Button } from '@vartana-repo/base-components/buttons';
import { Modal } from '@vartana-repo/base-components/modal';
import { AttentionHallowIcon, errorSubject } from '../../assets';

const Heading = () => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-2 modal-heading">
        <AttentionHallowIcon className="w-8 h-8" />
        <span>Uh oh something went wrong!</span>
      </div>
    </div>
  );
};

export const SomethingWentWrongModal = ({ isOpen }) => {
  const handleClose = (event, reloadPage = false) => {
    errorSubject.next(false);
    if (reloadPage) window.location.reload();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      variant="error"
      showCloseIcon
      wrapperClassName="gap-6 pb-10"
    >
      <Heading />
      <div className="text-vartana-black-100 text-sm font-normal">
        Please try refreshing and continue or get help through the support window below.
      </div>
      <Button
        onClick={(e) => handleClose(e, true)}
        className="mt-8 m-auto md:text-base w-60 max-h-16"
      >
        Refresh and continue
      </Button>
    </Modal>
  );
};
SomethingWentWrongModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default SomethingWentWrongModal;
